import { Upload } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { ButtonCommon } from '../../../components'
import { DataEmpty } from '../../../components/DataEmpty'
import { PaginationCommon } from '../../../components/Pagination'
import { IResPriceManager } from '../../../models'
import { dailyDivision, PriceManagerTableKey, convertNumberToMoneyFormat, meterUi, priceManagerUI } from '../../../until'
import styles from './index.module.scss'
import { UploadOutlined } from '@ant-design/icons'
import clsx from 'clsx'

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onChangePagination: (_page: number, _page_size: number) => void
  data: IResPriceManager
  propsUpload: any
  loading?: boolean
}

const Manager = ({
  data,
  onChangePagination,
  propsUpload,
  loading = false
}: IProps) => {
  const columns: ColumnsType<any> = [
    {
      title: priceManagerUI.tariffCode,
      dataIndex: PriceManagerTableKey.tariffCode,
      key: PriceManagerTableKey.tariffCode,
      width: '13%',
    },
    {
      title: priceManagerUI.amountUsage,
      dataIndex: PriceManagerTableKey.quantityLevel,
      key: PriceManagerTableKey.quantityLevel,
      width: '13%',
      render: (text: any) => {
        return text ? Number.parseFloat(text).toFixed(2) : ''
      },
    },
    {
      title: priceManagerUI.cost,
      dataIndex: PriceManagerTableKey.cost,
      key: PriceManagerTableKey.cost,
      width: '13%',
      render: (text: any) => {
        return convertNumberToMoneyFormat(text) ?? ''
      }
    },
    {
      title: priceManagerUI.cleanWater,
      dataIndex: PriceManagerTableKey.supplyCost,
      key: PriceManagerTableKey.supplyCost,
      width: '13%',
      render: (text: any) => {
        return convertNumberToMoneyFormat(text) ?? ''
      }
    },
    {
      title: priceManagerUI.sewageWater,
      dataIndex: PriceManagerTableKey.lossCost,
      key: PriceManagerTableKey.lossCost,
      width: '13%',
      render: (text: any) => {
        return convertNumberToMoneyFormat(text) ?? ''
      }
    },
    {
      title: priceManagerUI.daily_division,
      dataIndex: PriceManagerTableKey.dailyDivision,
      key: PriceManagerTableKey.dailyDivision,
      width: '13%',
      render: (text: any) => {
        const temp = dailyDivision.find((item) => item.value === Number(text || '0'))
        return temp?.label ?? ''
      }
    },
  ]

  if (loading) {
    return null
  }

  if (!data?.data || data.data.length <= 0) {
    return (
      <DataEmpty detail={priceManagerUI.noResultsMessage} className={styles.empty__results}>
        <Upload {...propsUpload}>
          <ButtonCommon icon={<UploadOutlined />} type="primary">
            {meterUi.importCSV}
          </ButtonCommon>
        </Upload>
      </DataEmpty>
    )
  }

  return (
    <div className={clsx(styles.page__content)}>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        scroll={{ x: 700 }}
      />
      {data?.pagination?.total > 10 ? (
        <div className={styles.pagination}>
          <PaginationCommon
            defaultCurrent={1}
            total={data?.pagination?.total}
            pageSize={data?.pagination?.per_page}
            current={data?.pagination?.current_page}
            onChangePagination={onChangePagination}
            pageSizeOptions={['10', '20', '50', '100']}
            showSizeChanger
          />
        </div>
      ) : null}
    </div>
  )
}

export default Manager
