import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Staff {
  createStaff = async (data: unknown) => {
    const url = `/${EndPoint.staff}`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  getAllStaff = async (params?: unknown) => {
    const url = `/${EndPoint.staff}`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  getSuggestStaff = async (params?: unknown) => {
    const url = `/${EndPoint.staff}/list`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  getAllContract = async (params?: unknown) => {
    const url = `/${EndPoint.staff}/contracts`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  deleteStaff = async (id: number) => {
    const url = `/${EndPoint.staff}/${id}`
    const res = await axiosClient.delete(url)
    return res.data
  }

  updateStaff = async (data: any) => {
    const url = `/${EndPoint.staff}/${data.id}`
    const res = await axiosClient.post(url, data.formData)
    return res.data
  }

  getSuggestionsBuilding = async () => {
    const url = `${EndPoint.building}/suggestions?all`
    const res = await axiosClient.get(url)
    return res.data
  }

  getSuggestionsBuildingAll = async () => {
    const url = `${EndPoint.building}/suggestions?all`
    const res = await axiosClient.get(url)
    return res.data
  }
  
  forgotPassword = async (data: { email: string }) => {
    const url = `/forgot-password`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  printQRCode = async () => {
    const url = `/${EndPoint.staff}/print-qr-code`
    const res = await axiosClient.get(url)
    return res.data
  }
}

export const StaffApi = new Staff()
