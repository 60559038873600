import styles from './index.module.scss'
import { useState, useEffect } from 'react'
import { Table } from 'antd'
import { extraordinaryUi, meterUi } from '../../../until/text/ui'
import { IMeterDetail } from '../../../models'
import { PaginationCommon } from '../../../components/Pagination'
import { DataEmpty } from '../../../components/DataEmpty'
import {
  useGetAllMeasurementExtraordinary,
} from '../../../hooks/query/extraordinary'
import { ModalShowImg } from '../../Meter/ShowImgModal'
import { meterTypeKey } from '../../../until'

export const List = ({ setLoadingApi, setLoadingApiRefresh, dataFilter, setDataFilter }: any) => {
  const [showModalImg, setShowModalImg] = useState(false)
  const [currentMeter, setCurrentMeter] = useState<IMeterDetail>()
  const {
    data: listMeasurementExtraordinary,
    isLoading: isLoadingMeasurementExtraordinary,
    isFetching,
  } = useGetAllMeasurementExtraordinary(dataFilter)

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== dataFilter.page_size) {
      return setDataFilter({ ...dataFilter, page: 1, page_size })
    }
    setDataFilter({ ...dataFilter, page, page_size })
  }
  useEffect(() => {
    setLoadingApi(isLoadingMeasurementExtraordinary)
    setLoadingApiRefresh(isFetching)
  }, [listMeasurementExtraordinary, isFetching])
  const columns = [
    {
      title: extraordinaryUi.association,
      dataIndex: 'management_association_no',
      width: '8%',
    },
    {
      title: extraordinaryUi.building,
      dataIndex: 'building_code',
      width: '6%',
    },
    {
      title: extraordinaryUi.room,
      dataIndex: 'room_no',
      width: '8%',
    },
    {
      title: extraordinaryUi.type,
      dataIndex: 'meter_type',
      width: '8%',
      render: (type: any) => meterTypeKey[type],
    },
    {
      title: extraordinaryUi.meterReading,
      dataIndex: 'reading_date',
      width: '10%',
    },
    {
      title: extraordinaryUi.user,
      dataIndex: 'worker',
      width: '12%',
    },
    {
      title: extraordinaryUi.abnormal,
      dataIndex: 'abnormal_rate',
      width: '10%',
      render: (value: any) => value ? `${parseInt(value)}%` : '---',
    },
    {
      title: extraordinaryUi.prevValue,
      dataIndex: 'prev_usage',
      width: '8%',
    },
    {
      title: extraordinaryUi.currentValue,
      dataIndex: 'current_usage',
      width: '10%',
    },
    {
      title: extraordinaryUi.extraordinaryValue,
      dataIndex: 'abnormal_value',
      width: '10%',
      render: (value: any) => `${value}%`,
    },
    {
      title: extraordinaryUi.image,
      width: '10%',
      render: (record: any) => (
        <>
          <a
            onClick={() => {
              setShowModalImg(true)
              setCurrentMeter(record)
            }}
            style={{ color: '#1890FF', cursor: 'pointer', whiteSpace: 'nowrap' }}
          >
            {extraordinaryUi.prevImage}
          </a>
        </>
      ),
    },
  ]
  return (
    <>
      {isLoadingMeasurementExtraordinary || isFetching ? null : (
        <div className={styles.extraordinary}>
          {listMeasurementExtraordinary?.data && listMeasurementExtraordinary?.data.length > 0 ? (
            <div className={styles.extraordinary__table}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={listMeasurementExtraordinary?.data}
                pagination={false}
                scroll={{ x: 1280 }}
              />
              <div>
                <ModalShowImg
                  visible={showModalImg}
                  title={meterUi.titleImage}
                  onCancel={() => setShowModalImg(false)}
                  img={currentMeter?.image}
                />
              </div>
              {listMeasurementExtraordinary?.pagination && listMeasurementExtraordinary?.pagination?.total > 10 && (
                <div className={styles.extraordinary__table__pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={listMeasurementExtraordinary.pagination.total}
                    current={listMeasurementExtraordinary.pagination.current_page}
                    pageSize={listMeasurementExtraordinary.pagination.per_page}
                    showSizeChanger
                    onChangePagination={onChangePagination}
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              )}
            </div>
          ) : (
            <DataEmpty>
              <></>
            </DataEmpty>
          )}
        </div>
      )}
    </>
  )
}
