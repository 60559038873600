import clsx from 'clsx'
import { ModalCommon } from '../../../components'
import styles from './index.module.scss'
import { Col, Row } from 'antd'
import { useMemo } from 'react'
import { contractorsUi } from '../../../until/text/ui'
import { FLGType, PriceManagerTableKey, dailyDivisionKey, dataFormat } from '../../../until/constant'
import moment from 'moment'
import { convertNumberToMoneyFormat } from '../../../until'
import { ContractorTableKey } from '..'
interface IProps {
  title: string
  detailModal?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  info: any
}

const ITEM_NOT_SHOW = ['building_id', 'id', 'type']
const ITEM_IS_COST = ['previous_water_charge', 'previous_sewerage_charge', 'transfer_cost']

export const ModalContractorDetail = ({ detailModal, visible, onOk, onCancel, info }: IProps) => {
  const convertInfo = useMemo(() => {
    const arrayFirstPart: string[] = []
    const arraySecondPart: string[] = []
    if (info && !!Object.keys(info).length) {
      Object.keys(info).forEach((itemKey: string, idx: number) => {
        if (ITEM_NOT_SHOW.includes(itemKey)) return
        if (idx % 2 !== 0) {
          return arrayFirstPart.push(itemKey)
        }

        arraySecondPart.push(itemKey)
      })
    }

    return {
      arrayFirstPart,
      arraySecondPart
    }
  }, [info])

  const convertInfoValue = (label: string, value: string | number) => {
    switch (true) {
      case !value && value !== 0:
        return ''
      case label.includes('date') && label !== 'previous_date_meter_reading_ft':
        return moment(value).format(label === 'previous_date_meter_reading' ? dataFormat.yyyymm : dataFormat.YYYYMMDD)
      case ITEM_IS_COST.includes(label):
        return convertNumberToMoneyFormat(value)
      case label === PriceManagerTableKey.dailyDivision:
        return dailyDivisionKey[value as number]
      case label === ContractorTableKey.FLG:
        return FLGType[Number(value)]
      default:
        return value
    }
  }

  return (
    <ModalCommon
      title={
        `${info?.management_association_no} ${info?.building_no} ${info?.room_no} ${info?.subject_code}` || '#13254'
      }
      detailModal={detailModal}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width='852px'
      destroyOnClose={true}
    >
      <div id="canvas" className={styles.modal__body}>
        <Row wrap={false} align='top' className={clsx(styles.detail__container)}>
          <Col span={12} className={styles.detail__container__col}>
            {convertInfo.arrayFirstPart.map((item: any) => {

              return (
                <Row key={item} align='top'>
                  <Col span={8} className={styles.title}>{contractorsUi[item]}:</Col>
                  <Col span={16}>{convertInfoValue(item, info[item])}</Col>
                </Row>
              )
            })}
          </Col>
          <Col span={12} className={styles.detail__container__col}>
            {convertInfo.arraySecondPart.map((item: string) => {
              return (
                <Row key={item} align='bottom'>
                  <Col span={8} className={styles.title}>{contractorsUi[item]}:</Col>
                  <Col span={16}>{convertInfoValue(item, info[item])}</Col>
                </Row>
              )
            })}
          </Col>
        </Row>
      </div>
    </ModalCommon>
  )
}
