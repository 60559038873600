import { contractorsUi, operationUi } from '../text/ui'
import { meterUi } from '../text/ui/meter'
import { priceManagerUI } from '../text/ui/price'

export const QR_CODE = {
  LEVEL: 'M',
  SIZE: 60,
}

export const PriceManagerTableKey = {
  quantityLevel: 'quantity_level',
  supplyCost: 'supply_cost',
  lossCost: 'loss_cost',
  cost: 'cost',
  tariffCode: 'tariff_code',
  action: 'action',
  dailyDivision: 'daily_division',
}

export const dailyDivision = [
  {
    label: priceManagerUI.nomal,
    value: 0,
  },
  {
    label: priceManagerUI.monthProrated,
    value: 1,
  },
  {
    label: priceManagerUI.month2Prorated,
    value: 2,
  },
]

export const dailyDivisionKey: { [key: number]: string } = {
  0: priceManagerUI.nomal,
  1: priceManagerUI.monthProrated,
  2: priceManagerUI.month2Prorated,
}

export const typeMeter: { name: string; value: number }[] = [
  { name: meterUi.water, value: 1 },
  { name: meterUi.electric, value: 2 },
  { name: meterUi.gas, value: 3 },
]

export const FLGType: { [key: number]: string } = {
  0: contractorsUi.flg_no_first,
  1: contractorsUi.flg_first,
}

export const meterTypeKey: { [key: number]: string } = {
  1: meterUi.water,
  2: meterUi.electric,
  3: meterUi.gas,
}

export const unit = {
  kw: 'KW',
  m3: 'm3',
  price: '円',
}

export const dataFormat = {
  yyyyMM: 'YYYY年MM月',
  yyyyMMdd: 'YYYY年MM月DD日',
  yyyyMMEmpty: ' 年 月',
  yyyyMMddEmpty: ' 年 月 日',
  YYYYMMDD: 'YYYY/MM/DD',
  yyyymm: 'YYYY/MM',
}

export const MAX_4_DEGIT_VALUE = 9999
export const MAX_5_DEGIT_VALUE = 99999
export const MAX_5_DEGIT_FLOAT_VALUE = 99999.99
export const MAX_6_DEGIT_FLOAT_VALUE = 999999.99
export const MAX_6_DEGIT_VALUE = 999999
export const MAX_11_DEGIT_VALUE = 99999999999

export const SIZE_5MB = 5 * 1024 * 1024

export const EXAMPLE_RESPONSE = {
  data: [],
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  },
}

export const PLATFORM: { [key: number]: string } = {
  1: operationUi.platformWeb,
  2: operationUi.platformApp,
}

export const OPERATION_TYPE: { [key: number]: string } = {
  1: operationUi.typeLogin,
  2: operationUi.typeRegister,
  3: operationUi.typeUpdate,
  4: operationUi.typeDelete,
  5: operationUi.typeExport,
  6: operationUi.typeDownload,
  7: operationUi.logout,
}

export const AUTHENTICATION_PASSCODE = 6

export const meterType: { label: string; value: number }[] = [
  { label: meterUi.water, value: 1 },
  { label: meterUi.electric, value: 2 },
  { label: meterUi.gas, value: 3 },
]
export const roundingType: { label: string; value: number }[] = [
  { label: priceManagerUI.roundDown, value: 0 },
  { label: priceManagerUI.roundTo5, value: 1 },
  { label: priceManagerUI.roundUp, value: 2 },
  { label: priceManagerUI.roundTo6, value: 3 },
]
export const roundingUnit: { label: string; value: number }[] = [
  { label: priceManagerUI.under1, value: 0 },
  { label: priceManagerUI.under10, value: 1 },
  { label: priceManagerUI.under100, value: 2 },
  { label: priceManagerUI.under1000, value: 3 },
]

export const meterTypeFormula: { label: string; value: number }[] = [
  { label: meterUi.water, value: 1 },
  { label: meterUi.another, value: 0 }
]
