import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface IProps {
  roles: Array<string>
  currentRole: string
}

export const PrivateRoute = ({ roles, currentRole }: IProps) => {
  const userHasRequiredRole = roles.includes(currentRole) ? true : false
  if (userHasRequiredRole) {
    return <Outlet />
  }

  if(currentRole === '9') {
    return <Navigate to="/admin" />
  }

  return <Navigate to="/notfound" />
}
