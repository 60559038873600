import React, { useState, useCallback } from 'react'
import { DatePicker, DatePickerProps, Select, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { IconWrap, InputCommon, PageHeader, SortTitle } from '../../components'
import operationLogHeader from '../../assets/images/operationLogHeader.png'
import clsx from 'clsx'
import styles from './index.module.scss'
import { LogIcon, SearchIcon } from '../../assets/icons'
import { operationUi } from '../../until/text/ui'
import { DataEmpty } from '../../components/DataEmpty'
import { Loading } from '../../components/Loading'
import { IOperationLog, IOpreationLogParams } from '../../models'
import debounce from 'lodash.debounce'
import { PaginationCommon } from '../../components/Pagination'
import moment from 'moment'
import { useGetOperationLog } from '../../hooks/query/operationLog'
import { OPERATION_TYPE, PLATFORM } from '../../until'

const typeOperation: { name: string; value: number }[] = Object.keys(OPERATION_TYPE).map((item) => (
  {
    name: OPERATION_TYPE[Number(item)],
    value: Number(item),
  }
))

const platfroms: { label: string; value: number }[] = Object.keys(PLATFORM).map((item) => (
  {
    label: PLATFORM[Number(item)],
    value: Number(item),
  }
))

export const OperationLog = () => {
  const [params, setParams] = useState<IOpreationLogParams>({
    page: 1,
    page_size: 10
  })

  const { data, isLoading, isFetching } = useGetOperationLog(params)

  const onSort = (name: string) => {
    if (params.sort_by !== name) {
      setParams({ ...params, sort_by: name, sort_type: 'asc' })
    } else {
      params.sort_type === 'asc'
        ? setParams({ ...params, sort_type: 'desc' })
        : setParams({ ...params, sort_by: '', sort_type: 'desc' })
    }
  }

  const columns: ColumnsType<IOperationLog> = [
    {
      title: (
        <SortTitle
          title={operationUi.date}
          value={params.sort_by === 'operated_at' ? params.sort_type : null}
          onSort={() => onSort('operated_at')}
        />
      ),
      dataIndex: 'operated_at',
      key: 'operated_at',
      width: '16%',
      render: (text) => text ? moment(text).format('YYYY/MM/DD HH:mm:ss') : '',
    },
    {
      title: (
        <SortTitle
          title={operationUi.username}
          value={params.sort_by === 'username' ? params.sort_type : null}
          onSort={() => onSort('username')}
        />
      ),
      dataIndex: 'username',
      key: 'username',
      width: '14%',
    },
    {
      title: (
        <SortTitle
          title={operationUi.category}
          value={params.sort_by === 'operation_type' ? params.sort_type : null}
          onSort={() => onSort('operation_type')}
        />
      ),
      dataIndex: 'operation_type',
      key: 'operation_type',
      width: '10%',
      render: (text) => OPERATION_TYPE[text]
    },
    {
      title: (
        <SortTitle
          title={operationUi.operationContent}
          value={params.sort_by === 'api_name' ? params.sort_type : null}
          onSort={() => onSort('api_name')}
        />
      ),
      dataIndex: 'api_name',
      key: 'api_name',
      width: '12%',
    },
    {
      title: (
        <SortTitle
          title={operationUi.name}
          value={params.sort_by === 'name' ? params.sort_type : null}
          onSort={() => onSort('name')}
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: (
        <SortTitle
          title={operationUi.logContent}
          value={params.sort_by === 'value' ? params.sort_type : null}
          onSort={() => onSort('value')}
        />
      ),
      dataIndex: 'value',
      key: 'value',
      width: '10%',
    },
    {
      title: (
        <SortTitle
          title={operationUi.platform}
          value={params.sort_by === 'platform' ? params.sort_type : null}
          onSort={() => onSort('platform')}
        />
      ),
      dataIndex: 'platform',
      key: 'platform',
      width: '8%',
      render: (value) => PLATFORM[value]
    },
    {
      title: (
        <SortTitle
          title={operationUi.version}
          value={params.sort_by === 'version' ? params.sort_type : null}
          onSort={() => onSort('version')}
        />
      ),
      dataIndex: 'version',
      key: 'version',
      width: '11%',
    },
    {
      title: (
        <SortTitle
          title={operationUi.device}
          value={params.sort_by === 'device' ? params.sort_type : null}
          onSort={() => onSort('device')}
        />
      ),
      dataIndex: 'device',
      key: 'device',
      width: '11%',
    },
  ]

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const handleChangeSelectType = (value: number) => {
    setParams({ ...params, page: 1, operation_type: value })
  }

  const handleChangeSelectPlatfrom = (value: number) => {
    setParams({ ...params, page: 1, platform: value })
  }

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: IOpreationLogParams) => ({ ...prev, text_search: nextValue, page: 1 })), 500),
    [],
  )

  const handleChangeDateStart: DatePickerProps['onChange'] = (date) => {
    setParams({ ...params, operated_at_from: date ? moment(date).format('YYYY/MM/DD') : null, page: 1 })
  }
  const handleChangeDateEnd: DatePickerProps['onChange'] = (date) => {
    setParams({ ...params, operated_at_to: date ? moment(date).format('YYYY/MM/DD') : null, page: 1 })
  }

  return (
    <div className={clsx(styles.operation)}>
      <PageHeader title={operationUi.operationLogList} backgroundImage={operationLogHeader} height={'108px'}>
        <div className={styles.operation__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={operationUi.search}
            className={styles.operation__header__search__input}
            onChange={onHandleSearch}
          />
        </div>
      </PageHeader>
      <Loading isLoading={isLoading || isFetching} />
      <div className={clsx(styles.operation__content__filter)}>
        <div
          className={clsx(
            styles.operation__content__filter__group,
            {
              [styles.hidden]: data?.data.length === 0 || isLoading || isFetching,
            },
            'select__group',
          )}
        >
          <Select
            style={{ width: 160 }}
            className={styles.operation__content__filter__group__select}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap color="#8C8C8C" icon={<LogIcon />} className={clsx(styles.icon__filter, styles.stafficon)} />
                {operationUi.category}
              </>
            }
            onChange={handleChangeSelectType}
          >
            {typeOperation.map((item: { name: string; value: number }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<LogIcon />} className={clsx(styles.icon__filter, styles.stafficon)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
          <Select
            style={{ width: 85 }}
            className={styles.operation__content__filter__group__select}
            allowClear
            optionLabelProp="label"
            placeholder={operationUi.platform}
            onChange={handleChangeSelectPlatfrom}
            options={platfroms}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <DatePicker
              onChange={handleChangeDateStart}
              className={styles.operation__content__filter__date}
              placeholder={operationUi.dateTime}
              format='YYYY/MM/DD'
            />
            <div style={{ width: '15px', height: '2px', background: '#747373' }}></div>
            <DatePicker
              onChange={handleChangeDateEnd}
              className={styles.operation__content__filter__date}
              placeholder={operationUi.dateTime}
              format='YYYY/MM/DD'
            />
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className={clsx(styles.operation__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={data.data}
                pagination={false}
                scroll={{ x: 700 }}
              />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty className={styles.empty__results}>
              <></>
            </DataEmpty>
          )}
        </div>
      )}
    </div>
  )
}
