export const TypeIcon = () => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 1.33334C11.6827 1.33334 14.6673 4.31801 14.6673 8.00001C14.6673 11.682 11.6827 14.6667 8.00065 14.6667C4.31865 14.6667 1.33398 11.682 1.33398 8.00001C1.33398 4.31801 4.31865 1.33334 8.00065 1.33334ZM8.00065 2.66668C5.05532 2.66668 2.66732 5.05468 2.66732 8.00001C2.66732 10.9453 5.05532 13.3333 8.00065 13.3333C10.946 13.3333 13.334 10.9453 13.334 8.00001C13.334 5.05468 10.946 2.66668 8.00065 2.66668ZM10.556 4.89134C10.714 4.78068 10.9287 4.79934 11.0647 4.93601C11.2007 5.07201 11.218 5.28668 11.1067 5.44268C9.65332 7.47334 8.85332 8.56134 8.70798 8.70734C8.31732 9.09734 7.68398 9.09734 7.29332 8.70734C6.90332 8.31668 6.90332 7.68334 7.29332 7.29268C7.54265 7.04401 8.62998 6.24334 10.556 4.89134ZM11.6673 7.33334C12.0353 7.33334 12.334 7.63201 12.334 8.00001C12.334 8.36801 12.0353 8.66668 11.6673 8.66668C11.2993 8.66668 11.0007 8.36801 11.0007 8.00001C11.0007 7.63201 11.2993 7.33334 11.6673 7.33334ZM4.33398 7.33334C4.70198 7.33334 5.00065 7.63201 5.00065 8.00001C5.00065 8.36801 4.70198 8.66668 4.33398 8.66668C3.96598 8.66668 3.66732 8.36801 3.66732 8.00001C3.66732 7.63201 3.96598 7.33334 4.33398 7.33334ZM5.87932 4.93601C6.13932 5.19601 6.13932 5.61801 5.87932 5.87868C5.61932 6.13868 5.19665 6.13868 4.93665 5.87868C4.67665 5.61868 4.67665 5.19601 4.93665 4.93601C5.19665 4.67601 5.61865 4.67601 5.87932 4.93601ZM8.00065 3.66668C8.36865 3.66668 8.66732 3.96534 8.66732 4.33334C8.66732 4.70134 8.36865 5.00001 8.00065 5.00001C7.63265 5.00001 7.33398 4.70134 7.33398 4.33334C7.33398 3.96534 7.63265 3.66668 8.00065 3.66668Z"
        fill="#8C8C8C"
      />
    </svg>
  )
}
