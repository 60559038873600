import React from 'react'

import { Avatar, Dropdown, Menu } from 'antd'
import styles from './index.module.scss'
import { UserOutlined } from '@ant-design/icons'
import { CopyToClipboard, MoreIcon } from '../../../assets/icons'
import { IconWrap } from '../../../components'
import clsx from 'clsx'
import { useState } from 'react'
import { managerUI, staffUi } from '../../../until/text/ui'
import { ModalQR } from '../../Staff/ModalQR'
import { ManagerModal } from '../Modal'
import { IStaff } from '../../../models'
import { envApiUrl } from '../../../apis/axiosClient'
import { openNotificationWithIcon } from '../../../hooks'
import { managerMessage } from '../../../until/text/messages'

interface ICardStaff {
  data: Partial<IStaff>
  // eslint-disable-next-line no-unused-vars
  // handleDeleteStaff: (id?: number) => void
}

export const CardManager = ({ data }: ICardStaff) => {
  const { full_name, contract_code, email, address_1, number_phone, username, token } = data
  const [openMenu, setOpenMenu] = useState(false)

  const [visible, setVisible] = useState(false)
  const [managerModalVisible, setManagerModalVisible] = useState(false)

  const handleUpdateInfo = () => {
    setOpenMenu(false)
    setManagerModalVisible(true)
  }

  const onCopyToken = async () => {
    const textArea = document.createElement('textarea')
    textArea.value = token!
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    openNotificationWithIcon('success', managerMessage.copyToClipboard)
  }

  const menu = (
    <Menu
      items={[
        {
          label: (
            <a
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
              onClick={() => {
                handleUpdateInfo()
              }}
            >
              {staffUi.updateInformation}
            </a>
          ),
          key: '0',
        },
        // {
        //   label: (
        //     <a
        //       onClick={() => {
        //         setVisible(true)
        //         setOpenMenu(false)
        //       }}
        //     >
        //       {staffUi.delete}
        //     </a>
        //   ),
        //   key: '1',
        // },
      ]}
    />
  )

  return (
    <div className={styles.manager}>
      <div className={styles.top}>
        <Avatar
          src={data && data.url_avatar ? `${envApiUrl}${data?.url_avatar}` : null}
          icon={data?.url_avatar ? null : <UserOutlined />}
          size={42}
        />
        <div className={styles.managerInfor}>
          <span>{username}</span>
          <span>{full_name}</span>
        </div>
        <Dropdown
          placement="bottom"
          overlay={menu}
          className={styles.dropdown}
          trigger={['click']}
          onVisibleChange={(visible: boolean) => {
            setOpenMenu(visible)
          }}
        >
          <div className={clsx({ [styles.isopen]: openMenu }, styles.threedot)}>
            <IconWrap icon={<MoreIcon />} size={18} />
          </div>
        </Dropdown>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottom__information}>
          <div className={styles.manager__information}>
            <span className={styles.manager__information__title}>{managerUI.IDcontract} :</span>
            <span className={styles.manager__information__content}>{contract_code}</span>
          </div>
          <div className={styles.manager__information}>
            <span className={styles.manager__information__title}>{managerUI.email} :</span>
            <span className={styles.manager__information__content}>{email}</span>
          </div>
          <div className={styles.manager__information}>
            <span className={styles.manager__information__title}>{managerUI.phone} : </span>
            <span className={styles.manager__information__content}>{number_phone}</span>
          </div>
          <div className={styles.manager__information}>
            <span className={styles.manager__information__title}> {managerUI.address} : </span>
            <span className={styles.manager__information__content}>{address_1}</span>
          </div>
          <div className={styles.manager__information}>
            <span className={styles.manager__information__title}> {managerUI.accessToken} : </span>
            <span className={styles.manager__information__content}>{token}</span>
            {token && <IconWrap
              icon={<CopyToClipboard />}
              className={styles.icon__wrap}
              onClick={onCopyToken}
            />}
          </div>
        </div>
      </div>
      <ModalQR
        data={data}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        title={staffUi.printQRCode}
      />
      {managerModalVisible && (
        <ManagerModal
          title={managerUI.edit}
          visible={managerModalVisible}
          onOk={() => setManagerModalVisible(false)}
          onCancel={() => setManagerModalVisible(false)}
          manager={data}
        />
      )}
    </div>
  )
}
