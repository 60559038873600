import { Select } from 'antd'
import { ReactNode, useState } from 'react'
import { ModalCommon, RequireTag } from '../../../components'
import { IPrice } from '../../../models'
import { priceManagerUI } from '../../../until/text/ui'
import { ElectricForm } from './ElectricForm'
import { GasForm } from './GasForm'
import { WaterForm } from './WaterForm'
import styles from './index.module.scss'
import clsx from 'clsx'
import { typeMeter } from '../../../until/constant'

interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  price?: IPrice
  id?: string
  isLoading?: boolean
  listErr?: any
}

export const ManagerModal = ({
  title,
  visible,
  onOk,
  onCancel,
  price,
  isLoading,
}: IProps) => {
  const [currType, setCurrType] = useState<number>(1)
  const ContentModal: { [key: string]: ReactNode } = {
    1: <WaterForm
      onCancel={onCancel}
      isLoading={isLoading}
      key='water_form'
    />,
    2: <ElectricForm
      onCancel={onCancel}
      isLoading={isLoading}
      key='electric_form'
    />,
    3: <GasForm
      onCancel={onCancel}
      isLoading={isLoading}
      key='gas_form'
    />
  }

  const handleChangeSelectMeterType = (type: number) => {
    setCurrType(type)
  }

  return (
    <>
      <ModalCommon
        destroyOnClose={true}
        title={price === null ? priceManagerUI.titleDefault : title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width="850px"
      >
        <div className={clsx(styles.modal__meterType)}>
          <div className={clsx([styles.modal__meterType__label, 'form__item--full'])}>
            <span>{priceManagerUI.kinds}</span>
            <RequireTag />
          </div>
          <Select
            autoFocus={false}
            maxTagCount='responsive'
            allowClear={false}
            optionLabelProp='label'
            style={{ minWidth: 156 }}
            value={currType}
            placeholder={
              <div className={styles.modal__meterType__option}>
                {priceManagerUI.type}
              </div>
            }
            onChange={handleChangeSelectMeterType}
          >
            {typeMeter.map((type: any) => (
              <Select.Option
                key={type.name}
                value={type.value}
                label={
                  <div className={styles.modal__meterType__option}>
                    {type.name}
                  </div>
                }
              >
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {ContentModal[currType]}
      </ModalCommon>
    </>
  )
}
