export const extraordinaryUi = {
  titleList: '異常値管理',
  titleManager: '異常率',
  titleModalCreate: '異常率追加',
  titleModalEdit: '異常率編集',
  imagePrev: '交換後のメーター値',
  type: 'メーター種類',
  electric: '電気',
  gas: 'ガス',
  water: '水道',
  association: '管理組合No',
  building: '棟No',
  room: '部屋No',
  extraordinary: '異常率 (%)',
  abnormal: '異常率',
  placeholderBuilding: '建物',
  placeholderType: '種類',
  meterReading: '今回検針日',
  buttonCreate: '異常率追加',
  buttonCancel: 'キャンセル',
  buttonSave: '確定',
  buttonDelete: '削除',
  textDelete: '削除してもよろしいですか？',
  year: '年',
  month: '月',
  user: '作業者',
  prevValue: '前回利用量',
  currentValue: '今回利用量',
  extraordinaryValue: '異常値',
  value: '今回指針値',
  totalUse: '利用量',
  prevImage: '画像表示',
  dateTime: 'yyyy/mm/dd',
  noResultsMessage :'以下のボタンから異常率を追加してください',
  image: '画像',
}
