export const CommonUi = {
  logo: ' SNAPPY',
  footer: '© 2022  SNAPPY - All Rights Reserved. ',
  noResultsFound: 'データが存在しません。',
  yes: 'はい',
  no: 'いいえ',
  required: '必須',
  changeAvatar: '画像を変更',
  btnNotFound: 'ホームへ',
  descriptionNotFound: 'このページにアクセスできません。URLにタイプミスがないかご確認ください。',
  uploadImage: '画像を変更',
  placeholderInput: '検索',
}
