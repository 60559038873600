import extraordinaryHeader from '../../assets/images/extraordinaryHeader.png'
import styles from './index.module.scss'
import { useState, useMemo, useCallback } from 'react'
import { DatePicker, Select, Tabs } from 'antd'
import clsx from 'clsx'
import { List } from './List'
import { Manager } from './Manager'
import { Loading } from '../../components/Loading'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import { BuildingIcon, PlusIcon, SearchIcon, TypeIcon } from '../../assets/icons'
import { CommonUi, extraordinaryUi } from '../../until'
import { useGetSuggestionsBuildingAll } from '../../hooks'
import { IBuilding } from '../../models'
import debounce from 'lodash.debounce'
import moment from 'moment'

export const ExtraordinaryManager = () => {
  const statusHeader = [
    {
      key: '1',
      title: extraordinaryUi.titleList,
    },
    {
      key: '2',
      title: extraordinaryUi.titleManager,
    },
  ]
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiRefresh, setLoadingApiRefresh] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [dataDetail, setDataDetail] = useState<any>()
  const [dataFilter, setDataFilter] = useState<any>({
    page: 1,
    page_size: 10,
    search: '',
    building_id: null,
    type: null,
    start_date: null,
    end_date: null,
  })
  const typeMeter: { name: string; value: number }[] = [
    { name: extraordinaryUi.water, value: 1 },
    { name: extraordinaryUi.electric, value: 2 },
    { name: extraordinaryUi.gas, value: 3 },
  ]
  const { data: listBuilding } = useGetSuggestionsBuildingAll()
  const [keys, setKeys] = useState({
    status: '1',
  })
  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValueSearch(e.target.value)
    debouncedSearch(e.target.value)
  }
  const debouncedSearch = useCallback(
    debounce((nextValue) => setDataFilter((prev: any) => ({ ...prev, search: nextValue, page: 1 })), 500),
    [],
  )
  const handleChangeListBuilding = (value: any) => {
    setDataFilter({ ...dataFilter, page: 1, building_id: value })
  }
  const handleChangeSelectType = (value: string) => {
    setDataFilter({
      ...dataFilter,
      page: 1,
      type: value,
    })
  }
  const handleChangeDateStart = (value: any) => {
    setDataFilter({
      ...dataFilter,
      page: 1,
      start_date: value ? moment(value).format('YYYY/MM/DD') : null,
    })
  }
  const handleChangeDateEnd = (value: any) => {
    setDataFilter({
      ...dataFilter,
      page: 1,
      end_date: value ? moment(value).format('YYYY/MM/DD') : null,
    })
  }
  const items: any = useMemo(() => {
    return statusHeader.map((tab: any) => {
      return {
        label: (
          <div
            className={clsx(styles.extraordinary__panel, { [styles.inactive]: keys.status !== tab.key })}
            style={
              keys.status === tab.key
                ? {
                  background: 'linear-gradient(135deg, #94E0EE 0%, #002661 100%)',
                  color: '#FFFFFF',
                }
                : {}
            }
          >
            {tab.title}
          </div>
        ),
        key: tab.key,
        children: (
          <div>
            {keys.status === '1' && (
              <List dataFilter={dataFilter} setDataFilter={setDataFilter} setLoadingApi={setLoadingApi} setLoadingApiRefresh={setLoadingApiRefresh} />
            )}
            {keys.status === '2' && (
              <Manager
                dataFilter={dataFilter}
                setDataFilter={setDataFilter}
                setLoadingApi={setLoadingApi}
                setLoadingApiRefresh={setLoadingApiRefresh}
                visibleAdd={visibleAdd}
                setVisibleAdd={setVisibleAdd}
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                listBuilding={listBuilding}
              />
            )}
          </div>
        ),
      }
    })
  }, [statusHeader])

  return (
    <div className={styles.extraordinary}>
      <PageHeader
        title={keys.status === '1' ? extraordinaryUi.titleList : extraordinaryUi.titleManager}
        backgroundImage={extraordinaryHeader}
        height={'108px'}
      >
        <div className={styles.extraordinary__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={CommonUi.placeholderInput}
            className={styles.extraordinary__header__search__input}
            value={valueSearch}
            onChange={onHandleSearch}
          />
          {keys.status === '2' && (
            <ButtonCommon
              style={{ width: '140px', gap: '8px' }}
              icon={<IconWrap icon={<PlusIcon />} size={14} />}
              className={styles.price__addRule__btn}
              type="primary"
              onClick={() => {
                setVisibleAdd(true)
                setDataDetail(null)
              }}
            >
              {extraordinaryUi.buttonCreate}
            </ButtonCommon>
          )}
        </div>
      </PageHeader>
      <div className={styles.extraordinary__tabs}>
        <Tabs
          id={'tab-1'}
          type="card"
          tabBarExtraContent={
            <>
              <div className={styles.extraordinary__filter}>
                <div className={clsx(styles.group__filter, 'select__group')}>
                  <Select
                    className={styles.extraordinary__filter__select}
                    style={{ width: 160 }}
                    optionLabelProp="label"
                    allowClear
                    placeholder={
                      <>
                        <IconWrap color="#8C8C8C" icon={<BuildingIcon />} className={styles.icon__filter} />
                        {extraordinaryUi.placeholderBuilding}
                      </>
                    }
                    value={dataFilter.building_id}
                    onChange={handleChangeListBuilding}
                  >
                    {listBuilding?.data &&
                      listBuilding.data.map((item: IBuilding) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          label={
                            <>
                              <IconWrap color="#8C8C8C" icon={<BuildingIcon />} className={clsx(styles.icon__filter)} />
                              {`${item.name}`}
                            </>
                          }
                        >
                          {`${item.name}`}
                        </Select.Option>
                      ))}
                  </Select>
                  <Select
                    className={styles.extraordinary__filter__select}
                    style={{ width: 100 }}
                    allowClear
                    optionLabelProp="label"
                    placeholder={
                      <>
                        <IconWrap
                          color="#8C8C8C"
                          icon={<TypeIcon />}
                          className={clsx(styles.icon__filter, styles.staffIcon)}
                        />
                        {extraordinaryUi.placeholderType}
                      </>
                    }
                    value={dataFilter.type}
                    onChange={handleChangeSelectType}
                  >
                    {typeMeter.map((item: { name: string; value: number }) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        className="select__option"
                        label={
                          <>
                            <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={styles.icon__filter} />
                            {item.name}
                          </>
                        }
                      >
                        <span className={styles.text}>{item.name}</span>
                      </Select.Option>
                    ))}
                  </Select>
                  {keys.status === '1' && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <DatePicker
                        onChange={handleChangeDateStart}
                        className={styles.extraordinary__filter__date}
                        placeholder={extraordinaryUi.dateTime}
                        format='YYYY/MM/DD'
                      />
                      <div style={{ width: '15px', height: '2px', background: '#747373' }}></div>
                      <DatePicker
                        onChange={handleChangeDateEnd}
                        className={styles.extraordinary__filter__date}
                        placeholder={extraordinaryUi.dateTime}
                        format='YYYY/MM/DD'
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          }
          activeKey={keys.status}
          onChange={(key: string) => {
            setKeys({ ...keys, status: key })
            setDataFilter({
              page: 1,
              page_size: 10,
              search: '',
              building_id: null,
              type: null,
              date_start: null,
              date_end: null,
            })
            setValueSearch('')
          }}
          items={items}
        />
      </div>
      <Loading isLoading={loadingApi || loadingApiRefresh} />
    </div>
  )
}
