export const DangerIcon = ({ fill = '#FFC069' }: any) => {
  return (
    <svg width="160" height="153" viewBox="0 0 160 153" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M80.1953 110.367C75.822 110.367 72.1953 105.889 72.1953 100.489V34.641C72.1953 29.2414 75.822 24.7637 80.1953 24.7637C84.5687 24.7637 88.1953 29.2414 88.1953 34.641V100.489C88.1953 105.889 84.5687 110.367 80.1953 110.367Z" fill="#FFC069" />
        <path d="M79.7656 134.06C79.2856 134.06 78.7256 133.984 78.1656 133.908C77.6856 133.831 77.2056 133.679 76.7256 133.45C76.2456 133.297 75.7656 133.068 75.2856 132.763C74.8856 132.457 74.4856 132.152 74.0856 131.847C72.6456 130.396 71.7656 128.412 71.7656 126.427C71.7656 124.442 72.6456 122.457 74.0856 121.007C74.4856 120.702 74.8856 120.396 75.2856 120.091C75.7656 119.786 76.2456 119.557 76.7256 119.404C77.2056 119.175 77.6856 119.022 78.1656 118.946C79.2056 118.717 80.3256 118.717 81.2856 118.946C81.8456 119.022 82.3256 119.175 82.8056 119.404C83.2856 119.557 83.7656 119.786 84.2456 120.091C84.6456 120.396 85.0456 120.702 85.4456 121.007C86.8856 122.457 87.7656 124.442 87.7656 126.427C87.7656 128.412 86.8856 130.396 85.4456 131.847C85.0456 132.152 84.6456 132.457 84.2456 132.763C83.7656 133.068 83.2856 133.297 82.8056 133.45C82.3256 133.679 81.8456 133.831 81.2856 133.908C80.8056 133.984 80.2456 134.06 79.7656 134.06Z" fill={fill} />
        <path d="M125.479 152.862H36.4673C22.1461 152.862 11.2033 147.618 5.62166 138.163C0.113512 128.708 0.847931 116.519 7.82492 103.888L52.3308 23.3712C59.675 10.0748 69.81 2.76187 80.9732 2.76187C92.1364 2.76187 102.271 10.0748 109.616 23.3712L154.121 103.962C161.098 116.593 161.906 128.708 156.325 138.237C150.743 147.618 139.8 152.862 125.479 152.862ZM80.9732 13.8421C74.0696 13.8421 67.313 19.1607 61.9517 28.7636L17.5193 109.354C12.5252 118.366 11.7173 126.639 15.1691 132.623C18.6209 138.606 26.2589 141.856 36.5407 141.856H125.552C135.834 141.856 143.399 138.606 146.924 132.623C150.449 126.639 149.568 118.44 144.574 109.354L99.9947 28.7636C94.6334 19.1607 87.8767 13.8421 80.9732 13.8421Z" fill={fill} />
      </g>
    </svg>
  )
}
