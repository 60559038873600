import { Form } from 'antd'
import { ButtonCommon, InputCommon, ModalCommon, RequireTag, SelectCommon } from '../../../components'
import { terminalUi } from '../../../until/text/ui'
import styles from './index.module.scss'
import clsx from 'clsx'
import { openNotificationWithIcon, useGetStaffSuggest } from '../../../hooks'
import { useMutation } from 'react-query'
import { TerminalApi } from '../../../apis'
import { ITerminal } from '../../../models'
import { useEffect } from 'react'
import { terminalMessage } from '../../../until'

interface IProps {
  dataDetail?: any
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  id?: string
  setLoading: any
  refetch: () => void
  listStaff?: any
}

export const TerminalModal = ({ dataDetail, title, visible, setLoading, refetch, onOk, onCancel }: IProps) => {
  const { data: listStaff, isLoading: isLoadingListStaff } = useGetStaffSuggest({})
  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue({
        user_id: dataDetail.user_id,
        device_name: dataDetail.device_name,
        device_id: dataDetail.device_id,
      })
    }
  }, [dataDetail])
  const [form] = Form.useForm()
  const { mutate: mutateCreate } = useMutation((body: ITerminal) => TerminalApi.createMAC(body), {
    onSuccess: () => {
      refetch()
      setLoading(false)
      onCancel()
      openNotificationWithIcon('success', terminalMessage.createSuccess)
    },
    onError: (error: any) => {
      setLoading(false)
      openNotificationWithIcon('error', error?.message)
    },
  })
  const { mutate: mutateUpdate } = useMutation((body: ITerminal) => TerminalApi.updateMAC(dataDetail.id, body), {
    onSuccess: () => {
      refetch()
      setLoading(false)
      onCancel()
      openNotificationWithIcon('success', terminalMessage.updateSuccess)
    },
    onError: (error: any) => {
      setLoading(false)
      openNotificationWithIcon('error', error?.message)
    },
  })
  const onFinish = (value: ITerminal) => {
    dataDetail ? mutateUpdate(value) : mutateCreate(value)
    setLoading(true)
  }

  return (
    <ModalCommon destroyOnClose={true} title={title} visible={visible} onOk={onOk} onCancel={onCancel} width="850px">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={clsx(styles.form__terminal)}>
          <Form.Item
            label={
              <>
                {terminalUi.title}
                <RequireTag />
              </>
            }
            rules={[
              {
                required: true,
                message: terminalMessage.userIdRequired,
              },
            ]}
            style={{ marginBottom: '8px' }}
            name="user_id"
            validateTrigger="onSubmit"
          >
            <SelectCommon
              loading={isLoadingListStaff}
              maxTagCount="responsive"
              showSearch
              placeholder={terminalUi.placeholderSelect}
              filterOption={(input, option) => `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
              options={
                listStaff
                  ?.filter((item: any) => item.status !== '0')
                  ?.map((item: any) => ({ label: item?.username, value: item?.user_id, key: item?.user_id }))
              }
            />
          </Form.Item>
          <Form.Item
            label={<>{terminalUi.device}</>}
            style={{ marginBottom: '8px' }}
            name={'device_name'}
            validateTrigger="onSubmit"
            initialValue={''}
          >
            <InputCommon maxLength={50} placeholder={terminalUi.placeholderInput} />
          </Form.Item>
          <Form.Item
            label={
              <>
                {terminalUi.MAC}
                <RequireTag />
              </>
            }
            rules={[
              {
                required: true,
                message: terminalMessage.MACRequired,
              },
            ]}
            style={{ marginBottom: '0' }}
            name={'device_id'}
            validateTrigger="onSubmit"
            initialValue={''}
          >
            <InputCommon maxLength={50} placeholder={terminalUi.placeholderInput} />
          </Form.Item>
        </div>
        <div className={styles.form__terminal__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {terminalUi.buttonCancel}
          </ButtonCommon>
          <ButtonCommon type="primary" htmlType="submit" style={{ letterSpacing: '-2px' }}>
            {terminalUi.buttonCreate}
          </ButtonCommon>
        </div>
      </Form>
    </ModalCommon>
  )
}
