import { ButtonCommon, ModalCommon } from '../../../components'
import styles from './index.module.scss'
import { useReactToPrint } from 'react-to-print'
import { useRef } from 'react'
import { staffUi } from '../../../until/text/ui'
import QRCode from 'qrcode.react'
import { QR_CODE } from '../../../until/constant'
import { IStaff } from '../../../models'
import { usePrintStaff } from '../../../hooks'

interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  data?: Partial<IStaff>
}

export const ModalQR = ({ title, visible, onOk, onCancel, data }: IProps) => {
  const componentRef = useRef(null)
  const { mutateAsync: mutatePrintAsync, isLoading: isPrintLoading } = usePrintStaff()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleConvertQrCode = async () => {
    await mutatePrintAsync()
    handlePrint()
  }

  function getCanvasBlob(mycanvas: HTMLCanvasElement, qrCode?: string) {
    return new Promise<File>(function (resolve) {
      mycanvas.toBlob((blob: any) => {
        resolve(new File([blob], `qrcode-staff${qrCode}.png`, { type: 'image/png' }))
      })
    })
  }

  const download = async () => {
    const element = document.createElement('a')
    const canvas = document.getElementById(`canvas${data?.qr_code}`) as HTMLCanvasElement
    const fileDownload = await getCanvasBlob(canvas, data?.qr_code)
    element.href = URL.createObjectURL(fileDownload)
    element.download = `${data?.email}.png`
    element.click()
  }

  return (
    <ModalCommon title={title} visible={visible} onOk={onOk} onCancel={onCancel} width="600px">
      <div className={styles.container}>
        <div className={styles.img__container}>
          {data?.qr_code && <QRCode level={QR_CODE.LEVEL} value={data.qr_code} renderAs="canvas" />}
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {staffUi.cancel}
          </ButtonCommon>
          <ButtonCommon type="ghost" onClick={download}>
            {staffUi.download}
          </ButtonCommon>
          <ButtonCommon loading={isPrintLoading} type="primary" onClick={handleConvertQrCode} style={{ letterSpacing: '-2px' }}>
            {staffUi.print}
          </ButtonCommon>
        </div>
        <div className={styles.clone__qrcode} ref={componentRef}>
          {data?.qr_code && (
            <QRCode
              size={QR_CODE.SIZE}
              level={QR_CODE.LEVEL}
              id={`canvas${data?.qr_code}`}
              value={data.qr_code || ''}
              renderAs="canvas"
            />
          )}
          <p>{data?.email}</p>
        </div>
      </div>
    </ModalCommon>
  )
}
