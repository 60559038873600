import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Notification {
  getAll = async (params?: any) => {
    const url = `/${EndPoint.notification}`
    const res = await axiosClient.get(url, { params })
    return res.data?.data
  }
  getCount = async () => {
    const url = `/${EndPoint.notification}/count`
    const res = await axiosClient.get(url)
    return res.data?.data
  }
  markAsRead = async (id: string) => {
    const url = `/${EndPoint.notification}/${id}`
    const res = await axiosClient.get(url)
    return res.data?.data
  }
  markAllAsRead = async () => {
    const url = `/${EndPoint.notification}`
    const res = await axiosClient.put(url)
    return res.data?.data
  }
}

export const NotificationApi = new Notification()
