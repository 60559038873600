import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { StoreProvider } from './store'
import { ConfigProvider } from 'antd'
import ja_JP from 'antd/lib/locale/ja_JP'
import 'moment/locale/ja'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StoreProvider>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ja_JP}>
        <App />
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
    </QueryClientProvider>
  </StoreProvider>,
)
