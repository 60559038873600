import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Admin {
  createAdmin = async (data: any) => {
    const url = `/${EndPoint.staff}`
    const res = await axiosClient.post(url, data)
    return res.data
  }
  getAllAdmin = async (params?: any) => {
    const url = `/${EndPoint.staff}`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  updateAdmin = async (data: any) => {
    const url = `/${EndPoint.staff}/${data.id}`
    const res = await axiosClient.post(url, data.formData)
    return res.data
  }
}

export const AdminApi = new Admin()
