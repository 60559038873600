import clsx from 'clsx'
import styles from './index.module.scss'
import PriceManager from '../../../assets/images/PriceManager.png'
import {
  ButtonCommon,
  ConfirmDelete,
  IconWrap,
  InputCommon,
  PageHeader,
  RequireTag,
  SelectCommon,
} from '../../../components'
import { Divider, Form, InputNumber, Space, Tabs } from 'antd'
import {
  homeUi,
  meterTypeFormula,
  priceManagerUI,
  priceMessage,
  roundingType,
  roundingUnit,
  settingMessage,
} from '../../../until'
import { useState, useEffect } from 'react'
import { FormListTable } from '../FormListTable'
import { Copy, PlusIcon } from '../../../assets/icons'
import {
  checkTariffCodeInDB,
  openNotificationWithIcon,
  useGetDetailFormulaPrice,
  useGetTariffCodeList,
} from '../../../hooks'
import { IFormula, formulaItem } from '../../../models'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { PriceManagerApi } from '../../../apis'
import { Loading } from '../../../components/Loading'
import { xor, last } from 'lodash'
export interface tabMainProps {
  defaultItems: boolean
  errors: boolean
}

export interface validProps {
  idIndex?: number
  errorFromTo?: boolean
  errorRange?: boolean
}

export interface checkValidateRangeProp {
  quantity_level_from?: number
  quantity_level_to?: number
  error?: string
  idIndex?: number
}
interface formProps {
  consumption_tax_rate?: number
  formula_items1?: []
  formula_items2?: []
  formula_items3?: []
  meter_type?: number
  rounding_type_one?: number
  rounding_unit_one?: number
  tariff_code?: string
  tariff_name?: string
}
const CreateFormula = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [tariffCodeList, setTariffCodeList] = useState<string[]>([])
  const [tabsError, setTabsError] = useState<string[]>([])
  const [name, setName] = useState('')
  const [checkTariffCode, setCheckTariffCode] = useState<{ check: boolean; name: string }>({ check: false, name: '' })
  const [form] = Form.useForm()
  const [keys, setKeys] = useState({ status: '1' })
  const [type, setType] = useState<number>(1)
  const [confirmCopy, setConfirmCopy] = useState(false)
  const [tabMainValid, setTabMainValid] = useState<tabMainProps>({ defaultItems: false, errors: false })
  const { data: dataDetailFormulaPrice, isLoading: isLoadingDetailFormulaPrice, error: errorDetailFormulaPrice } = useGetDetailFormulaPrice({ id: id })
  const { data: listTariffCode, isLoading: isLoadingListTariffCode, error } = useGetTariffCodeList({ type: type })
  const { data: tariffCodeInDB } = checkTariffCodeInDB({
    tariff_code: checkTariffCode.name,
    meter_type: type,
    check: checkTariffCode.check,
  })
  const statusHeader: { key: string; title: string }[] = [
    { key: '1', title: priceManagerUI.normalMeter },
    { key: '2', title: priceManagerUI.monthProrated },
    { key: '3', title: priceManagerUI.month2Prorated },
  ]
  useEffect(() => {
    window.location.href.includes('create-formula') && form.resetFields()
    if (listTariffCode) {
      const tariffList = listTariffCode?.map((el: { tariff_code: string }) => el.tariff_code)
      setTariffCodeList(tariffList)
    }
  }, [window.location.href])
  useEffect(() => {
    if (tariffCodeInDB?.data) {
      navigate(`/price-manager/edit-formula/${tariffCodeInDB?.data?.id}`)
    }
  }, [checkTariffCode])

  const addItem = () => {
    if (tariffCodeList.includes(name)) {
      form.setFieldValue('tariff_code', name)
      setName('')
    } else {
      if (name) {
        setCheckTariffCode({ check: true, name: name })
        setTariffCodeList([...tariffCodeList, name])
        form.setFieldValue('tariff_code', name)
        setName('')
      }
    }
  }

  const onHandleCopyFormula = () => {
    const mainData = form.getFieldValue('formula_items1')
    if (mainData && mainData.length > 0) {
      setConfirmCopy(true)
    } else {
      setTabMainValid({ ...tabMainValid, defaultItems: true })
      setTabsError([...tabsError, '1'])
    }
  }

  useEffect(() => {
    if (dataDetailFormulaPrice) {
      form.setFieldsValue({
        tariff_code: dataDetailFormulaPrice?.tariff_code,
        meter_type: dataDetailFormulaPrice?.meter_type === 1 ? 1 : 0,
        rounding_unit_one: dataDetailFormulaPrice?.rounding_unit_one,
        rounding_type_one: dataDetailFormulaPrice?.rounding_type_one,
        tariff_name: dataDetailFormulaPrice?.tariff_name,
        consumption_tax_rate: dataDetailFormulaPrice?.consumption_tax_rate,
        formula_items1: dataDetailFormulaPrice?.formula_items?.filter((el: formulaItem) => el?.daily_division === 0),
        formula_items2: dataDetailFormulaPrice?.formula_items?.filter((el: formulaItem) => el?.daily_division === 1),
        formula_items3: dataDetailFormulaPrice?.formula_items?.filter((el: formulaItem) => el?.daily_division === 2),
      })
      setType(dataDetailFormulaPrice?.meter_type === 1 ? 1 : 0)
    }
  }, [dataDetailFormulaPrice])

  useEffect(() => {
    if (errorDetailFormulaPrice) {
      openNotificationWithIcon('error', errorDetailFormulaPrice?.message)
    }
  }, [errorDetailFormulaPrice])

  useEffect(() => {
    if (listTariffCode) {
      const tariffList = listTariffCode?.map((el: { tariff_code: string }) => el.tariff_code)
      setTariffCodeList(tariffList)
    }
    if (error) setTariffCodeList([])
  }, [listTariffCode, error])
  const copyFinish = () => {
    const formFormula1 = form.getFieldValue('formula_items1')
    form.resetFields(['formula_items2', 'formula_items3'])
    form.setFields([
      { name: 'formula_items2', value: formFormula1 },
      { name: 'formula_items3', value: formFormula1 },
    ])
    const dailyDivisionNormalError = form.getFieldsError().filter(el => el.errors.length > 0 && el.name.includes('formula_items1'))
    if (dailyDivisionNormalError.length > 0) {
      setTabsError(['1'])
    } else {
      setTabsError([])
    }
    openNotificationWithIcon('success', priceMessage.copySuccess)
  }

  const handleChangeType = (value: number) => {
    setTabMainValid({ errors: false, defaultItems: false })
    form.setFields([
      { name: 'formula_items1', value: [] },
      { name: 'formula_items2', value: [] },
      { name: 'formula_items3', value: [] },
      { name: 'tariff_code', value: null, errors: [] },
      { name: 'consumption_tax_rate', value: null },
      { name: 'tariff_name', value: null, errors: [] },
    ])
    setTabsError([])
    setType(value)
  }
  const handleChangeTariffCode = (value: string) => {
    const tariffCodeHaveFormula = listTariffCode.filter(
      (el: { tariff_code: string; formulas: [] }) => el.tariff_code === value && el.formulas.length > 0,
    )
    if (tariffCodeHaveFormula.length > 0 || tariffCodeInDB) {
      form.resetFields()
      setTabsError([])
      navigate(`/price-manager/edit-formula/${tariffCodeHaveFormula[0]?.formulas[0]?.id || tariffCodeInDB?.id}`)
      setTabMainValid({ ...tabMainValid, defaultItems: false })
    }
  }

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (body: IFormula) => PriceManagerApi.createFormulaPrice(body),
    {
      onSuccess: () => {
        openNotificationWithIcon('success', `${priceMessage.createSuccess}`)
        navigate('/price-manager', { state: { tab: '2' } })
      },
      onError: (error: any) => {
        onHandleErrorFromBackEnd(error)
      },
    },
  )
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (body: IFormula) => PriceManagerApi.updateFormulaPrice(body, id as string),
    {
      onSuccess: () => {
        openNotificationWithIcon('success', `${priceMessage.updateSuccess}`)
        navigate('/price-manager', { state: { tab: '2' } })
      },
      onError: (error: any) => {
        onHandleErrorFromBackEnd(error)
      },
    },
  )

  const onHandleErrorFromBackEnd = (error: any) => {
    const tabError: string[] = []
    const entries = Object.entries(error.errors)
    const errorArray = entries.map(el => { return { name: convertFieldName(el[0]), errors: [String(el[1])] } })
    entries.map(el => {
      if (el[0].includes('daily_division.0')) tabError.push('1')
      if (el[0].includes('daily_division.1')) tabError.push('2')
      if (el[0].includes('daily_division.2')) tabError.push('3')
    })
    setTabsError(tabError)
    form.setFields(errorArray)
  }

  const convertFieldName = (name: string) => {
    const returnName = []
    switch (true) {
      case name.includes('daily_division.0'):
        returnName.push('formula_items1')
        break
      case name.includes('daily_division.1'):
        returnName.push('formula_items2')
        break
      case name.includes('daily_division.2'):
        returnName.push('formula_items3')
        break
      default:
        return name
    }
    if (name.includes('index.')) {
      returnName.push(Number(name.split('.')[5]))
      returnName.push(name.split('.')[6])
    }
    return returnName
  }

  const onFail = ({ errorFields }: any) => {
    const errorTable: string[] = xor(errorFields.filter((el: any) => ['formula_items1', 'formula_items2', 'formula_items3'].includes(el.name[0])).map((el: any) => last(el.name[0])))
    if (errorTable && errorTable.length) {
      setTabsError(errorTable)
    }
    const elementError = document.querySelector(`[id="${errorFields[0].name}"]`)
    if (elementError) {
      const fieldContainer = elementError.closest('.ant-row, .ant-col')
      if (fieldContainer) {
        fieldContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }
  const onFinish = async (values: formProps) => {
    if (!tabMainValid.defaultItems && !tabMainValid.errors) {
      const formulaTabNormal = values?.formula_items1?.map((el: formulaItem) => {
        return { ...el, daily_division: 0 }
      })
      let formulaTab1Month = values?.formula_items2?.map((el: formulaItem) => {
        return { ...el, daily_division: 1 }
      })
      let formulaTab2Month = values?.formula_items3?.map((el: formulaItem) => {
        return { ...el, daily_division: 2 }
      })
      if (id && !values?.formula_items2) {
        formulaTab1Month = dataDetailFormulaPrice?.formula_items
          ?.filter((el: formulaItem) => el?.daily_division === 1)
          ?.map((el: formulaItem) => {
            return { ...el, daily_division: 1 }
          })
      }
      if (id && !values?.formula_items3) {
        formulaTab2Month = dataDetailFormulaPrice?.formula_items
          ?.filter((el: formulaItem) => el?.daily_division === 2)
          ?.map((el: formulaItem) => {
            return { ...el, daily_division: 2 }
          })
      }
      const complete = formulaTabNormal?.concat(
        formulaTab1Month ? formulaTab1Month : [],
        formulaTab2Month ? formulaTab2Month : [],
      )
      const objConvert = {
        tariff_code: values.tariff_code,
        meter_type: values.meter_type,
        rounding_unit_one: values.rounding_unit_one,
        rounding_type_one: values.rounding_type_one,
        tariff_name: values.tariff_name,
        consumption_tax_rate: values.consumption_tax_rate,
        formula_items: complete,
      }
      values.meter_type !== 1 && delete objConvert['consumption_tax_rate']
      id ? mutateUpdate(objConvert) : mutateCreate(objConvert)
      setTabMainValid({ ...tabMainValid, defaultItems: false })
      setTabsError([])
    }
    else return
  }
  const items: any = statusHeader.map((tab: { title: string; key: string }, index: number) => {
    return {
      label: (
        <div
          className={clsx(
            styles.createFormula__panel,
            { [styles.createFormula__active]: keys.status === tab.key },
            { [styles.createFormula__error]: tabsError.includes(tab.key) },
          )}
        >
          {tab.title}
        </div>
      ),
      key: tab.key,
      children: (
        <div>
          {
            <div className={clsx(styles.createFormula__table)}>
              <Form.Item validateTrigger="onSubmit">
                <FormListTable
                  tabMainValid={tabMainValid}
                  statusKey={statusHeader[index].key}
                  type={type}
                  form={form}
                />
              </Form.Item>
              {type === 1 ? (
                <div className={clsx(styles.createFormula__table__note)}>
                  <div>{priceManagerUI.charactersPrice}</div>
                  <div>
                    <div>{priceManagerUI.charactersPrice1}</div>
                    <div>{priceManagerUI.charactersPrice2}</div>
                    <div>{priceManagerUI.charactersPrice3}</div>
                  </div>
                  <div>
                    <div>{priceManagerUI.charactersPrice4}</div>
                    <div>{priceManagerUI.charactersPrice5}</div>
                    <div>{priceManagerUI.charactersPrice6}</div>
                  </div>
                </div>
              ) : (
                <div className={clsx(styles.createFormula__table__note)}>
                  <div>{priceManagerUI.charactersPrice}</div>
                  <div>
                    <div>{priceManagerUI.charactersPrice1}</div>
                    <div>{priceManagerUI.charactersPrice3}</div>
                  </div>
                  <div>
                    <div>{priceManagerUI.charactersPrice7}</div>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      ),
    }
  })

  return (
    <div className={clsx(styles.createFormula)}>
      <PageHeader
        title={id ? homeUi.editFormula : homeUi.createFormula}
        backgroundImage={PriceManager}
        height={'108px'}
      ></PageHeader>
      <Form
        form={form}
        initialValues={{ meter_type: type, rounding_type_one: 0, rounding_unit_one: 0 }}
        layout="vertical"
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
        onFinishFailed={onFail}
        onFinish={onFinish}
      >
        <div className={clsx(styles.createFormula__content)}>
          <div className={clsx(styles.createFormula__content__item)}>
            <div className={clsx(styles.createFormula__content__item__label)}>
              {priceManagerUI.kinds}
              {!id && <RequireTag />}
            </div>
            <div className={clsx(styles.createFormula__content__item__input)}>
              <Form.Item name={'meter_type'} validateTrigger="onSubmit">
                <SelectCommon
                  disabled={id ? true : false}
                  onChange={handleChangeType}
                  className={styles['form__item--half']}
                  options={meterTypeFormula}
                />
              </Form.Item>
            </div>
          </div>
          <div className={clsx(styles.createFormula__content__item)}>
            <div className={clsx(styles.createFormula__content__item__label)}>
              {priceManagerUI.tariffCode}
              {!id && <RequireTag />}
            </div>
            <div className={clsx(styles.createFormula__content__item__input)}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `${priceManagerUI.tariffCode}${settingMessage.isRequired}`,
                  },
                ]}
                name={'tariff_code'}
                validateTrigger="onSubmit"
              >
                <SelectCommon
                  disabled={id ? true : false}
                  className={styles['form__item--half']}
                  placeholder={priceManagerUI.listPriceCodePlaceholder}
                  onDropdownVisibleChange={(open: boolean) => {
                    if (!open && form.getFieldValue('tariff_code')) {
                      handleChangeTariffCode(form.getFieldValue('tariff_code'))
                    }
                  }}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: '8px' }}>
                        <InputCommon
                          style={{ borderColor: '#dddde3', boxShadow: 'none' }}
                          maxLength={45}
                          placeholder={priceManagerUI.volumePlaceholder}
                          value={name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <ButtonCommon
                          icon={<IconWrap icon={<PlusIcon />} color="#237804" />}
                          onClick={addItem}
                          type="ghost"
                          style={{ gap: '5px', width: 80, height: 38 }}
                        >
                          {priceManagerUI.add}
                        </ButtonCommon>
                      </Space>
                      <Divider style={{ margin: '8px 0' }} />
                      {menu}
                    </>
                  )}
                  options={tariffCodeList.map((item, index) => ({ label: item, value: item, key: index }))}
                />
              </Form.Item>
            </div>
          </div>
          <div className={clsx(styles.createFormula__line)}></div>
          <div className={clsx(styles.createFormula__body)}>
            <div className={clsx(styles.createFormula__content__item)}>
              <div className={clsx(styles.createFormula__content__item__label)}>
                {priceManagerUI.listPriceName}
                <RequireTag />
              </div>
              <div className={clsx(styles.createFormula__content__item__input)}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: `${priceManagerUI.listPriceName}${settingMessage.isRequired}`,
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || value.trim() !== '') {
                          return Promise.resolve()
                        }
                        return Promise.reject(`${priceManagerUI.listPriceName}${settingMessage.isRequired}`)
                      },
                    }),
                  ]}
                  name={'tariff_name'}
                  validateTrigger="onSubmit"
                >
                  <InputCommon maxLength={100} placeholder={priceManagerUI.listPriceNamePlaceholder} />
                </Form.Item>
              </div>
            </div>
            {type === 1 && (
              <div className={clsx(styles.createFormula__content__item)}>
                <div className={clsx(styles.createFormula__content__item__label)}>{priceManagerUI.listPriceRate}</div>
                <div className={clsx(styles.createFormula__content__item__input)}>
                  <Form.Item name={'consumption_tax_rate'} validateTrigger="onSubmit">
                    <InputNumber
                      min={0}
                      max={99}
                      style={{ borderRadius: '20px' }}
                      controls={false}
                      className={styles['form__item--half']}
                      placeholder={priceManagerUI.listPriceRatePlaceholder}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className={clsx(styles.createFormula__content__item__bottom)}>
              <div className={clsx(styles.createFormula__content__item__label)}>
                {priceManagerUI.listPriceFractionSetting}
                <RequireTag />
              </div>
              <div className={clsx(styles.createFormula__content__item__bottom__body)}>
                <div className={clsx(styles.createFormula__content__item__bottom__body__left)}>
                  <div className={styles['form__item--half']}>
                    <Form.Item name={'rounding_unit_one'} validateTrigger="onSubmit">
                      <SelectCommon className={styles['form__item--half']} options={roundingUnit} />
                    </Form.Item>
                  </div>
                  <div className={styles['form__item--half']}>
                    <Form.Item name={'rounding_type_one'} validateTrigger="onSubmit">
                      <SelectCommon className={styles['form__item--half']} options={roundingType} />
                    </Form.Item>
                  </div>
                </div>
                <ButtonCommon
                  onClick={onHandleCopyFormula}
                  icon={<IconWrap icon={<Copy />} color="#237804" />}
                  style={{ width: '170px', display: 'flex', gap: '5px' }}
                  type="ghost"
                >
                  {priceManagerUI.listPriceCopy}
                </ButtonCommon>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.createFormula__tabs}>
          <Tabs
            id={'tab-1'}
            type="card"
            items={items}
            activeKey={keys.status}
            onChange={(key: string) => {
              setKeys({ ...keys, status: key })
            }}
          />
        </div>
        <div className={styles.createFormula__footer}>
          <ButtonCommon type="ghost" onClick={() => navigate('/price-manager', { state: { tab: '2' } })}>
            {priceManagerUI.cancel}
          </ButtonCommon>
          <ButtonCommon
            type="primary"
            disabled={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              if (!form.getFieldsValue()['formula_items1']?.length) {
                setTabsError([...tabsError, '1'])
                setTabMainValid({ ...tabMainValid, defaultItems: true })
              } else {
                setTabMainValid({ ...tabMainValid, defaultItems: false })
              }
            }}
            htmlType="submit"
            style={{ letterSpacing: '-2px' }
            }
          >
            {id ? priceManagerUI.update : priceManagerUI.save}
          </ButtonCommon>
        </div>
      </Form>
      <ConfirmDelete
        title={priceManagerUI.confirm}
        visible={confirmCopy}
        onCancel={() => setConfirmCopy(false)}
        text={priceManagerUI.textConfirm}
        width={'50%'}
        onConfirmDelete={() => {
          copyFinish()
          setConfirmCopy(false)
          setTabMainValid({ errors: false, defaultItems: false })
        }}
      />
      <Loading
        isLoading={isLoadingDetailFormulaPrice || isLoadingListTariffCode || isLoadingUpdate || isLoadingCreate}
      />
    </div>
  )
}

export default CreateFormula
