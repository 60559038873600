import { Pagination, PaginationProps } from 'antd'
import React from 'react'
import styles from './index.module.scss'
interface IPagination {
  defaultCurrent: number
  total: number
  pageSize: number
  current: number
  // eslint-disable-next-line no-unused-vars
  onChangePagination?: (page: number, pageSize: number) => void
  pageSizeOptions?: any
  showSizeChanger?: boolean
  showTotal?: boolean
}
export const PaginationCommon = ({
  total,
  pageSize,
  current,
  showSizeChanger,
  onChangePagination,
  pageSizeOptions,
}: IPagination) => {
  const onChange: PaginationProps['onChange'] = (page, pageSize) => {
    onChangePagination?.(page, pageSize)
  }

  return (
    <div className={styles.pagination}>
      <Pagination
        defaultCurrent={1}
        total={total}
        showSizeChanger={showSizeChanger}
        pageSize={pageSize}
        size={'small'}
        current={current}
        onChange={onChange}
        pageSizeOptions={pageSizeOptions}
      />
    </div>
  )
}
