import { useEffect, useState } from 'react'
import { ButtonCommon, IconWrap, InputCommon, PageHeader, RequireTag, SelectCommon, UploadImage } from '../../components'
import headerImg from '../../assets/images/profileHeader.png'
import { Col, Form, Row, Select } from 'antd'
import styles from './index.module.scss'
import clsx from 'clsx'
import { PasswordModal } from './changePasswordModal'
import { EditIcon } from '../../assets/icons'
import { useGetProfile, usePostProfile } from '../../hooks/query/user'
import { Loading } from '../../components/Loading'
import { UploadFile } from 'antd/es/upload'
import { managerMessage, profileMessage, staffMessage } from '../../until/text/messages'
import { profileUi, staffUi } from '../../until/text/ui'
import { envApiUrl } from '../../apis/axiosClient'
import { regex } from '../../until'
import { openNotificationWithIcon } from '../../hooks'

export const Profile = () => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState<UploadFile>()
  const [showModal, setShowModal] = useState(false)
  const [isLoadingImg, setIsLoadingImg] = useState(true)
  const { isLoading: dataLoading, data, error } = useGetProfile()
  const [imgAvatar, setImgAvatar] = useState(data?.url_avatar)
  const [clearImg, setClearImg] = useState(false)
  const { mutateAsync, isLoading } = usePostProfile()
  const [isView, setIsView] = useState(true)
  const onFinish = async (values: any) => {
    delete values.password
    try {
      await mutateAsync({
        ...values,
        avatar: avatar || data?.avatar,
        use_two_step_authentication: values.use_two_step_authentication === 1
      })
      openNotificationWithIcon('success', '', profileMessage.updateSuccess)
      setIsView(true)
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        form.setFields(arrError)
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
    }
  }
  const onHideModal = () => {
    setShowModal(false)
  }

  const oncancel = () => {
    setIsView(true)
    setClearImg(true)
    form.setFieldsValue({
      postal_code: data?.postal_code || '',
      full_name: data?.full_name || '',
      full_name_katakana: data?.full_name_katakana || '',
      email: data?.email || '',
      number_phone: data?.number_phone || '',
      address_1: data?.address_1 || '',
      address_2: data?.address_2 || '',
      username: data?.username || '',
      use_two_step_authentication: data?.use_two_step_authentication ? 1 : 0
    })
  }


  useEffect(() => {
    if (data) {
      setImgAvatar(data.url_avatar)
      form.setFieldsValue({
        postal_code: data.postal_code || '',
        full_name: data.full_name || '',
        full_name_katakana: data.full_name_katakana || '',
        email: data.email || '',
        number_phone: data.number_phone || '',
        address_1: data.address_1 || '',
        address_2: data.address_2 || '',
        username: data.username || '',
        use_two_step_authentication: data?.use_two_step_authentication ? 1 : 0
      })
      if (!data.avatar) {
        setIsLoadingImg(false)
      }
      return
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setIsLoadingImg(false)
      openNotificationWithIcon('error', '', error?.message)
    }
  }, [error])

  return (
    <div>
      <Loading isLoading={dataLoading || isLoadingImg} />
      <PageHeader title={profileUi.profile} backgroundImage={headerImg} height="108px"></PageHeader>
      <div className={styles.content}>
        <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" className={styles.form}>
          <div className={clsx(styles.form__avatar)}>
            <UploadImage
              useDefaultAvatar={true}
              url={imgAvatar ? `${envApiUrl}${imgAvatar}` : ''}
              onChangeAvatar={setAvatar}
              setLoadingImg={setIsLoadingImg}
              title=""
              hasBorder={false}
              className={clsx(styles.form__avatar__upload)}
              isAllow={isView}
              onClear={clearImg}
              setClear={() => setClearImg(false)}
            />
          </div>
          <div className={styles['form__control--container']}>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      {profileUi.name}
                      {!isView ? <RequireTag /> : null}
                    </>
                  }
                  name="full_name"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: profileUi.inputFullName,
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={50}
                    disabled={isView}
                    placeholder={isView ? '' : profileUi.placeholderFullName}
                    onBlur={() => {
                      form.setFieldValue('full_name', form.getFieldValue('full_name')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.nameKatakana}
                  name="full_name_katakana"
                  className={styles['form__item--half']}
                >
                  <InputCommon
                    maxLength={50}
                    disabled={isView}
                    placeholder={isView ? '' : profileUi.placeholderKatakana}
                    onBlur={() => {
                      form.setFieldValue('full_name_katakana', form.getFieldValue('full_name_katakana')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  validateTrigger="onSubmit"
                  label={
                    <>
                      {profileUi.username}
                      {!isView ? <RequireTag /> : null}
                    </>
                  }
                  name="username"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: profileUi.inputUserName,
                    },
                    {
                      validator(_, value) {
                        if (value.indexOf(' ') > 0) {
                          return Promise.reject(`${staffMessage.whiteSpace}`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <InputCommon
                    maxLength={50}
                    disabled={isView}
                    placeholder={isView ? '' : profileUi.placeholderUsername}
                    onBlur={() => {
                      form.setFieldValue('username', form.getFieldValue('username')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.password}
                  name="password"
                  className={clsx(styles.form__item, styles['form__item--half'])}
                >
                  <div>
                    <InputCommon type={'password'} placeholder="●●●●●●●●" disabled={true} />
                    <IconWrap
                      icon={<EditIcon />}
                      className={styles.form__item__edit}
                      onClick={() => {
                        setShowModal(true)
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label={profileUi.email} name="email" className={styles['form__item--half']}>
                  <InputCommon
                    maxLength={150}
                    placeholder={isView ? '' : profileUi.placeholderEmail}
                    disabled={true}
                    onBlur={() => {
                      form.setFieldValue('email', form.getFieldValue('email')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.phoneNumber}
                  name="number_phone"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      pattern: regex.validatePhone,
                      message: staffMessage.invalidPhone,
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={11}
                    disabled={isView}
                    placeholder={isView ? '' : profileUi.placeholderPhoneNumber}
                    onBlur={() => {
                      form.setFieldValue('number_phone', form.getFieldValue('number_phone')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={profileUi.address_1}
              name="address_1"
              className={styles['form__item--full']}
              initialValue={''}
            >
              <InputCommon
                maxLength={50}
                disabled={isView}
                placeholder={isView ? '' : profileUi.placeholderAddress1}
                onBlur={() => {
                  form.setFieldValue('address_1', form.getFieldValue('address_1')?.trim())
                }}
              />
            </Form.Item>
            <Form.Item
              label={profileUi.address_2}
              name="address_2"
              className={styles['form__item--full']}
              initialValue={''}
            >
              <InputCommon
                maxLength={50}
                disabled={isView}
                placeholder={isView ? '' : profileUi.placeholderAddress2}
                onBlur={() => {
                  form.setFieldValue('address_2', form.getFieldValue('address_2')?.trim())
                }}
              />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.postal_code}
                  name="postal_code"
                  className={styles['form__item--full']}
                  initialValue={''}
                  rules={[
                    {
                      pattern: regex.validatePostalCode,
                      message: profileUi.invalidPostalCode,
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={8}
                    disabled={isView}
                    placeholder={isView ? '' : profileUi.placeholderPostalCode}
                    onBlur={() => {
                      form.setFieldValue('postal_code', form.getFieldValue('postal_code')?.trim())
                    }}
                  />
                </Form.Item></Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      {staffUi.twoFactorAuthentication}
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: staffUi.twoFactorAuthentication + managerMessage.isRequired,
                    },
                  ]}
                  name="use_two_step_authentication"
                  className={styles['form__item--half']}
                  validateTrigger="onSubmit"
                >
                  <SelectCommon 
                    disabled={isView}
                    autoFocus={false}
                    maxTagCount="responsive"
                    placeholder={staffUi.twoFactorAuthenticationPlaceholder}
                  >
                    <Select.Option value={1}>{staffUi.active}</Select.Option>
                    <Select.Option value={0}>{staffUi.inactive}</Select.Option>
                  </SelectCommon>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={styles.form__btn}>
            {isView ? (
              <ButtonCommon type="ghost" onClick={() => setIsView(false)} style={{ letterSpacing: '-2px' }}>
                {profileUi.edit}
              </ButtonCommon>
            ) : (
              <>
                <ButtonCommon type="ghost" onClick={oncancel}>
                  {profileUi.cancel}
                </ButtonCommon>
                <ButtonCommon loading={isLoading} type="primary" htmlType="submit" style={{ letterSpacing: '-2px' }}>
                  {profileUi.save}
                </ButtonCommon>
              </>
            )}
          </div>
        </Form>
        <PasswordModal visible={showModal} onOk={onHideModal} onCancel={onHideModal} />
      </div>
    </div>
  )
}
