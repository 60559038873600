import { Check, Notification } from '../../assets/icons'
import { useState } from 'react'
import styles from './index.module.scss'
import { Badge, Dropdown, List, Skeleton, Space } from 'antd'
import { notificationsUi } from '../../until/text/ui'
import InfiniteScroll from 'react-infinite-scroll-component'
import { IconWrap } from '../../components'
import { useMutation, useQuery } from 'react-query'
import { uniqBy } from 'lodash'
import { NotificationApi } from '../../apis'
import { useGetNotificationCount } from '../../hooks/query/notification'
import { onMessageListener } from '../../firebase'
import { Loading } from '../../components/Loading'
import { useNavigate } from 'react-router-dom'

export const Notifications = () => {
  const navigate = useNavigate()
  const [openNotifications, setOpenNotifications] = useState(false)
  const [notifications, setNotifications] = useState<{
    params: {
      page_size: number
      page: number
    }
    data: any
  }>({
    params: {
      page_size: 50,
      page: 1,
    },
    data: [],
  })
  const [loadingNotifications, setLoadingNotifications] = useState(false)
  const { data: count, refetch: refetchCount } = useGetNotificationCount()

  const { data: dataNotifications, refetch } = useQuery(
    ['notification', notifications.params],
    () => NotificationApi.getAll(notifications.params),
    {
      onSuccess: (data: any) => {
        if (data?.data && data?.data?.length > 0) {
          setLoadingNotifications(false)
          setNotifications((prev: any) => ({
            ...prev,
            data: uniqBy([...(data?.data as []), ...prev.data], 'id').sort((a: any, b: any) => b.id - a.id),
          }))
        }
        if (notifications.params.page === 1) {
          const element = document.getElementById('scrollableDiv')
          element && element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        }
      },
    },
  )
  const { mutate: markAsRead } = useMutation((id: string) => NotificationApi.markAsRead(id), {
    onSuccess: () => {
      refetchCount()
      refetch()
    },
  })
  const { mutate: markAll } = useMutation(() => NotificationApi.markAllAsRead(), {
    onSuccess: async () => {
      await setNotifications({
        params: {
          page_size: 50,
          page: 1,
        },
        data: [],
      })
      refetch()
      refetchCount()
    },
  })
  const handleInfiniteOnLoad = () => {
    setNotifications((prev) => ({ ...prev, params: { ...prev.params, page: prev.params.page + 1 } }))
  }
  const onHandleNotification = (item: any) => {
    navigate(`/extraordinary-manager`)
    setOpenNotifications(false)
    if (item.read_at) {
      return
    }
    setLoadingNotifications(true)
    markAsRead(item.id)
  }
  const markAllAsRead = () => {
    if (count <= 0) {
      setLoadingNotifications(false)
    } else {
      setLoadingNotifications(true)
      markAll()
    }
  }
  onMessageListener().then(() => {
    refetch()
    refetchCount()
  })
  const menu = (
    <div
      style={{
        background: '#ffffff',
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
        borderRadius: 8,
        width: 375,
        padding: 24,
        marginTop: 10,
        position: 'absolute',
        right: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #484848',
          marginBottom: 24,
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            fontSize: 20,
            fontWeight: 700,
            margin: 0,
          }}
        >
          {notificationsUi.notification}
        </h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#348B7B',
            cursor: 'pointer',
          }}
          onClick={markAllAsRead}
        >
          <Check />
          {notificationsUi.check}
        </div>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: 'auto',
        }}
      >
        {loadingNotifications || (notifications?.data && notifications?.data.length > 0) ? (
          <InfiniteScroll
            dataLength={notifications?.data.length}
            next={handleInfiniteOnLoad}
            hasMore={notifications?.data.length < dataNotifications?.pagination?.total}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={notifications?.data || []}
              renderItem={(item: any) => (
                <List.Item
                  key={item.id}
                  onClick={() => onHandleNotification(item)}
                  style={{ paddingRight: '20px', cursor: 'pointer' }}
                >
                  <Space size={8} align="start">
                    <div>
                      <Badge dot={!item.read_at ? true : false} status="success" offset={[10, 10]}>
                        <div
                          style={
                            !item.read_at
                              ? { whiteSpace: 'pre-wrap', fontSize: '16px', fontWeight: '700' }
                              : { whiteSpace: 'pre-wrap', fontSize: '16px' }
                          }
                        >
                          {item?.data?.message}
                        </div>
                      </Badge>
                      <div>{item?.created_date}</div>
                    </div>
                  </Space>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        ) : <div style={{ textAlign: 'center' }}>{notificationsUi.no_data}</div>
        }
      </div>
      <Loading isLoading={loadingNotifications} />
    </div>
  )

  return (
    <div className={styles.notification}>
      <Dropdown placement="bottomRight" visible={openNotifications} onVisibleChange={setOpenNotifications} overlay={menu} trigger={['click']}>
        <div>
          <Badge count={count}>
            <IconWrap size={24} icon={<Notification />} />
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}
