import { Col, Form, Row, Select } from 'antd'
import { UploadFile } from 'antd/es/upload'
import { useEffect, useState } from 'react'
import { envApiUrl } from '../../../apis/axiosClient'
import { Alert, ResetToken } from '../../../assets/icons'
import { ButtonCommon, IconWrap, InputCommon, ModalCommon, RequireTag, SelectCommon } from '../../../components'
import { UploadImage } from '../../../components/UploadImage'
import { openNotificationWithIcon, useGetAllContracts } from '../../../hooks'
import { useUpdateAdmin } from '../../../hooks/query/manager'
import { useGenToken } from '../../../hooks/query/user'
import { regex } from '../../../until'
import { managerMessage } from '../../../until/text/messages'
import { managerUI, staffUi } from '../../../until/text/ui'
import styles from './index.module.scss'

interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  manager?: any
  id?: string
  isLoading?: boolean
  listErr?: any

  // eslint-disable-next-line no-unused-vars
  handleOnFinish?: (value: any) => void
}

export const ManagerModal = ({
  title,
  visible,
  onOk,
  onCancel,
  manager,
  handleOnFinish,
  isLoading,
  listErr,
}: IProps) => {
  const [avatar, setAvatar] = useState<UploadFile>()
  const mutateUpdateManager = useUpdateAdmin()
  const resetToken = useGenToken()
  const [isResetToken, setIsResetToken] = useState(false)

  const { data } = useGetAllContracts()
  const [form] = Form.useForm()
  const onFinish = async (value: any) => {
    const body = {
      ...value,
      use_two_step_authentication: value.use_two_step_authentication === 1
    }
    if (manager) {
      try {
        const formData = new FormData()
        for (const key in body) {
          formData.append(key, body[key])
        }
        formData.append('avatar', avatar as any)
        await mutateUpdateManager.mutateAsync({ id: manager.id, formData: formData })
        onOk()
        openNotificationWithIcon('success', '', `${managerMessage.updateSuccess}`)
      } catch (error: any) {
        if (error.errors) {
          const entries = Object.entries(error.errors)
          const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
          form.setFields(arrError)
        } else {
          openNotificationWithIcon('error', '', error?.message)
        }
      }
    } else {
      body.user_type = '1'
      if (avatar) {
        handleOnFinish?.({ ...body, avatar: avatar })
      } else {
        handleOnFinish?.({ ...body })
      }
    }
  }

  const onResetToken = async () => {
    try {
      await resetToken.mutateAsync(manager.id)
      openNotificationWithIcon('success', managerMessage.resetSuccess)
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message)
    }
    setIsResetToken(false)
  }
  useEffect(() => {
    if (listErr && listErr.length > 0) {
      form.setFields(listErr)
    }
  }, [listErr])

  useEffect(() => {
    if (manager) {
      form.setFieldsValue({
        full_name: manager.full_name || '',
        full_name_katakana: manager.full_name_katakana || '',
        username: manager.username || '',
        number_phone: manager.number_phone || '',
        user_type: manager.user_type || '',
        email: manager.email || '',
        contract_id: manager.contract_code || '',
        address_1: manager.address_1 || '',
        address_2: manager.address_2 || '',
        status: manager.status || '',
        postal_code: manager.postal_code || '',
        building_ids: manager.building_ids,
        use_two_step_authentication: manager?.use_two_step_authentication ? 1 : 0
      })

      return
    }
  }, [])

  return (
    <>
      <ModalCommon
        destroyOnClose={true}
        title={manager ? managerUI.titleDefault : title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width="850px"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className={styles.form__container}>
            <div className={styles.form__top}>
              <div className={styles.form__uploadImage}>
                <UploadImage
                  title={managerUI.profileImage}
                  hasBorder={true}
                  url={manager?.url_avatar ? `${envApiUrl}${manager?.url_avatar}` : ''}
                  onChangeAvatar={setAvatar}
                />
              </div>
              <div className={styles.form__name}>
                <Form.Item
                  label={
                    <>
                      {managerUI.fullName}
                      <RequireTag />
                    </>
                  }
                  name="full_name"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: managerMessage.inputFullName,
                    },
                  ]}
                  initialValue={''}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={50}
                    placeholder={managerUI.placeholderName}
                    onBlur={() => {
                      form.setFieldValue('full_name', form.getFieldValue('full_name')?.trim())
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <>
                      {managerUI.userName}
                      <RequireTag />
                    </>
                  }
                  name="username"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: managerMessage.inputUserName,
                    },
                    {
                      validator(_, value) {
                        if (value.indexOf(' ') > 0) {
                          return Promise.reject(`${managerMessage.whiteSpace}`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                  initialValue={''}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={50}
                    onBlur={() => {
                      form.setFieldValue('username', form.getFieldValue('username')?.trim())
                    }}
                    placeholder={managerUI.placeholderUserName}
                  />
                </Form.Item>
              </div>
              <div className={styles.form__name}>
                <Form.Item
                  label={managerUI.fullNameKatakana}
                  name="full_name_katakana"
                  className={styles['form__item--half']}
                  initialValue={''}
                >
                  <InputCommon
                    maxLength={50}
                    onBlur={() => {
                      form.setFieldValue('full_name_katakana', form.getFieldValue('full_name_katakana')?.trim())
                    }}
                    placeholder={managerUI.placeholderNameKatakana}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      {managerUI.contract}
                      {manager ? null : <RequireTag />}
                    </>
                  }
                  name="contract_id"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: managerMessage.selectContract,
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <SelectCommon
                    autoFocus={false}
                    maxTagCount="responsive"
                    disabled={!!manager}
                    placeholder={<p className={styles.form__item__placeholder}>{managerUI.placeholderContract}</p>}
                  >
                    {data &&
                      data.length > 0 &&
                      data
                        .filter((item: any) => item.status === false)
                        .map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.contract_code}
                          </Select.Option>
                        ))}
                  </SelectCommon>
                </Form.Item>
              </div>
            </div>
            <div className={styles.form__bottom}>
              <Form.Item
                label={<>{managerUI.phone}</>}
                rules={[
                  {
                    pattern: regex.validatePhone,
                    message: managerMessage.invalidPhone,
                  },
                ]}
                name="number_phone"
                className={styles['form__item--half']}
                validateTrigger="onSubmit"
                initialValue={''}
              >
                <InputCommon
                  maxLength={11}
                  onBlur={() => {
                    form.setFieldValue('number_phone', form.getFieldValue('number_phone')?.trim())
                  }}
                  placeholder={managerUI.placeholderPhone}
                />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    {managerUI.email}
                    {manager ? null : <RequireTag />}
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: managerMessage.inputEmail,
                  },
                  {
                    pattern: regex.validateEmail,
                    message: managerMessage.invalidEmail,
                  },
                ]}
                name="email"
                className={styles['form__item--half']}
                initialValue={''}
                validateTrigger="onSubmit"
              >
                <InputCommon maxLength={150} placeholder={managerUI.placeholderEmail} disabled={!!manager} />
              </Form.Item>
              <Form.Item
                label={<>{managerUI.address1}</>}
                name="address_1"
                className={styles['form__item--full']}
                initialValue={''}
              >
                <InputCommon
                  maxLength={50}
                  onBlur={() => {
                    form.setFieldValue('address_1', form.getFieldValue('address_1')?.trim())
                  }}
                  placeholder={managerUI.placeholderAddress}
                />
              </Form.Item>
              <Form.Item
                label={managerUI.address2}
                name="address_2"
                className={styles['form__item--full']}
                initialValue={''}
              >
                <InputCommon
                  maxLength={50}
                  onBlur={() => {
                    form.setFieldValue('address_2', form.getFieldValue('address_2')?.trim())
                  }}
                  placeholder={managerUI.placeholderAddress}
                />
              </Form.Item>
              <Form.Item
                label={<>{managerUI.postalCode}</>}
                rules={[
                  {
                    pattern: regex.validatePostalCode,
                    message: managerMessage.invalidPostalCode,
                  },
                ]}
                initialValue={''}
                name="postal_code"
                className={styles['form__item--half']}
                validateTrigger="onSubmit"
              >
                <InputCommon
                  maxLength={8}
                  onBlur={() => {
                    form.setFieldValue('postal_code', form.getFieldValue('postal_code')?.trim())
                  }}
                  placeholder={managerUI.placeholderPostalCode}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    {managerUI.status}
                    {manager ? null : <RequireTag />}
                  </>
                }
                name="status"
                className={styles['form__item--half']}
                // initialValue={'1'}
                rules={[{ required: true, message: managerMessage.selectStatus }]}
                validateTrigger="onSubmit"
              >
                <SelectCommon autoFocus={false} placeholder={managerUI.placeholderStatus}>
                  <Select.Option value="1">{managerUI.active}</Select.Option>
                  <Select.Option value="0">{managerUI.inactive}</Select.Option>
                </SelectCommon>
              </Form.Item>
            </div>
            <Row gutter={[40, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      <strong>{staffUi.twoFactorAuthentication}</strong>
                      {manager ? null : <RequireTag />}
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: staffUi.twoFactorAuthentication + managerMessage.isRequired,
                    },
                  ]}
                  name="use_two_step_authentication"
                  className={styles['form__item--half']}
                  validateTrigger="onSubmit"
                >
                  <SelectCommon
                    autoFocus={false}
                    maxTagCount="responsive"
                    placeholder={staffUi.twoFactorAuthenticationPlaceholder}
                  >
                    <Select.Option value={1}>{staffUi.active}</Select.Option>
                    <Select.Option value={0}>{staffUi.inactive}</Select.Option>
                  </SelectCommon>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                {manager && <Form.Item
                  label={
                    <strong>
                      {managerUI.accessToken}
                    </strong>
                  }
                  className={styles['form__item--full']}
                >
                  <div style={{ border: '1px solid #dddde3', borderRadius: '20px', background: '#f5f5f5' }}>
                    <InputCommon value={manager?.token} style={{ border: '0px', width: '90%' }} disabled />
                    <IconWrap
                      icon={<ResetToken />}
                      className={styles.form__item__edit}
                      onClick={() => setIsResetToken(true)}
                    />
                  </div>
                </Form.Item>}
              </Col>
            </Row>
          </div>
          <div className={styles.form__footer}>
            <ButtonCommon type="ghost" onClick={onCancel}>
              {managerUI.cancel}
            </ButtonCommon>
            <ButtonCommon
              type="primary"
              htmlType="submit"
              loading={isLoading || mutateUpdateManager.isLoading}
              style={{ letterSpacing: '-2px' }}
            >
              {managerUI.save}
            </ButtonCommon>
          </div>
        </Form>
      </ModalCommon>
      {
        isResetToken &&
        <ModalCommon
          destroyOnClose={true}
          title={managerUI.titleConfirmReset}
          visible={isResetToken}
          onOk={onResetToken}
          onCancel={() => setIsResetToken(false)}
          width="500px"
        >
          <div>
            <div className={styles.alertContent}>
              <Alert />
              <p>{managerUI.confirmReset}</p>
            </div>
            <div className={styles.form__footer}>
              <ButtonCommon type="ghost" onClick={() => setIsResetToken(false)}>
                {managerUI.cancel}
              </ButtonCommon>
              <ButtonCommon
                type="primary"
                style={{ letterSpacing: '-2px' }}
                onClick={onResetToken}
              >
                {managerUI.save}
              </ButtonCommon>
            </div>
          </div>
        </ModalCommon>
      }
    </>
  )
}
