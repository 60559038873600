import clsx from 'clsx'
import { PlusIcon, SearchIcon, TypeIcon } from '../../assets/icons'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import { priceManagerUI } from '../../until/text/ui/price'
import styles from './index.module.scss'
import PriceManager from '../../assets/images/PriceManager.png'
import { Select, Table, Tabs, Upload, UploadProps } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { ManagerModal } from './Modal'
import {
  useGetPricesManager,
  useImportPriceManager,
  openNotificationWithIcon,
  useGetListFormulaPrice,
} from '../../hooks'
import debounce from 'lodash.debounce'
import { Loading } from '../../components/Loading'
import { homeUi, meterUi } from '../../until/text/ui'
import { IPrice } from '../../models'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'
import { priceMessage } from '../../until/text/messages'
import Manager from './Manager'
import { SIZE_5MB, meterTypeFormula } from '../../until/constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataEmpty } from '../../components/DataEmpty'
import { PaginationCommon } from '../../components/Pagination'

interface propItemColumn {
  id: number
  meter_type: number
  tariff_code: string
  tariff_name: string
}
const Price = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [visible, setVisible] = useState(false)
  const [currentPriceItem, setCurrentPriceItem] = useState<IPrice | null>(null)
  const [keys, setKeys] = useState({ status: state ? state.tab : '1' })
  const [params, setParams] = useState<{ [key: string]: any }>({
    page: 1,
    page_size: 10,
    search: '',
    meter_type: null,
  })
  const [textSearch, setTextSearch] = useState<string>('')
  const background = 'linear-gradient(135deg, #94E0EE 0%, #002661 100%)'
  const statusHeader = [
    {
      key: '1',
      title: priceManagerUI.priceManager,
    },
    {
      key: '2',
      title: priceManagerUI.calculationFormula,
    },
  ]
  const mutateImportCsv = useImportPriceManager()
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const { data, isLoading, isFetching } = useGetPricesManager({ params: params, key: keys.status })
  const {
    data: listFormulaPrice,
    isLoading: loadingListFormulaPrice,
    isFetching: fetchingListFormulaPrice,
  } = useGetListFormulaPrice({ params: params, key: keys.status })

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextSearch(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce(
      (nextValue) => setParams((prev: { [key: string]: any }) => ({ ...prev, search: nextValue, page: 1 })),
      500,
    ),
    [],
  )
  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }
  const handleChangeSelectType = (value: string) => {
    setParams({
      ...params,
      page: 1,
      meter_type: value,
    })
  }

  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    accept: 'text/csv',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file: RcFile) {
      const isCSV = file.type === 'text/csv'
      const isLt5M = file.size < SIZE_5MB
      if (!isCSV) {
        openNotificationWithIcon('error', '', `${priceMessage.invalidCsv}\n${priceMessage.invalidCsv2}`)
        return isCSV
      }
      if (!isLt5M) {
        openNotificationWithIcon('error', '', priceMessage.csv5M)
        return isLt5M
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotificationWithIcon('success', '', `${info.file.name} ${priceMessage.upLoadSuccess}`)
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', '', `${info.file.name} ${priceMessage.upLoadFail}`)
      }
    },
    customRequest: async (options: any) => {
      const { file } = options
      const fmData = new FormData()
      fmData.append('file', file)
      try {
        setIsLoadingUpload(true)
        const data = await mutateImportCsv.mutateAsync(fmData)
        openNotificationWithIcon('success', '', data.data)
      } catch (err: any) {
        openNotificationWithIcon('error', '', err.message)
      }
      setIsLoadingUpload(false)
    },
  }
  const columns = [
    {
      title: priceManagerUI.tariffCode,
      dataIndex: 'tariff_code',
      render: (data: string) => <div style={{ width: 300 }}>{data}</div>
    },
    {
      title: priceManagerUI.listPriceName,
      dataIndex: 'tariff_name',
      render: (data: string) => <div style={{ width: 540 }}>{data}</div>
    },
    {
      title: priceManagerUI.kinds,
      dataIndex: 'meter_type',
      render: (type: number) => <div style={{ width: 60 }}>{type === 1 ? meterTypeFormula[0].label : meterTypeFormula[1].label}</div>,
    },
    {
      render: (item: propItemColumn) => {
        return (
          <div style={{ display: 'flex', gap: '10px', width: 30 }}>
            <div
              style={{ cursor: 'pointer', color: '#1890FF' }}
              onClick={() => navigate(`/price-manager/edit-formula/${item.id}`)}
            >
              {priceManagerUI.edit}
            </div>
          </div>
        )
      },
    },
  ]
  const items: any = useMemo(() => {
    return statusHeader.map((tab: { title: string; key: string }) => {
      return {
        label: (
          <div
            className={clsx(styles.price__panel, { [styles.inactive]: keys.status !== tab.key })}
            style={keys.status === tab.key ? { background: background, color: '#FFFFFF' } : {}}
          >
            {tab.title}
          </div>
        ),
        key: tab.key,
        children: (
          <div>
            {keys.status === '1' && (
              <Manager
                data={data as any}
                onChangePagination={onChangePagination}
                propsUpload={props}
                loading={isLoading}
              />
            )}
            {keys.status === '2' && (
              <div>
                {listFormulaPrice && listFormulaPrice.data && listFormulaPrice.data.length > 0 ? (
                  <div className={clsx(styles.formula)}>
                    <Table
                      dataSource={listFormulaPrice?.data}
                      columns={columns}
                      rowKey="id"
                      pagination={false}
                      scroll={{ x: 900 }}
                    />
                    {listFormulaPrice?.pagination?.total > 10 ? (
                      <div className={styles.formula__pagination}>
                        <PaginationCommon
                          defaultCurrent={1}
                          total={listFormulaPrice?.pagination?.total}
                          pageSize={listFormulaPrice?.pagination?.per_page}
                          current={listFormulaPrice?.pagination?.current_page}
                          onChangePagination={onChangePagination}
                          pageSizeOptions={['10', '20', '50', '100']}
                          showSizeChanger
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <DataEmpty detail={priceManagerUI.noResultsMessageFormula} className={styles.empty__results}>
                    <ButtonCommon
                      style={{ gap: '8px' }}
                      type="primary"
                      icon={<IconWrap icon={<PlusIcon />} color="#fff" />}
                      onClick={() => navigate('/price-manager/create-formula')}
                    >
                      {priceManagerUI.addPriceRule}
                    </ButtonCommon>
                  </DataEmpty>
                )}
              </div>
            )}
          </div>
        ),
      }
    })
  }, [statusHeader])
  return (
    <div className={clsx(styles.price)}>
      <PageHeader
        title={keys.status === '1' ? homeUi.priceManager : homeUi.createPriceFormula}
        backgroundImage={PriceManager}
        height={'108px'}
      >
        <div className={styles.price__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={priceManagerUI.search}
            className={styles.price__header__search__input}
            onChange={onHandleSearch}
            value={textSearch}
          />
          <ButtonCommon
            icon={<IconWrap icon={<PlusIcon />} size={14} />}
            className={styles.price__addRule__btn}
            type="primary"
            onClick={() => {
              if (keys.status === '1') {
                setCurrentPriceItem(null)
                setVisible(true)
              } else {
                navigate('/price-manager/create-formula')
              }
            }}
          >
            {priceManagerUI.addPriceRule}
          </ButtonCommon>
          {keys.status === '1' && (
            <Upload {...props}>
              <ButtonCommon icon={<UploadOutlined />} className={styles.price__header__button} type="primary">
                {meterUi.importCSV}
              </ButtonCommon>
            </Upload>
          )}
        </div>
      </PageHeader>
      <div className={styles.price__tabs}>
        <Tabs
          id={'tab-1'}
          type="card"
          tabBarExtraContent={
            keys.status === '2' ? (
              <div className={styles.price__tabs__filter}>
                <div className={clsx(styles.group__filter, 'select__group')}>
                  <Select
                    className={styles.price__tabs__filter__select}
                    allowClear
                    optionLabelProp="label"
                    placeholder={
                      <>
                        <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={styles.icon__filter} />
                        {meterUi.type}
                      </>
                    }
                    onChange={handleChangeSelectType}
                  >
                    {meterTypeFormula.map((item: { label: string; value: number }) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        className="select__option"
                        label={
                          <React.Fragment>
                            <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter)} />
                            {item.label}
                          </React.Fragment>
                        }
                      >
                        <span className={styles.text}>{item.label}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : (
              <></>
            )
          }
          items={items}
          activeKey={keys.status}
          onChange={(key: string) => {
            setKeys({ ...keys, status: key })
            setParams({
              page: 1,
              page_size: 10,
              search: '',
              meter_type: null,
            })
            setTextSearch('')
          }}
        />
      </div>
      <Loading
        isLoading={isLoadingUpload || isLoading || isFetching || loadingListFormulaPrice || fetchingListFormulaPrice}
      />

      {visible && (
        <ManagerModal
          title={priceManagerUI.edit}
          visible={true}
          onOk={() => {
            setVisible(false)
          }}
          onCancel={() => {
            setVisible(false)
          }}
          price={currentPriceItem!}
          isLoading={false}
        />
      )}
    </div>
  )
}

export default Price
