import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PriceManagerApi } from '../../../apis/price'
import { IFormula, IResFormula, IResPriceManager } from '../../../models'

export const PriceServerStateKeys = {
  PricesManager: 'priceManager/getAll',
  PricesFormula: 'priceFormula',
}

export const useGetPricesManager = (data?: any) =>
  useQuery<IResPriceManager, Error>(
    [PriceServerStateKeys.PricesManager, data.params],
    () => PriceManagerApi.getListPrices(data.params),
    { enabled: data.key !== '2' },
  )

export const useCreatePriceManager = () => {
  const cache = useQueryClient()
  return useMutation(PriceManagerApi.createPriceManager, {
    onSuccess: () => {
      cache.invalidateQueries(PriceServerStateKeys.PricesManager)
    },
  })
}

export const useImportPriceManager = () => {
  const cache = useQueryClient()
  return useMutation(PriceManagerApi.importCSV, {
    onSuccess: () => {
      cache.invalidateQueries(PriceServerStateKeys.PricesManager)
    },
  })
}

export const useGetTariffCodeList = (params: { type: number }) =>
  useQuery<any, Error>([PriceServerStateKeys.PricesFormula, params], () => PriceManagerApi.getListTariffCode(params))

export const checkTariffCodeInDB = (params: { tariff_code: string; meter_type: number; check: boolean }) =>
  useQuery<any, Error>(
    [PriceServerStateKeys.PricesFormula, params],
    () => PriceManagerApi.checkTariffCode({ tariff_code: params.tariff_code, meter_type: params.meter_type }),
    {
      enabled: params?.check,
    },
  )

export const useGetListFormulaPrice = (data: {
  params: { page: number; page_size: number; search: string; meter_type: number } | any
  key: string
}) => {
  return useQuery<IResFormula, Error>(
    [PriceServerStateKeys.PricesFormula, data.params],
    () => PriceManagerApi.getListFormulaPrice(data.params),
    { enabled: data.key !== '1' },
  )
}

export const useGetDetailFormulaPrice = (params?: any) =>
  useQuery<IFormula, Error>(
    [PriceServerStateKeys.PricesFormula, params],
    () => PriceManagerApi.getDetailFormulaPrice(params),
    { enabled: !!params.id, onSuccess: (data) => data, onError: (error) => error },
  )
