import clsx from 'clsx'
import styles from './index.module.scss'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonCommon } from '../../../components/Button'
import { InputCommon } from '../../../components/Input'
import { LockIcon } from '../../../assets/icons'
import { Form } from 'antd'
import { CommonUi, forgotPasswordUi } from '../../../until/text/ui'
import { openNotificationWithIcon, useForgotPassword } from '../../../hooks'
import { authMessage } from '../../../until/text/messages'
import { regex } from '../../../until'

export const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const mutationForgotPassword = useForgotPassword()

  const onFinish = async (value: any) => {
    setLoading(true)
    try {
      // const res = await mutationForgotPassword.mutateAsync({ email: email })
      const res = await mutationForgotPassword.mutateAsync(value)
      openNotificationWithIcon('success', '', res.data)
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)

      setLoading(false)
    }
    setLoading(false)
  }
  return (
    <div className={styles.forgot}>
      <div className={clsx(styles.forgot__title)}>
        <h1 style={{ textAlign: 'center', color: 'white' }}>{CommonUi.logo}</h1>
      </div>
      <div className={clsx(styles.forgot__page)}>
        <div className={clsx(styles.password__page)}>
          <div style={{ textAlign: 'center', marginBottom: '16px' }}>
            <LockIcon />
          </div>
          <h1>{forgotPasswordUi.forgotPasswordTitle}</h1>
          <Form className={clsx(styles.password__form)} onFinish={onFinish} layout="vertical">
            <Form.Item
              className={clsx(styles.password__form__item)}
              label={forgotPasswordUi.email}
              name="email"
              rules={[
                {
                  required: true,
                  message: `${authMessage.requiredEmail}`,
                },
                {
                  pattern: regex.validateEmail,
                  message: `${authMessage.validateEmail}`,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputCommon placeholder={forgotPasswordUi.placeHolder} />
            </Form.Item>
            <div className={clsx(styles.form__btnGroup)}>
              <ButtonCommon
                className={clsx(styles.btn__reset)}
                loading={loading}
                style={{ letterSpacing: '-2px' }}
                htmlType="submit"
              >
                {forgotPasswordUi.resetPassword}
              </ButtonCommon>
              <ButtonCommon type="ghost" className={clsx(styles.btn__back__login)} style={{ letterSpacing: '-2px' }}>
                <Link to={'/login'}>{forgotPasswordUi.backToLogin}</Link>
              </ButtonCommon>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
