import { Col, Form, Row, Select } from 'antd'
import { UploadFile } from 'antd/es/upload'
import { useEffect, useState } from 'react'
import { envApiUrl } from '../../../apis/axiosClient'
import { ButtonCommon, InputCommon, ModalCommon, RequireTag, SelectCommon } from '../../../components'
import { UploadImage } from '../../../components/UploadImage'
import { openNotificationWithIcon, useGetSuggestionsBuilding, useUpdateStaff } from '../../../hooks'
import { IBuilding, IStaff } from '../../../models'
import { regex } from '../../../until'
import { managerMessage, staffMessage } from '../../../until/text/messages'
import { staffUi } from '../../../until/text/ui'
import styles from './index.module.scss'
import { SelectTag } from '../../../components/Select/SelectTag'

interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  staff?: Partial<IStaff>
  // eslint-disable-next-line no-unused-vars
  handleOnFinish?: (value: any) => void
  isLoading?: boolean
  listErr?: any
  resetCheck?: () => void
}

interface IRole {
  role: string
  name: string
}

export const StaffModal = ({
  title,
  visible,
  onOk,
  onCancel,
  staff,
  handleOnFinish,
  isLoading,
  listErr,
  resetCheck,
}: IProps) => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState<UploadFile | string>()
  const [roleAssignBuilding, setRoleAssignBuilding] = useState(staff?.user_type || '2')
  const [buildingIds, setBuildingIds] = useState(staff?.buildings?.map((item: IBuilding) => item.id) || [])
  const { data: dataBuilding } = useGetSuggestionsBuilding()
  const mutateUpdateStaff = useUpdateStaff()
  const onFinish = async (value: any) => {
    const body = {
      ...value,
      use_two_step_authentication: value.use_two_step_authentication === 1
    }
    //update form
    if (staff) {
      // setLoadingUpdate(true)
      try {
        const formData = new FormData()
        if (body.building_ids && body.building_ids.length > 0) {
          for (let i = 0; i < body.building_ids.length; i++) {
            formData.append('building_ids[]', body.building_ids[i])
          }
        } else {
          formData.append('building_ids[]', '')
        }
        delete body.building_ids
        for (const key in body) {
          formData.append(key, body[key])
        }
        formData.append('avatar', avatar as any)
        await mutateUpdateStaff.mutateAsync({ id: staff.id, formData: formData })
        resetCheck?.()
        openNotificationWithIcon('success', '', staffMessage.updateSuccess)

        onOk()
      } catch (error: any) {
        if (error.errors) {
          const entries = Object.entries(error.errors)

          const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
          form.setFields(arrError)
        } else {
          openNotificationWithIcon('error', '', error?.message)
        }
      }
    } else {
      if (avatar) {
        handleOnFinish?.({ ...value, avatar: avatar })
      } else {
        handleOnFinish?.({ ...value })
      }
    }
  }

  useEffect(() => {
    if (listErr && listErr.length > 0) {
      form.setFields(listErr)
    }
  }, [listErr])

  useEffect(() => {
    !staff &&
      form.setFieldsValue({
        billing_date: 25,
      })
    if (staff) {
      form.setFieldsValue({
        full_name: staff.full_name || '',
        full_name_katakana: staff.full_name_katakana || '',
        username: staff.username || '',
        user_type: staff.user_type || '',
        number_phone: staff.number_phone || '',
        email: staff.email || '',
        address_1: staff.address_1 || '',
        address_2: staff.address_2 || '',
        status: staff.status || '',
        postal_code: staff.postal_code || '',
        billing_date: staff.billing_date === null ? 25 : staff.billing_date,
        building_ids: staff?.buildings?.map((item: IBuilding) => item.id),
        use_two_step_authentication: staff?.use_two_step_authentication ? 1 : 0,
      })
    }
  }, [])

  const roleCreate: any = {
    1: [
      {
        role: '2',
        name: '管理者',
      },
      {
        role: '3',
        name: '作業者',
      },
    ],
    2: [
      {
        role: '3',
        name: '作業者',
      },
    ],
  }

  const userType = localStorage.getItem('user_type')

  useEffect(() => {
    if (userType === '2') {
      setRoleAssignBuilding('3')
    }
  }, [userType])

  const handleChangeRole = (value: number) => {
    setRoleAssignBuilding(value.toString())
  }

  const handleChangeBuilding = (value: number[]) => {
    setBuildingIds(value)
  }

  return (
    <ModalCommon
      destroyOnClose={true}
      title={staff ? staffUi.titleDefault : title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="850px"
      className='modalUser'
    >
      <Form size='small' form={form} layout="vertical" onFinish={onFinish}>
        <div className={styles.form__container}>
          <div className={styles.form__top}>
            <div className={styles.form__uploadimage}>
              <UploadImage
                title={staffUi.profileImage}
                hasBorder={true}
                url={staff?.url_avatar ? `${envApiUrl}${staff?.url_avatar}` : ''}
                onChangeAvatar={setAvatar}
              />
            </div>
            <div className={styles.form__name}>
              <Form.Item
                label={
                  <>
                    {staffUi.fullName}
                    <RequireTag />
                  </>
                }
                name="full_name"
                className={styles['form__item--half']}
                initialValue={''}
                rules={[
                  {
                    required: true,
                    message: staffMessage.inputFullName,
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <InputCommon
                  maxLength={50}
                  placeholder={staffUi.placeholderName}
                  onBlur={() => {
                    form.setFieldValue('full_name', form.getFieldValue('full_name')?.trim())
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    {staffUi.userName}
                    <RequireTag />
                  </>
                }
                name="username"
                className={styles['form__item--full']}
                initialValue={''}
                rules={[
                  {
                    required: true,
                    message: staffMessage.inputUserName,
                  },
                  {
                    validator(_, value) {
                      if (value.indexOf(' ') > 0) {
                        return Promise.reject(`${staffMessage.whiteSpace}`)
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <InputCommon
                  maxLength={50}
                  placeholder={staffUi.placeholderUserName}
                  onBlur={() => {
                    form.setFieldValue('username', form.getFieldValue('username')?.trim())
                  }}
                />
              </Form.Item>
            </div>
            <div className={styles.form__name}>
              <Form.Item
                label={<>{staffUi.fullNameKatakana}</>}
                name="full_name_katakana"
                className={styles['form__item--half']}
                initialValue={''}
              >
                <InputCommon
                  maxLength={50}
                  placeholder={staffUi.placeholderNameKatakana}
                  onBlur={() => {
                    form.setFieldValue('full_name_katakana', form.getFieldValue('full_name_katakana')?.trim())
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    {staffUi.userType}
                    <RequireTag />
                  </>
                }
                name="user_type"
                initialValue={userType === '1' ? null : '3'}
                className={styles['form__item--half']}
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: staffMessage.inputUserType,
                  },
                ]}
              >
                <SelectCommon
                  onChange={handleChangeRole}
                  maxTagCount="responsive"
                  disabled={userType === '2' || buildingIds.length > 0}
                  placeholder={staffUi.placeholderUserType}
                >
                  {userType
                    ? roleCreate[userType].map((item: IRole) => (
                      <Select.Option key={item.role} value={item.role}>
                        {item.name}
                      </Select.Option>
                    ))
                    : null}
                </SelectCommon>
              </Form.Item>
            </div>
          </div>
          <div className={styles.form__bottom}>
            <Form.Item
              label={<>{staffUi.phone}</>}
              name="number_phone"
              className={styles['form__item--half']}
              initialValue={''}
              rules={[
                {
                  pattern: regex.validatePhone,
                  message: staffMessage.invalidPhone,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputCommon
                maxLength={11}
                placeholder={staffUi.placeholderPhone}
                onBlur={() => {
                  form.setFieldValue('number_phone', form.getFieldValue('number_phone')?.trim())
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  {staffUi.email}
                  {staff ? '' : <RequireTag />}
                </>
              }
              name="email"
              className={styles['form__item--half']}
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: staffMessage.inputEmail,
                },
                {
                  pattern: regex.validateEmail,
                  message: staffMessage.invalidEmail,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputCommon
                maxLength={100}
                disabled={!!staff}
                placeholder={staffUi.placeholderEmail}
                onBlur={() => {
                  form.setFieldValue('email', form.getFieldValue('email')?.trim())
                }}
              />
            </Form.Item>
            <Form.Item
              label={<>{staffUi.address1}</>}
              name="address_1"
              className={styles['form__item--full']}
              initialValue={''}
            >
              <InputCommon
                maxLength={50}
                placeholder={staffUi.placeholderAddress}
                onBlur={() => {
                  form.setFieldValue('address_1', form.getFieldValue('address_1')?.trim())
                }}
              />
            </Form.Item>
            <Form.Item
              label={staffUi.address2}
              name="address_2"
              className={styles['form__item--full']}
              initialValue={''}
            >
              <InputCommon
                maxLength={50}
                placeholder={staffUi.placeholderAddress}
                onBlur={() => {
                  form.setFieldValue('address_2', form.getFieldValue('address_2')?.trim())
                }}
              />
            </Form.Item>
            <Form.Item
              label={<>{staffUi.postalCode}</>}
              name="postal_code"
              className={styles['form__item--half']}
              initialValue={''}
              rules={[
                {
                  pattern: regex.validatePostalCode,
                  message: staffMessage.inputPostalCode,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputCommon
                maxLength={8}
                placeholder={staffUi.placeholderPostalCode}
                onBlur={() => {
                  form.setFieldValue('postal_code', form.getFieldValue('postal_code')?.trim())
                }}
              />
            </Form.Item>
            {roleAssignBuilding?.toString() === '2' ? (
              ''
            ) : (
              <>
                <Form.Item
                  label={
                    <>
                      {staffUi.billingDate} <RequireTag />
                    </>
                  }
                  name="billing_date"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: staffMessage.inputDateClose,
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <SelectCommon autoFocus={false} placeholder={staffUi.placeHolderBillingDate}>
                    {Array.from(Array(31)).map((val, index) => (
                      <Select.Option key={index} value={index + 1}>
                        {index + 1}
                      </Select.Option>
                    ))}
                  </SelectCommon>
                </Form.Item>
              </>
            )}

            <Form.Item
              label={
                <>
                  {staffUi.status}
                  {staff ? '' : <RequireTag />}
                </>
              }
              name="status"
              className={styles['form__item--half']}
              rules={[{ required: true, message: staffMessage.inputStatus }]}
              validateTrigger="onSubmit"
            >
              <SelectCommon autoFocus={false} placeholder={staffUi.placeholderStatus}>
                <Select.Option value="1">{staffUi.active}</Select.Option>
                <Select.Option value="0">{staffUi.inactive}</Select.Option>
              </SelectCommon>
            </Form.Item>
            {roleAssignBuilding?.toString() === '2' ? (
              ''
            ) : (
              <>
                <Form.Item
                  label={<>{staffUi.assignBuilding}</>}
                  name="building_ids"
                  className={styles['form__item--half']}
                >
                  <SelectCommon
                    disabled={roleAssignBuilding?.toString() === '2'}
                    mode="multiple"
                    autoFocus={false}
                    showArrow
                    maxTagCount="responsive"
                    optionFilterProp="children"
                    placeholder={staffUi.placeholderAssignBuilding}
                    onChange={handleChangeBuilding}
                    tagRender={SelectTag}
                  >
                    {dataBuilding?.data &&
                      dataBuilding.data.map((item: IBuilding) => (
                        <Select.Option key={item.id} value={item.id}>
                          {`${item.name}`}
                        </Select.Option>
                      ))}
                  </SelectCommon>
                </Form.Item>
              </>
            )}

            <Row gutter={[40, 40]} className={styles['form__item--full']} style={{ margin: 0 }}>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {staffUi.twoFactorAuthentication}
                      {!staff && <RequireTag />}
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: staffUi.twoFactorAuthentication + managerMessage.isRequired,
                    },
                  ]}
                  name="use_two_step_authentication"
                  className={styles['form__item--half']}
                  validateTrigger="onSubmit"
                  style={{ marginBottom: 0 }}
                >
                  <SelectCommon
                    autoFocus={false}
                    maxTagCount="responsive"
                    placeholder={staffUi.twoFactorAuthenticationPlaceholder}
                  >
                    <Select.Option value={1}>{staffUi.active}</Select.Option>
                    <Select.Option value={0}>{staffUi.inactive}</Select.Option>
                  </SelectCommon>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {staffUi.cancel}
          </ButtonCommon>
          <ButtonCommon
            type="primary"
            htmlType="submit"
            loading={isLoading || mutateUpdateStaff.isLoading}
            style={{ letterSpacing: '-2px' }}
          >
            {staffUi.save}
          </ButtonCommon>
        </div>
      </Form>
    </ModalCommon>
  )
}
