import { FC } from 'react'
import { Avatar, Dropdown, Menu } from 'antd'
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { useGetProfile, useLogout } from '../../../hooks/query/user'
import { profileUi } from '../../../until/text/ui'
import { envApiUrl } from '../../../apis/axiosClient'
import { openNotificationWithIcon } from '../../../hooks'
import { Notifications } from '../../../components'
interface HeaderLayoutProps {
  size?: number
}

const User: FC<HeaderLayoutProps> = ({ size = 24 }) => {
  const navigate = useNavigate()
  const role = localStorage.getItem('role') || ''
  const handleViewProfile = () => {
    if (role === '9') {
      navigate('/admin-profile')
    } else {
      navigate('/profile')
    }
  }
  const mutateLogout = useLogout()
  const handleLogOut = async () => {
    try {
      await mutateLogout.mutateAsync()
      localStorage.clear()
      navigate('/login')
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }
  const { data } = useGetProfile()
  const menu = (
    <Menu
      items={[
        { label: <a onClick={handleViewProfile}>{profileUi.profile}</a>, key: 0 },
        { label: <a onClick={handleLogOut}>{profileUi.logout}</a>, key: 1 },
      ]}
    />
  )

  return (
    <div className={clsx(styles.header__user)}>
      {role !== '9' && (
        <div>
          <Notifications />
        </div>
      )}
      <div>
        <Avatar
          size={size}
          src={data?.url_avatar ? `${envApiUrl}${data?.url_avatar}` : null}
          icon={data?.avatar ? null : <UserOutlined />}
        />
      </div>
      <div className={clsx(styles.header__user__dropdown)} onClick={(e) => e.preventDefault()}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={clsx(styles.header__user__name)}>
            <span>{!data?.username ? data?.full_name : data?.username}</span>
            <CaretDownOutlined className={styles.header__user__dropdown__icon} />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default User
