import { EXAMPLE_RESPONSE } from '../../until/constant'
import { summaryMessage } from '../../until/text/messages'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'
class Summary {
  getAllSummary = async (params?: unknown) => {
    const url = `/${EndPoint.summary}`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  downloadCsvSummary = async (params?: unknown) => {
    const url = `/${EndPoint.summary}/csv`
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  downloadImg = async (params?: unknown) => {
    const url = `/${EndPoint.summary}/image`
    return await axiosClient
      .get(url, {
        params,
        responseType: 'blob',
      })
      .then((result) => result.data)
      .catch(() => {
        return {
          message: summaryMessage.exportFailed,
        }
      })
  }

  getSuggestionsSummary = async () => {
    const url = `/${EndPoint.summary}/suggestions`
    const res = await axiosClient.get(url)
    return res.data?.data || EXAMPLE_RESPONSE
  }
}
export const SummaryApi = new Summary()
