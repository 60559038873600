import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Extraordinary {
  createExtraordinary = async (data: any) => {
    const url = `/${EndPoint.extraordinary}`
    const res = await axiosClient.post(url, data)
    return res.data
  }
  getAllExtraordinary = async (params?: any) => {
    const url = `/${EndPoint.extraordinary}`
    const res = await axiosClient.get(url, { params })
    return res.data.data
  }
  getAllMeasurementExtraordinary = async (params?: any) => {
    const url = `/${EndPoint.extraordinary}/alert-list`
    const res = await axiosClient.get(url, { params })
    return res.data.data
  }
  updateExtraordinary = async (id: number, data: any) => {
    const url = `/${EndPoint.extraordinary}/${id}`
    const res = await axiosClient.put(url, data)
    return res.data.data
  }
  deleteExtraordinary = async (id: number) => {
    const url = `/${EndPoint.extraordinary}/${id}`
    const res = await axiosClient.delete(url)
    return res.data
  }
  downloadMeasurementExtraordinary = async () => {
    const url = `/${EndPoint.extraordinary}/alert-list/download`
    const res = await axiosClient.get(url)
    return res.data
  }
}

export const ExtraordinaryApi = new Extraordinary()
