import { Button } from 'antd'
import { ButtonProps } from 'antd'
import clsx from 'clsx'
import styles from './index.module.scss'

export const ButtonCommon = ({ ...props }: ButtonProps) => {
  return (
    <div className={clsx(styles.button__container, { [styles.transparent]: props.type === 'ghost' })}>
      <Button {...props} />
    </div>
  )
}
