import './App.less'
import MainLayout from './layout/Main'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  Staff,
  Home,
  Building,
  Meter,
  Summary,
  ForgotPasswordForm,
  LoginForm,
  Profile,
  MeterTypeManagement,
  LogHistory,
  ExtraordinaryManager,
  Traffic,
  Setting,
} from './pages'
import AuthPage from './layout/AuthPage'
import { ProtectedRoutes } from './routes/protectedRoute'
import { NotFoundPage } from './pages/NotFound'
import { useEffect } from 'react'
import { useStore } from './store'
import { Manager } from './pages/Manager'
import { ProfileManager } from './pages/Manager/ProfileManager'
import { PrivateRoute } from './routes/privateRoute'
import Price from './pages/PriceManager'
import Invoice from './pages/Invoice'
import { Contractors } from './pages/Contractors'
import { Terminal } from './pages/Terminal'
import CreateFormula from './pages/PriceManager/CreateFormula'
import { OperationLog } from './pages/OperationLog'

function App() {
  const [state] = useStore()
  const { isAuthenticated } = state
  useEffect(() => {
    // const token = localStorage.getItem('access_token')
    // if (token) {
    //   setIsAuthenticated(true)
    // }
  }, [isAuthenticated])

  const role = localStorage.getItem('role') || ''
  const userType = localStorage.getItem('user_type') || ''
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthPage />}>
          <Route path="/login" element={<LoginForm />}></Route>
          <Route path="/forgot-password" element={<ForgotPasswordForm />}></Route>
        </Route>

        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
          <Route element={<MainLayout header={true} />}>
            <Route path="/" element={<PrivateRoute roles={['9']} currentRole={role} />}>
              <Route path="/admin" element={<Manager />} />
              <Route path="/admin-profile" element={<ProfileManager />} />
              <Route path="/meter-type" element={<MeterTypeManagement />} />
            </Route>
            <Route path="/" element={<PrivateRoute roles={['1', '2']} currentRole={role} />}>
              <Route index element={<Home />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/building" element={<Building />} />
              <Route path="/meter" element={<Meter />} />
              <Route path="/operation-log" element={<OperationLog />} />
              <Route path="/summary" element={<Summary />} />
              {userType === '1' && <Route path="/history" element={<LogHistory />} />}
              <Route path="/profile" element={<Profile />} />

              <Route path="/price-manager" element={<Price />} />
              <Route path="/price-manager/create-formula" element={<CreateFormula />} />
              <Route path="/price-manager/edit-formula/:id" element={<CreateFormula />} />
              <Route path="/invoice-manager" element={<Invoice />} />
              <Route path="/traffic-manager" element={<Traffic />} />
              <Route path="/terminal-manager" element={<Terminal />} />
              <Route path="/extraordinary-manager" element={<ExtraordinaryManager />} />
              <Route path="/contractor" element={<Contractors />} />
              <Route path="/setting" element={<Setting />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
