export const SettingV2Icon = ({ fill = 'white' }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9149_56462)">
        <path d="M9.97589 8.03808C9.97589 9.09815 9.11653 9.95751 8.05646 9.95751C6.99639 9.95751 6.13704 9.09815 6.13704 8.03808C6.13704 6.97801 6.99639 6.11865 8.05646 6.11865C9.11653 6.11865 9.97589 6.97801 9.97589 8.03808Z" stroke={fill} strokeWidth="1.5" />
        <path d="M8.65308 2C9.19552 2 9.6998 2.27907 9.98789 2.73868L10.4995 3.55495C10.7128 3.89517 11.0479 4.14112 11.4364 4.24255L12.4365 4.50363C12.9561 4.63929 13.3709 5.03032 13.5368 5.54108L13.9301 6.75139C14.1052 7.29023 13.9774 7.88159 13.5955 8.3001L12.9959 8.95716C12.6933 9.28881 12.5465 9.73384 12.5925 10.1805L12.6897 11.1237C12.7472 11.6824 12.5029 12.2295 12.0486 12.5596L11.0577 13.2795C10.5983 13.6133 9.99482 13.674 9.47816 13.4384L8.63432 13.0537C8.2149 12.8625 7.73276 12.8645 7.31499 13.0593L6.52544 13.4275C6.00644 13.6695 5.39699 13.6108 4.9337 13.2742L3.98016 12.5814C3.5098 12.2397 3.2659 11.6667 3.34569 11.0908L3.46418 10.2355C3.53041 9.75743 3.37387 9.27541 3.0394 8.9275L2.43972 8.30372C2.0369 7.8847 1.89753 7.27792 2.07714 6.72512L2.46428 5.53362C2.62891 5.02694 3.03847 4.6378 3.5529 4.49927L4.55191 4.23025C4.92889 4.12873 5.25475 3.89085 5.4663 3.56272L6.00851 2.72172C6.29857 2.27182 6.79724 2 7.33254 2H8.65308Z" stroke={fill} strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_9149_56462">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
