import React, { useState, useCallback } from 'react'
import { Select, Table, Upload, UploadProps, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import contractHEader from '../../assets/images/contract.png'
import clsx from 'clsx'
import styles from './index.module.scss'
import { UploadOutlined } from '@ant-design/icons'
import { SearchIcon, TypeIcon } from '../../assets/icons'
import { ModalContractorDetail } from './DetailContractor'
import { homeUi, meterUi, contractorsUi } from '../../until/text/ui'
import { DataEmpty } from '../../components/DataEmpty'
import { Loading } from '../../components/Loading'
import { openNotificationWithIcon, useGetContractorManager, useImportContractors } from '../../hooks'
import debounce from 'lodash.debounce'
import { PaginationCommon } from '../../components/Pagination'
import { RcFile } from 'antd/es/upload'
import { ContractorsMessage } from '../../until/text/messages'
import { IContractor } from '../../models'
import { FLGType, SIZE_5MB, typeMeter } from '../../until/constant'

export const ContractorTableKey = {
  id: 'id',
  managementAssociationNo: 'management_association_no',
  buildingNo: 'building_no',
  buildingName: 'building_name',
  roomNo: 'room_no',
  customerName: 'customer_name',
  waterCaliber: 'water_caliber',
  companyName: 'company_name',
  subjectCode: 'subject_code',
  FLG: 'first_move_in_flg'
}

export const Contractors = () => {
  const [visible, setVisible] = useState(false)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [currentContractorsSelected, setCurrentContractorsSelected] = useState<IContractor | null>(null)
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
    text_search: '',
  })

  const { data, isLoading, isFetching } = useGetContractorManager(params)
  const mutateImportCsv = useImportContractors()

  const columns: ColumnsType<any> = [
    {
      title: contractorsUi[ContractorTableKey.subjectCode],
      dataIndex: ContractorTableKey.subjectCode,
      key: ContractorTableKey.subjectCode,
      width: '10%',
    },
    {
      title: contractorsUi[ContractorTableKey.managementAssociationNo],
      dataIndex: ContractorTableKey.managementAssociationNo,
      key: ContractorTableKey.managementAssociationNo,
      width: '12%',
    },
    {
      title: contractorsUi[ContractorTableKey.buildingNo],
      dataIndex: ContractorTableKey.buildingNo,
      key: ContractorTableKey.buildingNo,
      width: '12%',
    },
    {
      title: contractorsUi[ContractorTableKey.buildingName],
      dataIndex: ContractorTableKey.buildingName,
      key: ContractorTableKey.buildingName,
      width: '14%',
      render: (text) => {
        if (text === null) {
          return ''
        }
        return (
          <div className={styles.text__name__container}>
            <Tooltip title={text} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{text}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: contractorsUi[ContractorTableKey.roomNo],
      dataIndex: ContractorTableKey.roomNo,
      key: ContractorTableKey.roomNo,
      width: '12%',
    },
    {
      title: contractorsUi[ContractorTableKey.customerName],
      dataIndex: ContractorTableKey.customerName,
      key: ContractorTableKey.customerName,
      width: '12%',
      render: (text) => {
        if (text === null) {
          return ''
        }
        return (
          <div className={styles.text__name__container}>
            <Tooltip title={text} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{text}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: contractorsUi[ContractorTableKey.waterCaliber],
      dataIndex: ContractorTableKey.waterCaliber,
      key: ContractorTableKey.waterCaliber,
      width: '8%',
    },
    {
      title: contractorsUi[ContractorTableKey.FLG],
      dataIndex: ContractorTableKey.FLG,
      key: ContractorTableKey.FLG,
      width: '14%',
      render: (value) => FLGType[value],
    },
    {
      title: '',
      dataIndex: 'viewDetail',
      key: 'viewDetail',
      width: '8%',
      render: (_, record: IContractor) => (
        <div className={styles.view_detail} onClick={() => showViewDetail(record)}>
          {contractorsUi.viewDetail}
        </div>
      ),
    },
  ]

  const showViewDetail = (contractor: IContractor) => {
    setCurrentContractorsSelected(contractor)
    setVisible(!visible)
  }

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, text_search: nextValue, page: 1 })), 500),
    [],
  )

  // eslint-disable-next-line no-unused-vars
  const handleChangeSelectType = (value: number) => {
    setParams({ ...params, page: 1, type: value })
  }

  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    accept: 'text/csv',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file: RcFile) {
      const isCSV = file.type === 'text/csv'
      const isLt5M = file.size < SIZE_5MB
      if (!isCSV) {
        openNotificationWithIcon('error', '', `${ContractorsMessage.invalidCsv}\n${ContractorsMessage.invalidCsv2}`)
        return isCSV
      }
      if (!isLt5M) {
        openNotificationWithIcon('error', '', ContractorsMessage.csv5M)
        return isLt5M
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotificationWithIcon('success', '', `${info.file.name} ${ContractorsMessage.upLoadSuccess}`)
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', '', `${info.file.name} ${ContractorsMessage.upLoadFail}`)
      }
    },
    customRequest: async (options: any) => {
      const { file } = options
      const fmData = new FormData()
      fmData.append('file', file)
      try {
        setIsLoadingUpload(true)
        const data = await mutateImportCsv.mutateAsync(fmData)
        openNotificationWithIcon('success', '', data.data)
      } catch (err: any) {
        openNotificationWithIcon('error', '', err.message)
      }
      setIsLoadingUpload(false)
    },
  }

  return (
    <div className={clsx(styles.contract_person)}>
      <PageHeader title={homeUi.contractor} backgroundImage={contractHEader} height={'108px'}>
        <div className={styles.contract_person__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={contractorsUi.search}
            className={styles.contract_person__header__search__input}
            onChange={onHandleSearch}
          />
          <Upload {...props}>
            <ButtonCommon icon={<UploadOutlined />} className={styles.contract_person__header__button} type="primary">
              {contractorsUi.importCSV}
            </ButtonCommon>
          </Upload>
        </div>
      </PageHeader>
      <Loading isLoading={isLoadingUpload || isLoading || isFetching} />
      <div className={clsx(styles.page__content__filter)}>
        <div
          className={clsx(
            styles.group__filter,
            {
              [styles.hidden]: data?.data.length === 0 || isLoading || isFetching,
            },
            'select__group',
          )}
        >
          <Select
            style={{ minWidth: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter, styles.stafficon)} />
                {meterUi.type}
              </>
            }
            onChange={handleChangeSelectType}
            value={params.type}
          >
            {typeMeter.map((item: { name: string; value: number }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      {!isLoading && (
        <div className={clsx(styles.page__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={data?.data}
                pagination={false}
                scroll={{ x: 700 }}
              />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty detail={contractorsUi.noResultsMessage} className={styles.empty__results}>
              <Upload {...props}>
                <ButtonCommon icon={<UploadOutlined />} type="primary">
                  {contractorsUi.importCSV}
                </ButtonCommon>
              </Upload>
            </DataEmpty>
          )}
        </div>
      )}
      <ModalContractorDetail
        info={currentContractorsSelected!}
        title={contractorsUi.detail}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      />
    </div>
  )
}
