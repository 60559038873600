/* eslint-disable react/no-unescaped-entities */
import clsx from 'clsx'
import React from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ButtonCommon } from '../../components'
import PageError from '../../assets/images/404.png'
import { CommonUi } from '../../until/text/ui'
export const NotFoundPage = () => {
  const navigate = useNavigate()
  const role = localStorage.getItem('role') || ''
  const handleNavigate = () => {
    role === '9' ? navigate('/admin') : navigate('/')
  }
  return (
    <div className={clsx(styles.page__content)}>
      <div className={clsx(styles.wrap__page)}>
        <div className={clsx(styles.wrap__page__content)}>
          <div className={clsx(styles.image)}>
            <img src={PageError} alt="notfound" />
          </div>
          <p className={clsx(styles.description)}>{CommonUi.descriptionNotFound}</p>
          <div>
            <ButtonCommon onClick={handleNavigate} className={clsx(styles.back__to__home)}>
              {CommonUi.btnNotFound}
            </ButtonCommon>
          </div>
        </div>
      </div>
    </div>
  )
}
