import { Checkbox, Col, DatePickerProps, Row, Select, Tooltip, } from 'antd'
import clsx from 'clsx'
import { PrintIcon, Invoice } from '../../assets/icons'
import { ButtonCommon, DatePickerCommon, IconWrap } from '../../components'
import { PageHeader } from '../../components/PageHeader'
import styles from './index.module.scss'
import InvoiceManager from '../../assets/images/InvoiceManager.png'
import Table, { ColumnsType } from 'antd/lib/table'
import { IInvoice } from '../../models'
import { ManagerModal } from './Modal'
import { useRef, useState } from 'react'
import React from 'react'
import { PaginationCommon } from '../../components/Pagination'
import { homeUi, invoiceUi } from '../../until/text/ui'
import { DataEmpty } from '../../components/DataEmpty'
import { useReactToPrint } from 'react-to-print'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Loading } from '../../components/Loading'
import moment from 'moment'
import { useGetInvoices, usePrintInvoice } from '../../hooks'
import InvoidDetail from './InvoidDetail'
import { meterTypeKey, typeMeter, convertNumberToMoneyFormat } from '../../until'

const InvoicePage = () => {
  const [visible, setVisible] = useState(false)
  const componentRef = useRef(null)
  const [checkAll, setCheckAll] = useState(false)
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [currentInvoice, setCurrentInvoice] = useState<IInvoice | null>(null)
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string | number | null>(null)

  const [params, setParams] = useState<{
    page: number
    page_size: number
    date: string | null
    type: string | null
  }>({
    page: 1,
    page_size: 10,
    date: null,
    type: null,
  })
  
  const columns: ColumnsType<IInvoice> = [
    {
      title: `${invoiceUi.invoiceNo}`,
      dataIndex: 'id',
      key: 'invoice_no',
      width: '6%',
      render: (text: string, record: IInvoice) => {
        return (
          <a onClick={() => {
            setCurrentInvoice(record)
            setVisible(!visible)
            setCurrentInvoiceId(text)
          }}>{`#${text}`}</a>
        )
      }
    },
    {
      title: `${invoiceUi.buildingName}`,
      dataIndex: 'building_name',
      key: 'building_name',
      width: '18%',
      render: (_text: string, record: IInvoice) => {
        if (!record?.building_name) {
          return ''
        }

        return (
          <div className={styles.text__name__container} style={{ maxWidth: '224px'}}>
            <Tooltip title={record?.building_name} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{record?.building_name}</span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: `${invoiceUi.buildingNo}`,
      dataIndex: 'building_code',
      key: 'building_code',
      width: '5%',
      render: (_text: string, record: IInvoice) => {
        if (!record?.building_code) {
          return ''
        }

        return (
          <div className={styles.text__name__container} style={{ maxWidth: '80px' }}>
            <Tooltip title={record?.building_code} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{record?.building_code}</span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: `${invoiceUi.roomNo}`,
      dataIndex: 'roomNo',
      key: 'roomNo',
      width: '5%',
      render: (_text: string, record: IInvoice) => {
        if (!record?.room_no) {
          return ''
        }

        return (
          <div className={styles.text__name__container} style={{ maxWidth: '80px' }}>
            <Tooltip title={record?.room_no} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{record?.room_no}</span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: `${invoiceUi.type}`,
      dataIndex: 'meter_type',
      key: 'meter_type',
      width: '1%',
      render: (_text: string, record: IInvoice) =>  {
        return meterTypeKey[Number(record?.meter_type)] ?? ''
      }
    },
    {
      title: `${invoiceUi.date}`,
      dataIndex: 'date',
      key: 'date',
      width: '6%',
    },
    {
      title: `${invoiceUi.employee}`,
      dataIndex: 'employee',
      key: 'employee',
      width: '10%',
      render: (_text: string, record: IInvoice) => {
        if (!record?.worker) {
          return ''
        }

        return (
          <div className={styles.text__name__container} style={{ maxWidth: '100px' }}>
            <Tooltip title={record?.worker} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
              <span>{record?.worker}</span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: `${invoiceUi.previousScanDate}`,
      dataIndex: 'start_time',
      key: 'start_time',
      width: '6%',
    },
    {
      title: `${invoiceUi.quantity}`,
      dataIndex: 'quantity',
      key: 'quantity',
      width: '6%',
      render: (_text: string, record: IInvoice) => {
        return record?.total_use ??  ''
      }
    },
    {
      title: `${invoiceUi.total}`,
      dataIndex: 'total',
      key: 'total',
      width: '6%',
      render: (_text: string, record: IInvoice) => {
        return convertNumberToMoneyFormat(record.cost) ?? ''
      }
    },
    {
      title: `${invoiceUi.history}`,
      dataIndex: 'history',
      key: 'history',
      width: '6%',
      render: (_, record) => {
        return (
          <span className={clsx([
            {[styles.textDeleted]: record?.deleted_flag},
            {[styles.textModify]: record?.edit_flag && !record?.deleted_flag},
          ])}>
            {record?.deleted_flag ? invoiceUi.deleted : record?.edit_flag ? invoiceUi.modify : ''}
          </span>
        )
      },
    },
  ]

  const { data, isLoading, isFetching } = useGetInvoices(params)
  const { mutateAsync: mutatePrintAsync, isLoading: isPrintLoading } = usePrintInvoice()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page { size: A4 Portrait }
      @media print {
        body { 
          -webkit-print-color-adjust: exact; 
        }
      }
    `
  })

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const listInvoices = data?.data.map((item: any) => item)
    setCheckedList(e.target.checked && listInvoices?.length ? listInvoices : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const onCheckAllReset = () => {
    if (!checkAll) return
    setCheckedList([])
    setIndeterminate(false)
    setCheckAll(false)
  }

  const onChangePagination = (page: number, page_size: number) => {
    setCheckedList([])
    setIndeterminate(false)
    setCheckAll(false)
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const handleChangeSelectMeterType = (value: string) => {
    onCheckAllReset()
    setParams({
      ...params,
      page: 1,
      type: value,
    })
  }

  const handleConvertBillDetail = async () => {
    await mutatePrintAsync()
    handlePrint()
  }

  const handleChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    onCheckAllReset()
    if (!date) {
      setParams({ ...params, page: 1, date: null })
      return
    }
    setParams({ ...params, page: 1, date: dateString })
  }

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.id),
    columnWidth: '2%',
    onChange: (_selectedRowKeys: React.Key[], selectedRows: any[]) => {
      if (selectedRows.length > 0) {
        setIndeterminate(true)
      }
      if (selectedRows.length === data?.data.length) {
        setIndeterminate(false)
        setCheckAll(true)
      }
      if (selectedRows.length === 0) {
        setIndeterminate(false)
        setCheckAll(false)
      }
      setCheckedList(selectedRows)
    },
  }

  return (
    <div className={styles.page__container}>
      <PageHeader height='108px' title={homeUi.invoiceManager} backgroundImage={InvoiceManager} />
      <Loading isLoading={isLoading || isFetching} />
      <Row wrap={false} className={clsx(styles.page__content__filter)}>
        <Col>
          <Row
            wrap={false}
            align='middle'
            gutter={40}
            className='select__group'
          >
            <Col className={clsx(styles.page__content__filter__userType)}>
              <span style={{ fontWeight: 400, fontSize: '14px' }}>{invoiceUi.searchType}</span>
              <Select
                autoFocus={false}
                maxTagCount='responsive'
                allowClear
                optionLabelProp='label'
                style={{ minWidth: 156 }}
                placeholder={
                  <>
                    <IconWrap color='#8C8C8C' icon={<Invoice />} className={clsx(styles.icon__filter)} />
                    {invoiceUi.searchTypePlaceholder}
                  </>
                }
                onChange={handleChangeSelectMeterType}
              >
                {typeMeter.map((type: any) => (
                  <Select.Option
                    key={type.name}
                    value={type.value}
                    label={
                      <React.Fragment>
                        <IconWrap color='#8C8C8C' icon={<Invoice />} className={clsx(styles.icon__filter)} />
                        {type.name}
                      </React.Fragment>
                    }
                  >
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col className={clsx(styles.page__content__filter__userType)}>
              <span style={{ fontWeight: 400, fontSize: '14px' }}>{invoiceUi.date}</span>
              <DatePickerCommon
                onChange={handleChangeDate}
                placeholder={invoiceUi.dateTime}
                value={params.date ? moment(params?.date) : null}
                format='YYYY/MM/DD'
              />
            </Col>
          </Row>
        </Col>

        <Col className={clsx(styles.page__content__filter__printQR)}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={!data?.data || data?.data?.length === 0}
          >
            {invoiceUi.selectAll}
          </Checkbox>
          <ButtonCommon
            disabled={checkedList.length === 0}
            className={styles.printqrcode__btn}
            icon={<IconWrap icon={<PrintIcon />} size={18} />}
            type='ghost'
            onClick={handleConvertBillDetail}
            loading={isPrintLoading}
          >
            {invoiceUi.printInvoice}
          </ButtonCommon>
        </Col>
      </Row>

      {!isLoading && (
        <div className={clsx(styles.page__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowKey='id'
                columns={columns}
                dataSource={data.data}
                pagination={false}
                scroll={{ x: 700 }}
              />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    pageSizeOptions={['10', '20', '50', '100']}
                    showSizeChanger
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty className={styles.empty__results} ><></></DataEmpty>
          )}
        </div>
      )}

      {visible && currentInvoice && (
        <ManagerModal
          title={invoiceUi.invoiceDetail}
          visible={true}
          invoiceId={currentInvoiceId as string}
          onOk={() => {
            setVisible(false)
            setCurrentInvoice(null)
          }}
          onCancel={() => {
            setVisible(false)
            setCurrentInvoice(null)
          }}
          isLoading={false}
          invoice={currentInvoice}
        />
      )}
      <div className={styles.list__invoice}>
        <div ref={componentRef} >
          {checkedList &&
            checkedList.map((item: any, idx: number) => (
              <div key={idx} className={clsx(['pagebreak', styles.invoice__deatail])}>
                <InvoidDetail invoice={item} />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default InvoicePage
