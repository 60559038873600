import { InputNumber, InputNumberProps } from 'antd'
import clsx from 'clsx'
import styles from './index.module.scss'

export const InputNumberCommon = ({ ...props }: InputNumberProps) => {
  return (
    <div className={clsx(styles.input)}>
      <InputNumber className={clsx(styles.input)} min={0}  precision={0} {...props} />
    </div>
  )
}
