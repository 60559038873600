interface IProps {
  fill?: string
}

export const LogIcon = ({fill = '#8C8C8C'}: IProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33333 8.33333C3.33333 8.542 3.64067 8.90533 4.35333 9.262C5.276 9.72333 6.58467 10 8 10C9.41533 10 10.724 9.72333 11.6467 9.262C12.3593 8.90533 12.6667 8.542 12.6667 8.33333V6.886C11.5667 7.566 9.88467 8 8 8C6.11533 8 4.43333 7.56533 3.33333 6.886V8.33333ZM12.6667 10.2193C11.5667 10.8993 9.88467 11.3333 8 11.3333C6.11533 11.3333 4.43333 10.8987 3.33333 10.2193V11.6667C3.33333 11.8753 3.64067 12.2387 4.35333 12.5953C5.276 13.0567 6.58467 13.3333 8 13.3333C9.41533 13.3333 10.724 13.0567 11.6467 12.5953C12.3593 12.2387 12.6667 11.8753 12.6667 11.6667V10.2193ZM2 11.6667V5C2 3.34333 4.68667 2 8 2C11.3133 2 14 3.34333 14 5V11.6667C14 13.3233 11.3133 14.6667 8 14.6667C4.68667 14.6667 2 13.3233 2 11.6667ZM8 6.66667C9.41533 6.66667 10.724 6.39 11.6467 5.92867C12.3593 5.572 12.6667 5.20867 12.6667 5C12.6667 4.79133 12.3593 4.428 11.6467 4.07133C10.724 3.61 9.41533 3.33333 8 3.33333C6.58467 3.33333 5.276 3.61 4.35333 4.07133C3.64067 4.428 3.33333 4.79133 3.33333 5C3.33333 5.20867 3.64067 5.572 4.35333 5.92867C5.276 6.39 6.58467 6.66667 8 6.66667Z" fill={fill}/>
    </svg>
  )
}
