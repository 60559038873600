import { useMutation, useQuery, useQueryClient} from 'react-query'
import { InvoiceManagerApi } from '../../../apis'
import { IResInvoiceManager } from '../../../models'

export const InvoiceServerStateKeys = {
  Invoice: 'invoice/getAll',
  InvoicePrint: 'invoice/print'
}

export const useGetInvoices = (params?: any) =>
  useQuery<IResInvoiceManager, Error>([InvoiceServerStateKeys.Invoice, params], () => InvoiceManagerApi.getListInvoices(params))

export const usePrintInvoice = () => {
  const cache = useQueryClient()
  return useMutation(InvoiceManagerApi.print, {
    onSuccess: () => {
      cache.invalidateQueries(InvoiceServerStateKeys.InvoicePrint)
    },
  })
}

