import { Tag, Tooltip } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

export const SelectTag = (props: CustomTagProps) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tooltip title={label} placement="bottomLeft" overlayStyle={{ minWidth: 50 }}>
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className='ant-select-selection-item'
        style={{ alignItems: 'center' }}
      >
        <span
          style={{
            width: '90px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'inline-block'
          }}
        >
          {label}
        </span>
      </Tag>
    </Tooltip>
  )
}