
import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Building {
  importCSV = async (data: any) => {
    const url = `/${EndPoint.building}/imports`
    const res = await axiosClient.post(url, data)
    return res.data
  }
  getListbuilding = async (params?: any) => {
    const url = `/${EndPoint.building}/list`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }
  getListContract = async (params?: any) => {
    const url = `/${EndPoint.staff}/contracts`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }
}

export const BuildingApi = new Building()
