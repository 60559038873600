export const meterUi = {
  imagePrev: '交換後のメーター値',
  type: '種類',
  electric: '電気',
  gas: 'ガス',
  water: '水道',
  meterlist: 'メーター一覧',
  managementNo: '管理組合No',
  buildingNo: '棟No',
  roomNo: '部屋No',
  routeNumber: 'ルート番号',
  meterDigit: 'メータ桁数',
  decimalDigits: '小数点桁数',
  latestMeter: '最新メータ',
  importCSV: 'CSV取り込み',
  search: '検索',
  building: '建物',
  year: '年',
  month: '月',
  day: '日',
  staff: '作業者',
  meterID: 'メーターID',
  itemName: '項目名',
  printQR: 'QRコード印刷',
  createQR: 'QRコード発行',
  viewQR: 'QRコード',
  download: 'ダウンロード',
  meterHistory: '検針履歴',
  total: '利用量',
  image: '画像',
  showImage: '画像表示',
  showHistory: ' 検針履歴',
  noResultsMessage: '以下のボタンからメーターを追加してください。',
  noMeterTypeResultsMessage: '以下のボタンから種類を追加してください。',
  qrCode: 'QRコード',
  printQRCode: 'QRコード印刷',
  checkAll: '全て選択',
  prevValue: '前回指針値',
  currentValue: '今回指針値',
  titleImage: '画像',
  active: '有効',
  inactive: '無効',
  prevDateReset: 'メーター取替日',
  prevTotalUse: '交換後のメーター値',
  addMeterType: '種類追加',
  meterTypeTitle: 'メーター種類追加',
  subjectCode: '科目コード',
  meterTypePlaceholder: '種類を選択してください。',
  editMeterType: '種類編集',
  last_update: '最新検針日',
  refetchQRCode: 'QRコード再発行',
  refetch: '再発行',
  correction: '最新検針値修正',
  cancel: 'キャンセル',
  save: '確定',
  correctionHistory: '検針値修正履歴',
  updateOn: '更新日',
  changer: '更新者',
  beforeCorrection: '修正前',
  revised: '修正後',
  history: '履歴',
  deleted: '削除',
  modify: '修正',
  water_supply_basic_fee: '上水基本料',
  water_sewage_basic_fee: '下水基本料',
  basic_fee: '基本料金',
  another: '水道以外',
}
