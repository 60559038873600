export const priceMessage = {
  upLoadSuccess: 'ファイルをアップロードしました 。',
  upLoadFail: 'CSVファイルのインポートに失敗しました。',
  invalidCsv: 'ファイルの形式が正しくありません。',
  invalidCsv2: '※登録可能なファイル形式はCSVのみです。',
  csv5M: 'ファイルの最大サイズは、5MBです。',
  meterTypeSelect: '種類は必須です。',
  subjecCodeRequired: '科目コードは必須です。',
  createSuccess: '作成に成功しました。',
  updateSuccess: '更新に成功しました。',
  costPlaceholder: '上水金額と下水金額を入力してください。',
  inputTariffCode: '料金表コードは必須です。',
  inputQuantityLevel: '使用量は必須です。',
  inputinputQuantityLevelValid: '使用量の形式が正しくありません。',
  inputWaterSupplyCost: '上水金額は必須です。',
  inputWaterLossCost: '下水金額は必須です。',
  inputCost: '金額は必須です。',
  errorWaterAndSupplyEmpty: '計算式（上水）または計算式（下水）は必須です。',
  errorSewage: '計算式（上水）が正しくありません。',
  errorSupply: '計算式（下水）が正しくありません。',
  errorBasic: '計算式は必須です。',
  errorFormulaBasic: '計算式が正しくありません。',
  copySuccess: 'コピーに成功しました。',
}
