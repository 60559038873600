import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Meter {
  importCSV = async (data: any) => {
    const url = `/${EndPoint.meter}/imports`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  getListBuildingMeter = async (params: any) => {
    const url = `${EndPoint.meter}/list`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }

  uploadQrCode = async (data: any) => {
    const url = `/${EndPoint.meter}/uploadQrCode`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  refetchQrCodes = async () => {
    const url = `/${EndPoint.meter}/regenerate-qr-code`
    const res = await axiosClient.post(url)
    return res.data
  }

  getHistoryMeter = async (params: any) => {
    const url = `/${EndPoint.meter}/${params.id}/histories`
    delete params.id
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }

  downloadHistoryMeter = async (params: any) => {
    const url = `/${EndPoint.meter}/${params.id}/histories/download`
    delete params.id
    const res = await axiosClient.get(url, { params })
    return res.data
  }

  getImageMeter = async (url: string) => {
    const res = await axiosClient.get(url, { responseType: 'blob' })
    return res.data
  }

  postMeterType = async (params: any) => {
    const url = `/${EndPoint.meterType}`
    const res = await axiosClient.post(url, params )
    return res.data
  }

  getMeterType = async (params: any) => {
    const url = `/${EndPoint.meterType}/list`
    const res = await axiosClient.get(url, {params})
    return res.data?.data || EXAMPLE_RESPONSE
  }

  putMeterType = async (params: any) => {
    const url = `/${EndPoint.meterType}/${params.id}`
    delete params.id
    const res = await axiosClient.put(url, params)
    return res.data?.data || EXAMPLE_RESPONSE
  }

  putMeterLatest = async (params: { id?: number, value: number }) => {
    const url = `/${EndPoint.meter}/${params?.id}/change-lasted-consumption`
    delete params?.id
    const res = await axiosClient.put(url, params)
    return res.data?.data || EXAMPLE_RESPONSE
  }

  printQRCode = async () => {
    const url = `/${EndPoint.meter}/print-qr-code`
    const res = await axiosClient.get(url)
    return res.data
  }
}

export const MeterApi = new Meter()
