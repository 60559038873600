export const MeterIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12.3333C10.7614 12.3333 13 10.0948 13 7.33334C13 4.57192 10.7614 2.33334 8 2.33334C5.23858 2.33334 3 4.57192 3 7.33334C3 10.0948 5.23858 12.3333 8 12.3333ZM8 13.3333C11.3137 13.3333 14 10.6471 14 7.33334C14 4.01963 11.3137 1.33334 8 1.33334C4.68629 1.33334 2 4.01963 2 7.33334C2 10.6471 4.68629 13.3333 8 13.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91548 10.8405C4.18765 10.7939 4.44611 10.9767 4.49277 11.2489L4.99297 14.1667H11.007L11.5071 11.2489C11.5538 10.9767 11.8123 10.7939 12.0844 10.8405C12.3566 10.8872 12.5394 11.1456 12.4928 11.4178L11.8501 15.1667H4.14981L3.50715 11.4178C3.46049 11.1456 3.6433 10.8872 3.91548 10.8405Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33337 5.33334C4.33337 4.78106 4.78109 4.33334 5.33337 4.33334H10.6667C11.219 4.33334 11.6667 4.78106 11.6667 5.33334V6.00001C11.6667 6.55229 11.219 7.00001 10.6667 7.00001H5.33337C4.78109 7.00001 4.33337 6.55229 4.33337 6.00001V5.33334ZM5.33337 5.00001C5.14928 5.00001 5.00004 5.14925 5.00004 5.33334V6.00001C5.00004 6.1841 5.14928 6.33334 5.33337 6.33334H10.6667C10.8508 6.33334 11 6.1841 11 6.00001V5.33334C11 5.14925 10.8508 5.00001 10.6667 5.00001H5.33337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00004 5.16666C6.09209 5.16666 6.16671 5.24128 6.16671 5.33332V5.99999C6.16671 6.09204 6.09209 6.16666 6.00004 6.16666C5.90799 6.16666 5.83337 6.09204 5.83337 5.99999V5.33332C5.83337 5.24128 5.90799 5.16666 6.00004 5.16666ZM7.33337 5.16666C7.42542 5.16666 7.50004 5.24128 7.50004 5.33332V5.99999C7.50004 6.09204 7.42542 6.16666 7.33337 6.16666C7.24133 6.16666 7.16671 6.09204 7.16671 5.99999V5.33332C7.16671 5.24128 7.24133 5.16666 7.33337 5.16666ZM8.66671 5.16666C8.75875 5.16666 8.83337 5.24128 8.83337 5.33332V5.99999C8.83337 6.09204 8.75875 6.16666 8.66671 6.16666C8.57466 6.16666 8.50004 6.09204 8.50004 5.99999V5.33332C8.50004 5.24128 8.57466 5.16666 8.66671 5.16666ZM10 5.16666C10.0921 5.16666 10.1667 5.24128 10.1667 5.33332V5.99999C10.1667 6.09204 10.0921 6.16666 10 6.16666C9.90799 6.16666 9.83337 6.09204 9.83337 5.99999V5.33332C9.83337 5.24128 9.90799 5.16666 10 5.16666Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39081 7.75668C8.50365 7.78332 8.58337 7.88405 8.58337 7.99999V9.08333H9.00004C9.08668 9.08333 9.16715 9.12819 9.2127 9.20189C9.25825 9.2756 9.2624 9.36763 9.22365 9.44513L8.22365 11.4451C8.1718 11.5488 8.05545 11.6033 7.9426 11.5766C7.82976 11.55 7.75004 11.4493 7.75004 11.3333V10.25H7.33337C7.24673 10.25 7.16626 10.2051 7.12071 10.1314C7.07516 10.0577 7.07102 9.96569 7.10977 9.88819L8.10977 7.88819C8.16162 7.78449 8.27797 7.73004 8.39081 7.75668ZM7.73788 9.74999H8.00004C8.13811 9.74999 8.25004 9.86192 8.25004 9.99999V10.2743L8.59553 9.58333H8.33337C8.1953 9.58333 8.08337 9.4714 8.08337 9.33333V9.05901L7.73788 9.74999Z"
        fill="currentColor"
      />
    </svg>
  )
}
