export const operationUi = {
  operationLogList: '操作ログ',
  search: '検索',
  date: '操作日時',
  username: 'ユーザ名',
  category: '操作区分',
  operationContent: '操作内容',
  name: 'ログ名称',
  logContent: 'ログ内容',
  dateTime: 'yyyy/mm/dd',
  typeLogin : 'ログイン',
  typeRegister: '登録',
  typeUpdate: '更新',
  typeDelete: '削除',
  typeExport: 'エクスポート',
  typeDownload: 'ダウンロード',
  platformWeb: 'ウェブ',
  platformApp: 'アプリ',
  platform: '区分',
  version: 'バージョン',
  device: 'デバイス名',
  logout: 'ログアウト',
}
