import React, { useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { IHistory } from '../../models'
import { historyUI } from '../../until/text/ui'
import styles from './index.module.scss'
import clsx from 'clsx'
import { IconWrap, PageHeader } from '../../components'
import historyHeader from '../../assets/images/historyHeader.png'
import { Loading } from '../../components/Loading'
import { Select, Table } from 'antd'
import { CloudIcon } from '../../assets/icons'
import { DetailLog } from './DetailLog'
import { DataEmpty } from '../../components/DataEmpty'
import { useGetHistory } from '../../hooks/query/history'
import moment from 'moment'
import { PaginationCommon } from '../../components/Pagination'

export const categorySync: { name: string; value: string }[] = [
  { name: historyUI.objectMeter, value: '0' },
  { name: historyUI.objectBuilding, value: '1' },
  { name: historyUI.objectScan, value: '4' },
  { name: historyUI.price, value: '2' },
  { name: historyUI.contractor, value: '3' },
  { name: historyUI.objectFormula, value: '5' }
]
export const status: { name: string; value: string; color: string }[] = [
  { name: historyUI.statusError, value: '0', color: '#FF4D4F' },
  { name: historyUI.statusDone, value: '1', color: '#52C41A' },
  { name: historyUI.statusProcessing, value: '2', color: '#FAAD14' },
]
export const typeSync: { name: string; value: string }[] = [
  { name: 'MAMAからSNAPPYへ', value: '0' },
  { name: 'SNAPPYからMAMAへ', value: '1' },
]

export const LogHistory = () => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState<IHistory>()
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
    filter_by_sync_type: '',
    filter_by_sync_category: '',
    filter_by_status: '',
  })

  const { data, isLoading, isFetching } = useGetHistory(params)
  const columns: ColumnsType<IHistory> = [
    {
      title: `${historyUI.timeStart}`,
      dataIndex: 'time_start',
      key: 'time_start',
      width: '17%',
      render: (date) => moment(date).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: `${historyUI.timeEnd}`,
      dataIndex: 'time_end',
      key: 'time_end',
      width: '17%',
      render: (date) => (date ? moment(date).format('YYYY/MM/DD HH:mm') : ''),
    },
    {
      title: `${historyUI.direction}`,
      dataIndex: 'sync_type',
      key: 'sync_type',
      width: '17%',
      render: (direction) => typeSync.find((el) => el.value === direction)?.name,
    },
    {
      title: `${historyUI.object}`,
      dataIndex: 'sync_category',
      key: 'sync_category',
      width: '17%',
      render: (category, record) =>
        record?.sync_type === '1' ? categorySync[2].name : categorySync.find((el) => Number(el.value) === Number(category))?.name,
    },
    {
      title: `${historyUI.status}`,
      dataIndex: 'status',
      key: 'status',
      width: '17%',
      render: (syncStatus) => {
        const item = status.find((el) => el.value === syncStatus)
        return <span style={{ color: item?.color }}>{item?.name}</span>
      },
    },
    {
      title: `${historyUI.log}`,
      key: 'log',
      width: '15%',
      render: (_, record) => {
        return (
          <span
            style={{ color: '#1890FF', cursor: 'pointer' }}
            onClick={() => {
              setVisible(true)
              setSelected(record)
            }}
          >
            {historyUI.messageLog}
          </span>
        )
      },
    },
  ]

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const handleChangeSelectDirection = (value: string) => {
    setParams({
      ...params,
      page: 1,
      filter_by_sync_type: value,
    })
  }
  const handleChangeCategory = (value: string) => {
    setParams({
      ...params,
      page: 1,
      filter_by_sync_category: value,
    })
  }
  const handleChangeStatus = (value: string) => {
    setParams({
      ...params,
      page: 1,
      filter_by_status: value,
    })
  }

  return (
    <div className={clsx(styles.history)}>
      <PageHeader title={historyUI.title} backgroundImage={historyHeader} height="108px" />
      <Loading isLoading={isLoading || isFetching} />
      <div className={clsx(styles.page__content__filter)}>
        <div
          className={clsx(
            styles.group__filter,
            {
              [styles.hidden]: data?.data.length === 0 || isLoading || isFetching,
            },
            'select__group',
          )}
        >
          <Select
            style={{ minWidth: 210 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap
                  color="#8C8C8C"
                  icon={<CloudIcon />}
                  className={clsx(styles.icon__filter, styles.stafficon)}
                />
                {historyUI.direction}
              </>
            }
            onChange={handleChangeSelectDirection}
            value={params.type}
          >
            {typeSync.map((item: { name: string; value: string }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<CloudIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
          <Select
            style={{ width: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap
                  color="#8C8C8C"
                  icon={<CloudIcon />}
                  className={clsx(styles.icon__filter, styles.stafficon)}
                />
                {historyUI.object}
              </>
            }
            onChange={handleChangeCategory}
            value={params.type}
          >
            {categorySync.map((item: { name: string; value: string }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<CloudIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
          <Select
            style={{ width: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap
                  color="#8C8C8C"
                  icon={<CloudIcon />}
                  className={clsx(styles.icon__filter, styles.stafficon)}
                />
                {historyUI.status}
              </>
            }
            onChange={handleChangeStatus}
            value={params.type}
          >
            {status.map((item: { name: string; value: string }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<CloudIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      {!isLoading && (
        <div className={clsx(styles.page__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table rowKey={'id'} columns={columns} pagination={false} dataSource={data.data} scroll={{ x: 700 }} />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty className={styles.empty__results}>{<></>}</DataEmpty>
          )}
        </div>
      )}
      {visible && (
        <DetailLog
          visible={visible}
          onCancel={() => {
            setVisible(false)
            setSelected(undefined)
          }}
          onOK={() => {
            setVisible(false)
            setSelected(undefined)
          }}
          history={selected!}
        />
      )}
    </div>
  )
}
