import { ModalCommon } from '../Modal'
import deleteImg from '../../assets/images/delete.png'
import { ButtonCommon } from '../Button'
import styles from './index.module.scss'
import { CommonUi } from '../../until/text/ui'

interface IConfirmDelete {
  text: string
  title: string
  visible: boolean
  onOk?: () => void
  onCancel?: () => void
  width: string
  isLoading?: boolean
  onConfirmDelete?: () => void
}
export const ConfirmDelete = ({
  text,
  title,
  visible,
  onOk,
  onCancel,
  width,
  isLoading,
  onConfirmDelete,
}: IConfirmDelete) => {
  return (
    <ModalCommon title={title} visible={visible} onOk={onOk} onCancel={onCancel} width={width} destroyOnClose={true}>
      <div>
        <figure className={styles.figure}>
          <img src={deleteImg} />
        </figure>
        <p className={styles.text__confirm}>{text}</p>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel} style={{background: 'transparent'}}>
            {CommonUi.no}
          </ButtonCommon>
          <ButtonCommon type="primary" onClick={onConfirmDelete} loading={isLoading}>
            {CommonUi.yes}
          </ButtonCommon>
        </div>
      </div>
    </ModalCommon>
  )
}
