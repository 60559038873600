import clsx from 'clsx'
import { Outlet } from 'react-router-dom'
import FooterLayout from '../Footer'
import HeaderLayout from '../Header'
import styles from './index.module.scss'
interface IProps {
  header?: boolean
}

const MainLayout = ({ header }: IProps) => {
  return (
    <>
      <div className={clsx(styles.main)}>
        {header && <HeaderLayout />}
        <div
          className={clsx(styles.main__content)}
          style={header ? { minHeight: 'calc(100vh - 108px)' } : { minHeight: 'calc(100vh - 40px)' }}
        >
          <div className={clsx(styles.main__content__body)}>
            <Outlet />
          </div>
        </div>
        <div className="main__footer">
          <FooterLayout />
        </div>
      </div>
    </>
  )
}

export default MainLayout
