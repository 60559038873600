// import { envApiUrl } from '../../../apis/axiosClient'
import { ModalCommon } from '../../../components'
import { Loading } from '../../../components/Loading'
import { useGetImageMeter } from '../../../hooks/query/meter'
import styles from './index.module.scss'
import defaultImg from '../../../assets/images/default.png'

interface IProps {
  img?: string
  title: string
  detailModal?: string
  visible: boolean
  onCancel: () => void
}

export const ModalShowImg = ({ detailModal, title, visible, onCancel, img }: IProps) => {
  const { data, isLoading } = useGetImageMeter(img || '')

  const handleImg = () => {
    if (data) {
      const url = URL.createObjectURL(data)
      return url
    }

    return defaultImg
  }
  return (
    <ModalCommon
      title={title}
      detailModal={detailModal}
      visible={visible}
      onCancel={onCancel}
      width="600px"
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <Loading isLoading={isLoading} className={styles.loading} />
        {!isLoading ? <figure className={styles.figure}>{<img src={handleImg()} alt="img of meter" />}</figure> : null}
      </div>
    </ModalCommon>
  )
}
