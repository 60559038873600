export const homeUi = {
  home: 'ホーム',
  staff: 'ユーザー',
  building: '建物',
  meter: 'メーター',
  summary: '集計',
  history: '同期履歴',
  username: 'ユーザー名',
  numOfBuilding: '建物数',
  numOfStaff: 'ユーザー数',
  numOfMeters: 'メーター数',
  meterType: 'メーター種類追加',
  footer: '© 2022  検針@CITY - All Rights Reserved. ',
  createPriceFormula: '請求金額算出',
  createFormula: '計算式作成',
  editFormula: '計算式編集',
  priceManager: '料金表',
  invoiceManager: '請求書',
  contractor: '契約者',
  trafficManager: '通信量管理',
  terminalManager: '端末管理',
  extraordinary: '異常値管理',
  extraordinaryManager: '異常値管理',
  other: 'その他',
  setting: 'マスタ設定',
  operationLog: '操作ログ',
}
