export const terminalUi = {
  header: '端末管理',
  title: 'ユーザー名',
  device: '端末名',
  MAC: 'デバイスID',
  modalTitleAdd: '端末追加',
  modalTitleEdit: '端末編集',
  buttonAdd: '端末追加',
  buttonCancel: 'キャンセル',
  buttonCreate: '確定',
  buttonEdit: '編集',
  buttonDelete: '削除',
  placeholderSelect: '選択してください。',
  placeholderInput: '入力してください。',
  invalid: '無効',
  noResultsMessage: '以下のボタンから端末を追加してください',
}
