export const historyUI = {
  timeStart: '実行時刻',
  timeEnd: '終了時間',
  direction: '同期区分',
  object: '対象',
  status: '状態',
  log: 'ログ',
  title: '同期履歴',
  detailLog: '詳細',
  messageLog: '詳細を見る',
  statusDone: '完了',
  statusError: 'エラー',
  statusProcessing: '実行中',
  objectBuilding: '建物',
  objectMeter: 'メーター',
  objectScan: 'メーター検針',
  objectFormula: '計算式',
  httpStatus: 'HTTP_STATUS',
  noContent: 'データがありません。',
  price: '料金表',
  contractor: '契約者'
}
