import { Avatar, Dropdown, Menu } from 'antd'
import styles from './index.module.scss'
import { UserOutlined } from '@ant-design/icons'
import { MoreIcon } from '../../../assets/icons'
import { ConfirmDelete, IconWrap } from '../../../components'
import clsx from 'clsx'
import { useState } from 'react'
import { staffUi } from '../../../until/text/ui'
import { ModalQR } from '../ModalQR'
import { StaffModal } from '../Modal'
import { IStaff } from '../../../models'
import { envApiUrl } from '../../../apis/axiosClient'

interface ICardStaff {
  data: Partial<IStaff>
  // eslint-disable-next-line no-unused-vars
  handleDeleteStaff: (id?: number) => void
  checkBox: React.ReactNode
  isChecked?: boolean
  isLoadingDelete?: boolean
  handleResetCheck: () => void
}

export const CardStaff = ({ data, handleDeleteStaff, checkBox, isChecked, isLoadingDelete, handleResetCheck }: ICardStaff) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const [staffModalVisible, setStaffModalVisible] = useState(false)
  const [deleteStaffModalVisible, setDeleteStaffModalVisible] = useState(false)
  const handleUpdateInfo = () => {
    setOpenMenu(false)
    setStaffModalVisible(true)
  }

  const menuStaff = (
    <Menu
      items={[
        {
          label: (
            <a
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
              onClick={() => {
                setVisible(true)
                setOpenMenu(false)
              }}
            >
              {staffUi.qrCode}
            </a>
          ),
          key: '0',
        },
        {
          label: (
            <a style={{ display: 'block', width: '100%', textAlign: 'center' }} onClick={handleUpdateInfo}>
              {staffUi.updateInformation}
            </a>
          ),
          key: '1',
        },
        {
          label: (
            <a
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
              onClick={() => {
                setDeleteStaffModalVisible(true)
                setOpenMenu(false)
              }}
            >
              {staffUi.delete}
            </a>
          ),
          key: '2',
        },
      ]}
    />
  )

  const menuManager = (
    <Menu
      items={[
        {
          label: (
            <a style={{ display: 'block', width: '100%', textAlign: 'center' }} onClick={handleUpdateInfo}>
              {staffUi.updateInformation}
            </a>
          ),
          key: '1',
        },
        {
          label: (
            <a
              style={{ display: 'block', width: '100%', textAlign: 'center' }}
              onClick={() => {
                setDeleteStaffModalVisible(true)
                setOpenMenu(false)
              }}
            >
              {staffUi.delete}
            </a>
          ),
          key: '2',
        },
      ]}
    />
  )

  return (
    <div className={clsx(styles.addstaff__container, { [styles.checked]: isChecked })}>
      <div className={styles.top}>
        <div className={styles.checkbox__wrapper}>{checkBox}</div>
        <Avatar
          src={data && data.url_avatar ? `${envApiUrl}${data?.url_avatar}` : null}
          size={42}
          icon={data?.avatar ? null : <UserOutlined />}
        />
        <div className={styles.userInfor}>
          <span>{data?.username}</span>
          <span>{data?.user_type?.toString() === '2' ? staffUi.manager : staffUi.staff}</span>
        </div>
        <Dropdown
          placement="bottom"
          overlay={data?.user_type?.toString() === '2' ? menuManager : menuStaff}
          className={styles.dropdown}
          trigger={['click']}
          onVisibleChange={(visible: boolean) => {
            setOpenMenu(visible)
          }}
        >
          <div className={clsx({ [styles.isopen]: openMenu }, styles.threedot)}>
            <IconWrap icon={<MoreIcon />} size={18} />
          </div>
        </Dropdown>
      </div>
      <div className={styles.bottom}>
        <div className={styles.building}>
          <span>{staffUi.building}:</span>
          {data.buildings && data.buildings.length > 0 ? (
            data.buildings
              .filter((item, index) => index <= 1)
              .map((item, index) => <span key={index}>{item.name}</span>)
          ) : (
            <span className={styles.no__building}>{staffUi.noBuilding}</span>
          )}
          {data.buildings && data.buildings.length > 2 ? (
            <span className={styles.building__more}>+{data.buildings.length - 2}</span>
          ) : null}
        </div>
        <div className={clsx(styles.status, { [styles.status__active]: Number(data?.status) === 1 })}>
          <span>{staffUi.status}:</span>
          <span>{Number(data?.status) === 0 ? `${staffUi.inactive}` : `${staffUi.active}`}</span>
        </div>
      </div>
      <ModalQR
        data={data}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        title={staffUi.qrCode}
      />
      {staffModalVisible && (
        <StaffModal
          resetCheck={handleResetCheck}
          title={staffUi.updateInformation}
          visible={staffModalVisible}
          onOk={() => setStaffModalVisible(false)}
          onCancel={() => setStaffModalVisible(false)}
          staff={data}
        />
      )}
      {deleteStaffModalVisible && (
        <ConfirmDelete
          title={staffUi.delete}
          visible={deleteStaffModalVisible}
          onOk={() => setDeleteStaffModalVisible(false)}
          onCancel={() => setDeleteStaffModalVisible(false)}
          text={`ユーザー：${data.full_name}は削除してもよろしいですか。`}
          width={'50%'}
          onConfirmDelete={() => handleDeleteStaff(Number(data.id))}
          isLoading={isLoadingDelete}
        />
      )}
    </div>
  )
}
