import React, { ReactElement } from 'react'
import styles from './index.module.scss'
import emptyImg from '../../assets/images/noResults.png'
import { CommonUi } from '../../until/text/ui'
import clsx from 'clsx'
interface Iprops {
  detail?: string
  children: ReactElement
  className?: string
  onLoadImg?: () => void
}
export const DataEmpty = ({ detail, children, className, onLoadImg }: Iprops) => {
  return (
    <div className={clsx(styles.empty__wrap, className)}>
      <div className={styles.empty}>
        <img onLoad={onLoadImg} src={emptyImg} alt="" />
        <h2>{CommonUi.noResultsFound}</h2>
        <p>{detail}</p>
      </div>
      {children}
    </div>
  )
}
