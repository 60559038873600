// import axiosClient from '../axiosClient'

import { ILogin, IResponseLogin, IUserInfor } from '../../models/user'
import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'

class User {
  login(data: ILogin): Promise<IResponseLogin> {
    return axiosClient.post('/login', data).then((res) => res.data?.data)
  }
  logout() {
    return axiosClient.post('/logout')
  }
  getProfile() {
    return axiosClient.get('/me').then((res) => {
      // localStorage.setItem('user_type', res.data?.data.user_type)
      return res.data?.data || EXAMPLE_RESPONSE
    })
  }
  updateProfile(data: IUserInfor): Promise<IUserInfor> {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })
    return axiosClient.post('/me', formData).then((res) => res.data?.data || EXAMPLE_RESPONSE)
  }
  genToken(data: string) {
    return axiosClient.put(`/${data}/reset-token`)
  }
}

export const UserApi = new User()
