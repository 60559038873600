export const buildingUi = {
  buildingList: '建物一覧',
  search: '検索',
  importCSV: 'CSV取り込み',
  postal_code: '郵便番号',
  address_1: '住所1',
  address_2: '住所2',
  noResultsMessage: '以下のボタンから建物を追加してください',
  managerment_association_name: '管理組合名',
  building_code: '管理組合No',
}
