import { Descriptions } from 'antd'
import { ModalCommon } from '../../../components'
import { IHistory } from '../../../models'
import { historyUI } from '../../../until/text/ui'
import styles from './index.module.scss'
import { find } from 'lodash'
import { categorySync, status, typeSync } from '..'
import moment from 'moment'
interface IProps {
  visible: boolean
  onOK: () => void
  onCancel: () => void
  history: IHistory
}

export const DetailLog = ({ visible, onCancel, onOK, history }: IProps) => {
  return (
    <ModalCommon
      title={historyUI.detailLog}
      destroyOnClose={true}
      visible={visible}
      width='60%'
      className={styles.modal}
      onOk={onOK}
      onCancel={onCancel}
    >
      <div className={styles.content}>
        <div className={styles.content__wrap}>
          <Descriptions labelStyle={{fontWeight: 900}} layout="horizontal" column={1}>
            <Descriptions.Item label={historyUI.direction}>{find(typeSync, ['value', history.sync_type])?.name}</Descriptions.Item>
            <Descriptions.Item label={historyUI.timeStart}>{moment(history.time_start).format('YYYY/MM/DD HH:mm')}</Descriptions.Item>
            <Descriptions.Item label={historyUI.object}>{history.sync_type === '1' ? categorySync[2].name : find(categorySync, ['value', history.sync_category])?.name}</Descriptions.Item>
            <Descriptions.Item label={historyUI.status} contentStyle={{color: find(status, ['value', history.status])?.color}}>{find(status, ['value', history.status])?.name}</Descriptions.Item>
            <Descriptions.Item label={historyUI.httpStatus}>{history?.HTTP_STATUS}</Descriptions.Item>
            <Descriptions.Item label={historyUI.log}>{!history.message && historyUI.noContent}</Descriptions.Item>
          </Descriptions>
          {!!history.message && <pre className={styles.content__wrap__log} dangerouslySetInnerHTML={{__html: history.message ? JSON.stringify(history.message, null , 2) : ''}}>
          </pre>}
        </div>
      </div>
    </ModalCommon>
  )
}