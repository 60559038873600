import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Terminal {
  createMAC = async (data: any) => {
    const url = `/${EndPoint.terminal}`
    const res = await axiosClient.post(url, data)
    return res.data
  }
  getAllMAC = async (params?: any) => {
    const url = `/${EndPoint.terminal}`
    const res = await axiosClient.get(url, { params })
    return res.data.data
  }
  getDetailMAC = async (id: number) => {
    const url = `/${EndPoint.terminal}/${id}`
    const res = await axiosClient.get(url)
    return res.data.data
  }
  updateMAC = async (id: number, data: any) => {
    const url = `/${EndPoint.terminal}/${id}`
    const res = await axiosClient.put(url, data)
    return res.data.data
  }
  deleteMAC = async (id: number) => {
    const url = `/${EndPoint.terminal}/${id}`
    const res = await axiosClient.delete(url)
    return res.data
  }
}

export const TerminalApi = new Terminal()
