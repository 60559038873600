import clsx from 'clsx'
import { BuildingIcon, ContractIcon, LogHistory, MeterIcon, StaffIcon, SummaryIcon, Invoice, Price } from '../../assets/icons'
import { PageHeader } from '../../components'
import { IconWrap } from '../../components/IconWrap'
import styles from './index.module.scss'
import headerImg from '../../assets/images/homeHeader.png'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { homeUi } from '../../until/text/ui'
import { useGetAllSummary } from '../../hooks/query/summary'

export const Home = () => {
  const navigate = useNavigate()
  const { data } = useGetAllSummary()
  const userType = localStorage.getItem('user_type') || ''
  return (
    <div className={clsx(styles.home)}>
      <PageHeader backgroundImage={headerImg} height="200px">
        <div className={clsx(styles.home__header)}>
          <div className={clsx(styles.home__header__item)}>
            <span>{homeUi.numOfBuilding}</span>
            <span>{data?.data.total_building}</span>
          </div>
          <div className={clsx(styles.home__header__item)}>
            <span>{homeUi.numOfStaff}</span>
            <span>{data?.data.total_staff}</span>
          </div>
          <div className={clsx(styles.home__header__item)}>
            <span>{homeUi.numOfMeters}</span>
            <span>{data?.data.total_meter}</span>
          </div>
        </div>
      </PageHeader>
      <div className={clsx(styles.home__main)}>
        {
          userType === '1' ?
            <Row gutter={[40, 40]} className={clsx(styles.home__main__row)}>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('staff')}>
                  <IconWrap icon={<StaffIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.staff}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('building')}>
                  <IconWrap icon={<BuildingIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.building}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('meter')}>
                  <IconWrap icon={<MeterIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.meter}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('summary')}>
                  <IconWrap icon={<SummaryIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.summary}</p>
                </div>
              </Col>

              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('history')}>
                  <IconWrap icon={<LogHistory />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.history}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('contractor')}>
                  <IconWrap icon={<ContractIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.contractor}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('price-manager')}>
                  <IconWrap icon={<Price />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.priceManager}</p>
                </div>
              </Col>

              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('invoice-manager')}>
                  <IconWrap icon={<Invoice />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.invoiceManager}</p>
                </div>
              </Col>

            </Row>
            :
            <Row gutter={[40, 40]} className={clsx(styles.home__main__row)}>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('staff')}>
                  <IconWrap icon={<StaffIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.staff}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('building')}>
                  <IconWrap icon={<BuildingIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.building}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('meter')}>
                  <IconWrap icon={<MeterIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.meter}</p>
                </div>
              </Col>

              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('summary')}>
                  <IconWrap icon={<SummaryIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.summary}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('contractor')}>
                  <IconWrap icon={<ContractIcon />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.contractor}</p>
                </div>
              </Col>
              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('price-manager')}>
                  <IconWrap icon={<Price />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.priceManager}</p>
                </div>
              </Col>

              <Col>
                <div className={clsx(styles.home__item)} onClick={() => navigate('invoice-manager')}>
                  <IconWrap icon={<Invoice />} className={clsx(styles.home__item__icon)} />
                  <p>{homeUi.invoiceManager}</p>
                </div>
              </Col>
            </Row>
        }
      </div>
    </div>
  )
}
