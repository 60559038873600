import { notification } from 'antd'
import styles from './index.module.scss'
type NotificationType = 'success' | 'info' | 'warning' | 'error'

export const openNotificationWithIcon = (type: NotificationType, message?: string, description?: string) => {
  notification[type]({
    message: message,
    description: <span className={styles.description}>{description}</span>,
  })
}
