import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
// import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import type { UploadChangeParam } from 'antd/es/upload'
import styles from './index.module.scss'
import clsx from 'clsx'
import { CommonUi } from '../../until/text/ui'
import { IconWrap } from '../IconWrap'
import { UploadIcon } from '../../assets/icons'
import { MessageCommon } from '../../until/text/messages'
import UserDefault from '../../assets/images/UserDefault.png'
import { openNotificationWithIcon } from '../../hooks'
import { CloseOutlined } from '@ant-design/icons'
import { SIZE_5MB } from '../../until/constant'
// import { regex } from '../../until'
// import Avatar from 'antd/lib/avatar/avatar'

// eslint-disable-next-line no-unused-vars
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}
const beforeUpload = (file: RcFile) => {
  const extension = file.name.split('.').at(-1)
  const isJpgOrPng =
    extension === 'png' ||
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'PNG' ||
    extension === 'JPG' ||
    extension === 'JPEG'
  if (!isJpgOrPng) {
    openNotificationWithIcon('error', '', MessageCommon.invalidImgType)
    return
  }
  const isLt5M = file.size < SIZE_5MB
  if (!isLt5M) {
    openNotificationWithIcon('error', '', MessageCommon.img5MG)
    return
  }
  return false
}

interface IUploadImage {
  title: string
  hasBorder: boolean
  className?: string
  url?: string
  onChangeAvatar?: any
  setLoadingImg?: React.Dispatch<React.SetStateAction<boolean>>
  isAllow?: boolean
  onClear?: boolean
  setClear?: () => void
  useDefaultAvatar?: boolean
}

export const UploadImage = ({
  title,
  hasBorder,
  className,
  url,
  onChangeAvatar,
  setLoadingImg,
  isAllow,
  onClear,
  setClear,
  useDefaultAvatar = false,
}: IUploadImage) => {
  const handleDefaultAvatar = () => {
    if (useDefaultAvatar) {
      return UserDefault
    }
    return ''
  }
  const [loading, setLoading] = useState(false)

  const [imageUrl, setImageUrl] = useState<string>(url ? url : handleDefaultAvatar())

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    getBase64(info.file as RcFile, (url) => {
      setLoading(false)
      setImageUrl(url)
    })
    onChangeAvatar?.(info.file)
  }

  const uploadButton = (
    <div className={styles.uploadButton}>
      {title ? <span>{title}</span> : null}
      <div className={styles.uploadbutton__container}>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        {CommonUi.uploadImage}
      </div>
    </div>
  )

  const handleImgEvent = () => {
    setLoadingImg?.(false)
  }

  useEffect(() => {
    if (onClear) {
      setImageUrl(url || UserDefault)
      setClear?.()
    }
  }, [onClear])

  useEffect(() => {
    if (url) {
      setImageUrl(url)
    }
  }, [url])

  const handleDeleteImage = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!useDefaultAvatar) {
      setImageUrl('')
    } else {
      setImageUrl(UserDefault)
    }
    onChangeAvatar?.('null')
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      accept=".jpg,.jpeg,.png"
      className={clsx(styles.upload__container, { [styles.border__right]: hasBorder }, className)}
      disabled={isAllow}
    >
      {imageUrl ? (
        <div className={styles.img__container}>
          {title ? <span>{title}</span> : null}
          <figure className={clsx(styles.figure, { [styles.figure__cusor]: isAllow })}>
            {!isAllow ? (
              <div className={clsx(styles.changelogo__overlay)}>
                {imageUrl === UserDefault ? null : (
                  <div onClick={handleDeleteImage} className={styles.delete__logo}>
                    <CloseOutlined />
                  </div>
                )}
                <IconWrap icon={<UploadIcon />} />
                <p>{CommonUi.changeAvatar}</p>
              </div>
            ) : null}
            <img src={imageUrl} alt="avatar" onLoad={handleImgEvent} onError={handleImgEvent} />
          </figure>
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  )
}
