import { Form } from 'antd'
import { ButtonCommon, ModalCommon, RequireTag } from '../../../components'
import styles from './index.module.scss'
import { InputPasswordCommon } from '../../../components/Input/Password'
import { regex } from '../../../until'
import { usePostProfile } from '../../../hooks/query/user'
import { profileMessage } from '../../../until/text/messages'
import { profileUi } from '../../../until/text/ui'
import { openNotificationWithIcon } from '../../../hooks'
interface IProps {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export const PasswordModal = ({ visible, onOk, onCancel }: IProps) => {
  const { mutateAsync, isLoading } = usePostProfile()
  const handleFinish = async (values: any) => {
    try {
      await mutateAsync(values)
      openNotificationWithIcon('success', '', profileMessage.updateSuccess)
      onOk()
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  return (
    <ModalCommon
      destroyOnClose={true}
      title={profileUi.changePassword}
      visible={visible}
      width="29%"
      className={styles.modal}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form onFinish={handleFinish} className={styles.form} layout="vertical">
        <div className={styles.form__wrap}>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {profileUi.currentPassword}
                <RequireTag />
              </>
            }
            name="current_password"
            className={styles.form__item}
            rules={[
              {
                required: true,
                message: `${profileMessage.requireCurrentPwd}`,
              },
            ]}
          >
            <InputPasswordCommon placeholder={profileMessage.placeholderInput} />
          </Form.Item>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {profileUi.newPassword}
                <RequireTag />
              </>
            }
            name="password"
            dependencies={['current_password']}
            className={styles.form__item}
            rules={[
              {
                required: true,
                message: `${profileMessage.newPwd}`,
              },
              {
                pattern: regex.validatePassword,
                message: <span style={{ whiteSpace: 'break-spaces' }}>{profileMessage.ruleNewPassword}</span>,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue('current_password') === value) {
                    return Promise.reject(`${profileMessage.mathCurrentPass}`)
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputPasswordCommon placeholder={profileMessage.placeholderInput} />
          </Form.Item>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {profileUi.confirmPassword}
                <RequireTag />
              </>
            }
            name="confirmPassword"
            className={styles.form__item}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: `${profileMessage.confirmPassword}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(`${profileMessage.confirmPwdNotMatch}`)
                },
              }),
            ]}
          >
            <InputPasswordCommon placeholder={profileMessage.placeholderInput} />
          </Form.Item>
        </div>
        <div className={styles.form__btn__wrap}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {profileUi.cancel}
          </ButtonCommon>
          <ButtonCommon loading={isLoading} type="primary" htmlType="submit">
            {profileUi.savePassword}
          </ButtonCommon>
        </div>
      </Form>
    </ModalCommon>
  )
}
