export const HomeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6666 14H3.33329C3.15648 14 2.98691 13.9298 2.86189 13.8047C2.73686 13.6797 2.66663 13.5101 2.66663 13.3333V7.33333H0.666626L7.55129 1.07466C7.67403 0.962983 7.83401 0.901093 7.99996 0.901093C8.1659 0.901093 8.32589 0.962983 8.44863 1.07466L15.3333 7.33333H13.3333V13.3333C13.3333 13.5101 13.2631 13.6797 13.138 13.8047C13.013 13.9298 12.8434 14 12.6666 14ZM3.99996 12.6667H12V6.10466L7.99996 2.46866L3.99996 6.10466V12.6667ZM5.99996 6.66666H9.99996V10.6667H5.99996V6.66666ZM7.33329 8V9.33333H8.66663V8H7.33329Z"
        fill="#E6F7FF"
      />
    </svg>
  )
}
