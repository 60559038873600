import { useQuery } from 'react-query'
import { OperationLogApi } from '../../../apis'
import { IOpreationLogParams, IResOperationLog } from '../../../models'

export const ServerStateKeys = {
  operation_log: 'operation_log',
}

export const useGetOperationLog = (params?: IOpreationLogParams) => {
  return useQuery<IResOperationLog, Error>([ServerStateKeys.operation_log, params], () =>
    OperationLogApi.getListOperationLog(params),
  )
}

