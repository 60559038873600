export const Copy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2V8H20V18H8V2H14ZM22 20V6L16 0H6V4H2V24H18V20H22ZM16 20V22H4V6H6V20H16ZM19.1716 6L16 2.82843V6H19.1716Z"
        fill="#389E0D"
      />
    </svg>
  )
}
