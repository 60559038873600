import { useState } from 'react'
import { ButtonCommon, DatePickerCommon, PageHeader, SelectCommon } from '../../components'
import headerImg from '../../assets/images/summaryHeader.png'
import styles from './index.module.scss'
import { Form, Select } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { meterUi, summaryUi } from '../../until/text/ui'
import clsx from 'clsx'
import { ImageIcon } from '../../assets/icons'
import { useDownloadCsvSummary, useDownloadImgSummary, useSuggestionsSummary } from '../../hooks/query/summary'
import type { DatePickerProps } from 'antd'
import moment from 'moment'
import { DownOutlined } from '@ant-design/icons'
import { openNotificationWithIcon } from '../../hooks'
import { summaryMessage } from '../../until/text/messages'

export const Summary = () => {
  const [params, setParams] = useState<any>({
    year: null,
    month: null,
    management_association_id: '',
  })

  const fetchDataDownloadCsv = useDownloadCsvSummary({
    ...params,
    year: params.year,
  })

  const fetchDataDownloadImg = useDownloadImgSummary({
    ...params,
    year: params.year,
  })
  const { data: listSuggestions, isLoading: loadingSuggestions } = useSuggestionsSummary()

  const handleNameCsv = (year?: number, month?: number, fileType?: string) => {
    const association = listSuggestions.management_associations.find((item: any) => {
      return item.id === params.management_association_id
    })
    if (!year && !month) return `${association.no}${association.name && '_'+association.name}.${fileType}`
    if (year && !month) return `${association.no}${association.name && '_'+association.name}_${params?.year}.${fileType}`
    if (year && month) return `${association.no}${association.name && '_'+association.name}_${params?.year}-${params?.month}.${fileType}`
    return '*'
  }

  const handleNameImg = (year?: number, month?: number, fileType?: string) => {
    const association = listSuggestions.management_associations.find((item: any) => {
      return item.id === params.management_association_id
    })
    if (!year && !month) return `${association.no}${association.name && '_'+association.name}_image.${fileType}`
    if (year && !month) return `${association.no}${association.name && '_'+association.name}_${params?.year}_image.${fileType}`
    if (year && month) return `${association.no}${association.name && '_'+association.name}_${params?.year}-${params?.month}_image.${fileType}`
    return '*'
  }

  const handleExportCSV = async () => {
    try {
      const { data: dataExport } = await fetchDataDownloadCsv.refetch()
      if (dataExport) {
        const a = document.createElement('a')
        const csvFile = new Blob(['\uFEFF' + dataExport], { type: 'text/csv; charset=utf-8' })
        a.href = URL.createObjectURL(csvFile)
        a.download = handleNameCsv(params?.year, params?.month, 'csv')
        a.click()
      } else {
        openNotificationWithIcon('error', '', summaryMessage.exportFailed)
      }
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  const handleExportImg = async () => {
    try {
      const { data: dataDownloadImg } = await fetchDataDownloadImg.refetch()
      if (dataDownloadImg.message) {
        openNotificationWithIcon('error', '', dataDownloadImg.message)
        return
      }
      const a = document.createElement('a')
      a.href = URL.createObjectURL(dataDownloadImg)
      a.download = handleNameImg(params?.year, params?.month, 'xlsx')
      a.click()
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const handleChangeMonth = (value: number) => {
    setParams({ ...params, month: value })
  }

  // eslint-disable-next-line no-unused-vars
  const handleChangeYear: DatePickerProps['onChange'] = (date, dateString) => {
    if (!date) {
      setParams({ ...params, month: null, year: null })
      return
    }
    setParams({ ...params, year: dateString })
  }

  const handleChangeAssociation = (value: string) => {
    if (!value) {
      setParams({ management_association_id: '', month: null, year: null })
      return
    }
    setParams({ ...params, management_association_id: value })
  }
  return (
    <div>
      <PageHeader title={summaryUi.summary} backgroundImage={headerImg} height="108px" />
      <div className={styles.summary__wrap}>
        <div className={styles.summary__content}>
          <Form layout="vertical">
            <Form.Item label={summaryUi.managementAssociation} className={styles.summary__form__item}>
              <SelectCommon
                showSearch
                className={styles.summary__select}
                placeholder={summaryUi.pleaseSelect}
                loading={loadingSuggestions}
                onChange={handleChangeAssociation}
                allowClear
                filterOption={(input, option) =>  
                  (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
                }
              >
                {listSuggestions
                  ? listSuggestions.management_associations.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {`${item.no}${item.name && ' - ' + item.name}`}
                    </Select.Option>
                  ))
                  : null}
              </SelectCommon>
            </Form.Item>
            <div className={clsx(styles.summary__filter__month)}>
              <DatePickerCommon
                onChange={handleChangeYear}
                picker="year"
                placeholder={meterUi.year}
                format={'YYYY'}
                disabled={!params.management_association_id}
                value={params.year ? moment(params.year, 'YYYY') : null}
                suffixIcon={<DownOutlined style={{ fontSize: '12px' }} />}
              />
              <Select
                disabled={!params.year}
                onChange={handleChangeMonth}
                value={params.month}
                placeholder={meterUi.month}
                className={clsx(styles.month__select)}
                style={{ width: 'calc(50% - 6px)' }}
                allowClear
              >
                {monthArr.map((item: number) => (
                  <Select.Option key={item} value={item}>
                    {`${item} ${meterUi.month}`}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Form>
          <div className={styles.summary__btn}>
            <ButtonCommon
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleExportCSV}
              disabled={!params.management_association_id}
            >
              {summaryUi.exportCSV}
            </ButtonCommon>
            <ButtonCommon
              type="ghost"
              icon={<ImageIcon />}
              onClick={handleExportImg}
              disabled={!params.management_association_id}
            >
              {summaryUi.downloadImage}
            </ButtonCommon>
          </div>
        </div>
      </div>
    </div>
  )
}
