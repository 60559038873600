import { useQuery } from 'react-query'
import { TerminalApi } from '../../../apis/terminal'
import { IResTerminal, ITerminal } from '../../../models'

export const ServerStateKeysTerminal = {
  Terminal: 'terminal',
}

export const useGetAllMAC = (params?: any) =>
  useQuery<IResTerminal, Error>([ServerStateKeysTerminal.Terminal, params], () => TerminalApi.getAllMAC(params))
export const useGetDetailMAC = (id: number) =>
  useQuery<ITerminal, Error>([ServerStateKeysTerminal.Terminal, id], () => TerminalApi.getDetailMAC(id), {
    enabled: !!id,
  })
