import { Tag } from 'antd'
import { CommonUi } from '../../until/text/ui'

interface IRequireTag {
  message?: string
  className?: string
}

export const RequireTag = ({ message, className }: IRequireTag) => {
  return (
    <Tag className={className} style={{ fontSize: '12px', marginLeft: '4px' }} color="#ff4d4f">
      {message || CommonUi.required}
    </Tag>
  )
}
