import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AdminApi } from '../../../apis'
import { IResStaff } from '../../../models'

export const ServerStateKeys = {
  Staff: 'staff/getAll',
}

export const useGetAdmin = (params?: any) =>
  useQuery<IResStaff, Error>([ServerStateKeys.Staff, params], () => AdminApi.getAllAdmin(params))

export const useCreateAdmin = () => {
  const cache = useQueryClient()
  return useMutation(AdminApi.createAdmin, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.Staff)
    },
  })
}

export const useUpdateAdmin = () => {
  const cache = useQueryClient()
  return useMutation(AdminApi.updateAdmin, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.Staff)
    },
  })
}
