import { Form, Select } from 'antd'
import { ButtonCommon, ModalCommon, RequireTag } from '../../../components'
import { extraordinaryUi, terminalUi } from '../../../until/text/ui'
import styles from './index.module.scss'
import clsx from 'clsx'
import { openNotificationWithIcon } from '../../../hooks'
import { useMutation } from 'react-query'
import { IExtraordinary } from '../../../models'
import { useEffect } from 'react'
import { extraordinaryMessage, meterTypeKey, terminalMessage, typeMeter } from '../../../until'
import { InputNumberCommon } from '../../../components/Input/Number'
import { ExtraordinaryApi } from '../../../apis/extraordinary'

interface IProps {
  dataDetail?: any
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  id?: string
  loading: boolean
  setLoading: any
  refetch: () => void
  listStaff?: any
  listBuilding: any
}

export const ExtraordinaryManagerModal = ({
  listBuilding,
  dataDetail,
  loading,
  setLoading,
  refetch,
  title,
  visible,
  onOk,
  onCancel,
}: IProps) => {
  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue({
        building_id: dataDetail.building_id,
        meter_type: meterTypeKey[dataDetail.meter_type],
        value: dataDetail.value,
      })
    }
  }, [dataDetail])
  const [form] = Form.useForm()
  const { mutate: mutateCreate } = useMutation((body: IExtraordinary) => ExtraordinaryApi.createExtraordinary(body), {
    onSuccess: () => {
      setLoading(false)
      refetch()
      onCancel()
      openNotificationWithIcon('success', terminalMessage.createSuccess)
    },
    onError: (error: any) => {
      setLoading(false)
      openNotificationWithIcon('error', error?.message)
    },
  })
  const { mutate: mutateUpdate } = useMutation(
    (body: IExtraordinary) => ExtraordinaryApi.updateExtraordinary(dataDetail.id, body),
    {
      onSuccess: () => {
        refetch()
        setLoading(false)
        onCancel()
        openNotificationWithIcon('success', terminalMessage.updateSuccess)
      },
      onError: (error: any) => {
        setLoading(false)
        openNotificationWithIcon('error', error?.message)
      },
    },
  )
  const onFinish = (value: IExtraordinary) => {
    dataDetail ? mutateUpdate(value) : mutateCreate(value)
    setLoading(true)
  }

  return (
    <ModalCommon destroyOnClose={true} title={title} visible={visible} onOk={onOk} onCancel={onCancel} width="850px">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={clsx(styles.form__extraordinary)}>
          <Form.Item
            label={
              <>
                {extraordinaryUi.placeholderBuilding}
                {!dataDetail && <RequireTag />}
              </>
            }
            rules={[
              {
                required: true,
                message: extraordinaryMessage.buildingRequired,
              },
            ]}
            style={{ marginBottom: '8px' }}
            name="building_id"
            validateTrigger="onSubmit"
          >
            <Select
              showSearch
              className={clsx(styles.form__extraordinary__select)}
              maxTagCount="responsive"
              optionFilterProp="children"
              placeholder={terminalUi.placeholderSelect}
              disabled={dataDetail ? true : false}
            >
              {listBuilding?.data?.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <>
                {extraordinaryUi.placeholderType}
                {!dataDetail && <RequireTag />}
              </>
            }
            rules={[
              {
                required: true,
                message: extraordinaryMessage.typeRequired,
              },
            ]}
            style={{ marginBottom: '8px' }}
            name={'meter_type'}
            validateTrigger="onSubmit"
          >
            <Select
              maxTagCount="responsive"
              placeholder={terminalUi.placeholderSelect}
              disabled={dataDetail ? true : false}
            >
              {typeMeter?.map((item: any) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <>
                {extraordinaryUi.extraordinary}
                <RequireTag />
              </>
            }
            rules={[
              {
                required: true,
                message: extraordinaryMessage.valueRequired,
              },
            ]}
            style={{ marginBottom: '0' }}
            name={'value'}
            validateTrigger="onSubmit"
            initialValue={''}
          >
            <InputNumberCommon
              min={1}
              max={99999}
              precision={0}
              maxLength={5}
              placeholder={terminalUi.placeholderInput}
            />
          </Form.Item>
        </div>
        <div className={styles.form__extraordinary__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {extraordinaryUi.buttonCancel}
          </ButtonCommon>
          <ButtonCommon loading={loading} type="primary" htmlType="submit" style={{ letterSpacing: '-2px' }}>
            {extraordinaryUi.buttonSave}
          </ButtonCommon>
        </div>
      </Form>
    </ModalCommon>
  )
}
