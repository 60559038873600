import { useQuery } from 'react-query'
import { TrafficApi } from '../../../apis/traffic'
import { IResTraffic } from '../../../models'

export const ServerStateKeysTraffic = {
  Traffic: 'traffic',
}

export const useGetAllTraffic = (params?: any) =>
  useQuery<IResTraffic, Error>([ServerStateKeysTraffic.Traffic, params], () => TrafficApi.getAllTraffic(params))
export const useDownloadTraffic = (params?: any) => {
  return useQuery<any, Error>(
    [ServerStateKeysTraffic.Traffic],
    () => TrafficApi.downloadTraffic(params),
    {
      enabled: false,
    },
  )
}
