import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ContractorsApi } from '../../../apis'
import { IResContractor } from '../../../models/contractors'

export const ContractorServerStateKeys = {
  contractors: 'contractor/getAll',
}

export const useGetContractorManager = (params?: any) =>
  useQuery<IResContractor, Error>([ContractorServerStateKeys.contractors, params], () => ContractorsApi.getListContractors(params))

export const useImportContractors = () => {
  const cache = useQueryClient()
  return useMutation(ContractorsApi.importCSV, {
    onSuccess: () => {
      cache.invalidateQueries(ContractorServerStateKeys.contractors)
    },
  })
}
