import { Form, Select } from 'antd'
import { useCallback, useEffect } from 'react'
import { ButtonCommon, InputCommon, RequireTag, SelectCommon } from '../../../../components'
import { InputNumberCommon } from '../../../../components/Input/Number'
import { regex } from '../../../../until'
import { dailyDivision, MAX_11_DEGIT_VALUE, MAX_5_DEGIT_FLOAT_VALUE, MAX_6_DEGIT_VALUE } from '../../../../until/constant'
import { priceMessage } from '../../../../until/text/messages'
import { managerUI } from '../../../../until/text/ui'
import { priceManagerUI } from '../../../../until/text/ui'
import styles from './index.module.scss'
import { openNotificationWithIcon, useCreatePriceManager } from '../../../../hooks'

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onCancel: () => void
  id?: string
  isLoading?: boolean
}

const FormLabel = {
  quantityLevel: 'quantity_level',
  supplyCost: 'supply_cost',
  lossCost: 'loss_cost',
  cost: 'cost',
  tariffCode: 'tariff_code',
  dailyDivision: 'daily_division'
}

export const WaterForm = ({
  onCancel,
  isLoading,
}: IProps) => {
  const mutateAddPriceManager = useCreatePriceManager()
  const [form] = Form.useForm()
  const handleOnFinish = async (value: any) => {
    try {
      const formData = new FormData()

      for (const key in value) {
        formData.append(key, value[key])
      }

      await mutateAddPriceManager.mutateAsync({ formData: formData })
      openNotificationWithIcon('success', '', `${priceMessage.createSuccess}`)

      onCancel()
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        form.setFields(arrError)
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
    }
  }

  useEffect(() => {
    form.setFieldValue(FormLabel.dailyDivision, dailyDivision[0].value)
  }, [])

  const handleCostChange = useCallback(() => {
    const supplyCost = form.getFieldValue(FormLabel.supplyCost)
    const lossCost = form.getFieldValue(FormLabel.lossCost)
    if (!supplyCost && !lossCost) {
      return form.setFieldValue(FormLabel.cost, supplyCost)
    }

    return form.setFieldValue(FormLabel.cost,
      Number(form.getFieldValue(FormLabel.supplyCost)) + Number(form.getFieldValue(FormLabel.lossCost))
    )
  }, [form.getFieldValue(FormLabel.supplyCost), form.getFieldValue(FormLabel.lossCost)])

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleOnFinish}>
        <div className={styles.form__container}>
          <div className={styles.form__content}>
            <Form.Item
              label={
                <>
                  {priceManagerUI.tariffCode}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputTariffCode,
                },
              ]}
              name={FormLabel.tariffCode}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputCommon
                maxLength={45}
                onBlur={() => {
                  form.setFieldValue(FormLabel.tariffCode, form.getFieldValue(FormLabel.tariffCode))
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.amountUsage}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputQuantityLevel,
                },
                {
                  pattern: regex.validateNumber,
                  message: <span style={{ whiteSpace: 'break-spaces' }}>{priceMessage.inputinputQuantityLevelValid}</span>,
                },
              ]}
              name={FormLabel.quantityLevel}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                max={MAX_5_DEGIT_FLOAT_VALUE}
                maxLength={8}
                precision={2}
                step={0.1}
                onBlur={() => {
                  form.setFieldValue(FormLabel.quantityLevel, form.getFieldValue(FormLabel.quantityLevel))
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.cleanWater}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputWaterSupplyCost,
                },
              ]}
              name={FormLabel.supplyCost}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                max={MAX_6_DEGIT_VALUE}
                maxLength={6}
                onChange={() => {
                  form.setFieldValue(FormLabel.supplyCost, form.getFieldValue(FormLabel.supplyCost))
                  handleCostChange()
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.sewageWater}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputWaterLossCost,
                },
              ]}
              name={FormLabel.lossCost}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                max={MAX_6_DEGIT_VALUE}
                maxLength={6}
                onChange={() => {
                  form.setFieldValue(FormLabel.lossCost, form.getFieldValue(FormLabel.lossCost))
                  handleCostChange()
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.waterCost}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputCost,
                },
              ]}
              name={FormLabel.cost}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                disabled
                max={MAX_11_DEGIT_VALUE}
                maxLength={11}
                onBlur={() => {
                  form.setFieldValue(FormLabel.cost, form.getFieldValue(FormLabel.cost))
                }}
                placeholder={priceManagerUI.waterCostPlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.daily_division}
                  <RequireTag />
                </>
              }
              name={FormLabel.dailyDivision}
              className={styles['form__item--half']}
              validateTrigger="onSubmit"
            >
              <SelectCommon
                autoFocus={false}
                maxTagCount="responsive"
                placeholder={<p className={styles.form__item__placeholder}>{managerUI.placeholderContract}</p>}
                className={styles.select}
                allowClear={false}
              >
                {dailyDivision
                  .map((item: any) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </SelectCommon>
            </Form.Item>
          </div>
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {managerUI.cancel}
          </ButtonCommon>
          <ButtonCommon
            type="primary"
            htmlType="submit"
            loading={isLoading || mutateAddPriceManager.isLoading}
            style={{ letterSpacing: '-2px' }}
          >
            {managerUI.save}
          </ButtonCommon>
        </div>
      </Form>
    </>
  )
}
