import { useQuery } from 'react-query'
import { HistoryApi } from '../../../apis'

export const ServerStateKeys = {
  history: 'history',
}

export const useGetHistory = (params?: any) => {
  return useQuery<any, Error>([ServerStateKeys.history, params], () =>
    HistoryApi.getHistory(params)
  )
}