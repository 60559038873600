import { useEffect, useState } from 'react'
import headerImg from '../../../assets/images/profileHeader.png'

import { ButtonCommon, IconWrap, InputCommon, PageHeader, RequireTag, SelectCommon, UploadImage } from '../../../components'
import { Col, Form, Row, Select } from 'antd'
import styles from './index.module.scss'
import clsx from 'clsx'
import { managerMessage, profileMessage } from '../../../until/text/messages'
import { Loading } from '../../../components/Loading'
import { EditIcon } from '../../../assets/icons'
import { PasswordModal } from '../../Profile/changePasswordModal'
import { useGetProfile, usePostProfile } from '../../../hooks/query/user'
import { UploadFile } from 'antd/es/upload'
import { profileUi, staffUi } from '../../../until/text/ui'
import { envApiUrl } from '../../../apis/axiosClient'
import { openNotificationWithIcon } from '../../../hooks'

export const ProfileManager = () => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState<UploadFile>()
  const [showModal, setShowModal] = useState(false)
  const [isLoadingImg, setIsLoadingImg] = useState(true)
  const [isView, setIsView] = useState(true)
  const { isLoading: dataLoading, data, error } = useGetProfile()
  const [clearImg, setClearImg] = useState(false)
  const { mutateAsync, isLoading } = usePostProfile()
  const onFinish = async (values: any) => {
    try {
      delete values.password
      await mutateAsync({
        ...values,
        avatar: avatar || data?.avatar,
        use_two_step_authentication: values.use_two_step_authentication === 1
      })
      openNotificationWithIcon('success', '', profileMessage.updateSuccess)
      setIsView(true)
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        form.setFields(arrError)
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
    }
  }
  const onHideModal = () => {
    setShowModal(false)
  }

  const oncancel = () => {
    setIsView(true)
    setClearImg(true)
    form.setFieldsValue({
      full_name: data?.full_name || '',
      full_name_katakana: data?.full_name_katakana || '',
      use_two_step_authentication: data?.use_two_step_authentication ? 1 : 0
    })
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        full_name: data?.full_name || '',
        full_name_katakana: data?.full_name_katakana || '',
        email: data?.email || '',
        use_two_step_authentication: data?.use_two_step_authentication ? 1 : 0
      })
      if (!data.avatar) {
        setIsLoadingImg(false)
      }
      return
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setIsLoadingImg(false)
      openNotificationWithIcon('error', '', error?.message)
    }
  }, [error])

  return (
    <div>
      <Loading isLoading={dataLoading || isLoadingImg} />
      <PageHeader title={profileUi.profile} backgroundImage={headerImg} height="108px"></PageHeader>
      <div className={styles.content}>
        <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" className={styles.form}>
          <div className={styles.form__avatar}>
            <UploadImage
              useDefaultAvatar={true}
              isAllow={isView}
              url={data && data.url_avatar ? `${envApiUrl}${data?.url_avatar}` : ''}
              onChangeAvatar={setAvatar}
              setLoadingImg={setIsLoadingImg}
              title=""
              hasBorder={false}
              className={styles.form__avatar__upload}
              onClear={clearImg}
              setClear={() => setClearImg(false)}
            />
          </div>
          <div className={styles['form__control--container']}>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      {profileUi.name}
                      {!isView ? <RequireTag /> : null}
                    </>
                  }
                  name="full_name"
                  className={styles['form__item--half']}
                  rules={[
                    {
                      required: true,
                      message: managerMessage.inputFullName,
                    },
                  ]}
                  initialValue={''}
                  validateTrigger="onSubmit"
                >
                  <InputCommon
                    maxLength={50}
                    placeholder={profileUi.placeholderFullName}
                    disabled={isView}
                    onBlur={() => {
                      form.setFieldValue('full_name', form.getFieldValue('full_name')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.nameKatakana}
                  name="full_name_katakana"
                  className={styles['form__item--half']}
                  initialValue={''}
                >
                  <InputCommon
                    maxLength={50}
                    placeholder={profileUi.placeholderKatakana}
                    disabled={isView}
                    onBlur={() => {
                      form.setFieldValue('full_name_katakana', form.getFieldValue('full_name_katakana')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.email}
                  name="email"
                  className={styles['form__item--half']}
                  initialValue={''}
                >
                  <InputCommon
                    maxLength={150}
                    placeholder={profileUi.placeholderEmail}
                    disabled={true}
                    onBlur={() => {
                      form.setFieldValue('email', form.getFieldValue('email')?.trim())
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={profileUi.password}
                  name="password"
                  className={clsx(styles.form__item, styles['form__item--half'])}
                  initialValue={''}
                >
                  <div>
                    <InputCommon
                      type={'password'}
                      placeholder="●●●●●●●●"
                      disabled={true}
                      onBlur={() => {
                        form.setFieldValue('password', form.getFieldValue('password')?.trim())
                      }}
                    />
                    <IconWrap
                      icon={<EditIcon />}
                      className={styles.form__item__edit}
                      onClick={() => {
                        setShowModal(true)
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={
                    <>
                      {staffUi.twoFactorAuthentication}
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: staffUi.twoFactorAuthentication + managerMessage.isRequired,
                    },
                  ]}
                  name="use_two_step_authentication"
                  className={styles['form__item--half']}
                  validateTrigger="onSubmit"
                >
                  <SelectCommon
                    disabled={isView} 
                    autoFocus={false}
                    maxTagCount="responsive"
                    placeholder={staffUi.twoFactorAuthenticationPlaceholder}
                  >
                    <Select.Option value={1}>{staffUi.active}</Select.Option>
                    <Select.Option value={0}>{staffUi.inactive}</Select.Option>
                  </SelectCommon>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={styles.form__btn}>
            {isView ? (
              <ButtonCommon type="ghost" onClick={() => setIsView(false)} style={{ letterSpacing: '-2px' }}>
                {profileUi.edit}
              </ButtonCommon>
            ) : (
              <>
                <ButtonCommon type="ghost" onClick={oncancel} style={{ letterSpacing: '-2px' }}>
                  {profileUi.cancel}
                </ButtonCommon>
                <ButtonCommon loading={isLoading} type="primary" htmlType="submit" style={{ letterSpacing: '-2px' }}>
                  {profileUi.save}
                </ButtonCommon>
              </>
            )}
          </div>
        </Form>
        <PasswordModal visible={showModal} onOk={onHideModal} onCancel={onHideModal} />
      </div>
    </div>
  )
}
