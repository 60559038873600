export const managerUI = {
  managerList: '管理ユーザー一覧',
  addManager: '管理ユーザー追加',
  edit: '編集',
  profileImage: 'プロフィール画像',
  IDcontract: '契約コード',
  email: 'メールアドレス',
  phone: '電話番号',
  address: '住所',
  address1: '住所 1',
  address2: '住所 2',
  postalCode: '郵便番号',
  status: '状態',
  fullName: '名前',
  fullNameKatakana: '名前カナ',
  contract: '契約コード',
  userName: 'ユーザー名',
  noResultsMessage: '以下のボタンから作業者を追加してください。',
  placeholderName: '名前を入力してください。',
  placeholderNameKatakana: '名前カナを入力してください。',
  placeholderUserName: 'ユーザー名を入力してください。',
  placeholderPhone: '電話番号を入力してください。',
  placeholderEmail: 'メールアドレスを入力してください。',
  placeholderAddress: '住所を入力してください。',
  placeholderPostalCode: '郵便番号を入力してください。',
  placeholderContract: '契約コードを選択してください。',
  placeholderStatus: '状態を選択してください。',
  active: '有効',
  inactive: '無効',
  assignBuilding: '担当する建物設定',
  cancel: 'キャンセル',
  save: '確定',
  titleDefault: '管理ユーザー編集',
  accessToken: 'ベアラートークン',
  confirmReset: 'このトークンをリセットしてもよろしいですか？',
  titleConfirmReset: 'トークンのリセット',
}
