import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './index.module.scss'
import { ButtonCommon } from '../../../components/Button'
import { InputCommon } from '../../../components/Input'
import { Link, useNavigate } from 'react-router-dom'
import { actions, useStore } from '../../../store'
import { Form } from 'antd'
import { InputPasswordCommon } from '../../../components/Input/Password'
import { CommonUi, loginUi } from '../../../until/text/ui'
import { useLogin } from '../../../hooks/query/user'
import { ILogin } from '../../../models/user'
import { AUTHENTICATION_PASSCODE, regex } from '../../../until'
import { authMessage } from '../../../until/text/messages'
import { openNotificationWithIcon } from '../../../hooks'
import { requestForToken } from '../../../firebase'
import { VerifyAuthForm } from '../VerifyAuth'

export const LoginForm: React.FC = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [, dispatch] = useStore()
  const [loading, setLoading] = useState(false)
  const [verify, setVerify] = useState({
    is_required: false,
    email: '',
    password: '',
  })
  const [passcode, setPasscode] = useState<(number | null)[]>(new Array(AUTHENTICATION_PASSCODE).fill(null))

  const mutateLogin = useLogin()

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(actions.setIsAuthenticated(true))
      navigate('/')
    }
  }, [])
  requestForToken()

  const onFinish = async (value: ILogin) => {
    let body = {...value}
    body.device_token = localStorage.getItem('device_token') || ''
    if (verify.is_required) {
      body = {
        ...body,
        email: verify.email,
        password: verify.password,
        token: passcode.join(''),
      }
    }
    setLoading(true)
    try {
      const data = await mutateLogin.mutateAsync(body)
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('role', data.role.toString())
      dispatch(actions.setIsAuthenticated(true))
      setLoading(false)
      if (data.role.toString() === '9') {
        navigate('/admin')
        return
      }
      localStorage.setItem('user_type', data.user_type!.toString())
      navigate('/')
    } catch (error: any) {
      setLoading(false)
      if (error?.is_required) {
        setVerify({
          ...verify,
          is_required: error?.is_required,
          email: value?.email || '',
          password: value?.password || ''
        })
        return
      }
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  if (verify.is_required) {
    return (
      <VerifyAuthForm
        loading={loading}
        onFinish={onFinish}
        passcode={passcode}
        setPasscode={setPasscode}
        form={form}
        onBackLoginForm={
          (value) => {
            setVerify({
              is_required: value,
              email: '',
              password: '',
            })
            setPasscode(new Array(AUTHENTICATION_PASSCODE).fill(null))
          }
        }
      />
    )
  }

  return (
    <div className={styles.login}>
      <div className={clsx(styles.login__title)}>
        <h1 style={{ textAlign: 'center', color: 'white' }}>{CommonUi.logo}</h1>
      </div>
      <div className={clsx(styles.login__page)}>
        <div className={clsx(styles.login__header)}>
          <h1 className={clsx(styles.login__header__text)}>{loginUi.loginTitle}</h1>
          <Form form={form} className={clsx(styles.login__header__form)} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label={loginUi.email}
              name="email"
              className={clsx(styles.form__item)}
              rules={[
                {
                  required: true,
                  message: `${authMessage.requiredEmail}`,
                },
                {
                  pattern: regex.validateEmail,
                  message: `${authMessage.validateEmail}`,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputCommon autoComplete="new-password" placeholder={loginUi.placeHolderEmail} />
            </Form.Item>
            <Form.Item
              label={loginUi.password}
              name="password"
              className={clsx(styles.form__item)}
              rules={[
                {
                  required: true,
                  message: `${authMessage.requiredPassword}`,
                },
              ]}
              validateTrigger="onSubmit"
            >
              <InputPasswordCommon autoComplete="new-password" placeholder={loginUi.placeHolderPassword} />
            </Form.Item>{' '}
            <Link to="/forgot-password" style={{ display: 'inline-block', width: 'fit-content' }}>
              <span>{loginUi.forgotPassword}</span>
            </Link>
            <div style={{ width: '100%' }}>
              <ButtonCommon loading={loading} htmlType="submit" className={clsx(styles.btn__login)}>
                {loginUi.loginBtn}
              </ButtonCommon>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
