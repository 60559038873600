import clsx from 'clsx'
import { PlusIcon, SearchIcon } from '../../assets/icons'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import ManagerList from '../../assets/images/ManagerList.png'
import styles from './index.module.scss'
import { managerUI, meterUi } from '../../until/text/ui'
import { CardManager } from './ManagerCard'
import { Col, Row } from 'antd'
import { useCallback, useRef, useState } from 'react'
import { ManagerModal } from './Modal'
import { Loading } from '../../components/Loading'
import { GenerateUrlQr, openNotificationWithIcon, useGetAllContracts, useGetStaff } from '../../hooks'
import { useCreateAdmin } from '../../hooks/query/manager'
import { DataEmpty } from '../../components/DataEmpty'
import { IStaff } from '../../models'
import debounce from 'lodash.debounce'
import { PaginationCommon } from '../../components/Pagination'

export const Manager = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    page_size: 12,
    search: '',
  })

  const [id, setId] = useState('')
  const { data, isLoading, isFetching } = useGetStaff(params)
  const [listErr, setListErr] = useState<any>()
  // eslint-disable-next-line no-unused-vars
  const { data: dataContracts } = useGetAllContracts()

  const mutateAddAdmin = useCreateAdmin()
  const dataSubmit = useRef<any>(null)
  const handleUploadQrcode = async (file: any) => {
    try {
      const formData = new FormData()
      for (const key in dataSubmit.current) {
        formData.append(key, dataSubmit.current[key])
      }
      formData.append('qr_code', file)
      await mutateAddAdmin.mutateAsync(formData)
      setVisible(false)
      setLoading(false)
      setListErr([])
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        setListErr(arrError)
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
      setLoading(false)
    }
  }
  const onHandleSearch = (e: any) => {
    debouncedSearch(e.target.value)
  }
  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams({ ...params, page: 1, search: nextValue }), 500),
    [],
  )
  const handleOnFinish = async (value: any) => {
    setLoading(true)
    setId(value.email)
    dataSubmit.current = value
  }

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }
  return (
    <div className={clsx(styles.manager)}>
      <PageHeader title={managerUI.managerList} backgroundImage={ManagerList} height={'108px'}>
        <div className={styles.manager__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={meterUi.search}
            className={styles.manager__header__search__input}
            onChange={onHandleSearch}
          />
          <ButtonCommon
            icon={<IconWrap icon={<PlusIcon />} size={14} />}
            className={styles.addManager__btn}
            type="primary"
            onClick={() => setVisible(true)}
          >
            {managerUI.addManager}
          </ButtonCommon>
        </div>
      </PageHeader>
      <Loading isLoading={isLoading || isFetching} />

      {!isLoading && (
        <div className={styles.cardManager__wrap}>
          {Array.isArray(data?.data) && data?.data && data?.data.length > 0 ? (
            <div className={styles.cardManager__container}>
              <Row justify="start" wrap gutter={[24, 24]}>
                {data?.data?.map((item: Partial<IStaff>) => (
                  <Col lg={6} md={8} sm={12} xs={24} key={item.id}>
                    <CardManager key={item.id} data={item} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <DataEmpty detail={managerUI.noResultsMessage}>
              <ButtonCommon
                className={styles.btn__add}
                type="primary"
                icon={<IconWrap icon={<PlusIcon />} color="#fff" />}
                onClick={() => setVisible(true)}
              >
                {managerUI.addManager}
              </ButtonCommon>
            </DataEmpty>
          )}
          {data?.total && data?.total > 12 ? (
            <div className={styles.pagination__container}>
              <PaginationCommon
                defaultCurrent={1}
                current={data?.current_page}
                total={data?.total}
                pageSize={data?.per_page}
                onChangePagination={onChangePagination}
                showSizeChanger
                pageSizeOptions={['12', '24', '60', '120']}
              />
            </div>
          ) : null}
        </div>
      )}
      <GenerateUrlQr id={id} handleResetId={() => setId('')} uploadQrCode={handleUploadQrcode} />
      {visible && (
        <ManagerModal
          listErr={listErr}
          handleOnFinish={(value: string) => handleOnFinish(value)}
          title={managerUI.addManager}
          visible={visible}
          onOk={() => {
            setVisible(false)
            setListErr([])
          }}
          onCancel={() => {
            setVisible(false)
            setListErr([])
          }}
          isLoading={loading}
        />
      )}
    </div>
  )
}
