export const managerMessage = {
  inputFullName: '名前は必須です。',
  inputUserName: ' ユーザー名は必須です。',
  inputPhone: '電話番号を入力してください。',
  invalidPhone: '電話番号の形式が正しくありません。',
  inputEmail: 'メールアドレスは必須です。',
  invalidEmail: 'メールアドレスの形式が正しくありません。',
  inputAddress: '住所を入力してください。',
  invalidPostalCode: '郵便番号の形式が正しくありません。',
  selectContract: '契約コードは必須です。',
  updateSuccess: ' 更新に成功しました。',
  selectStatus: ' 状態は必須です。',
  whiteSpace: 'ユーザー名の形式が正しくありません。',
  copyToClipboard: 'クリップボードにコピーしました。',
  resetSuccess: 'リセットに成功しました。',
  isRequired: 'は必須です。',
}
