import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BuildingApi } from '../../../apis'
import { IResBuilding } from '../../../models'

export const ServerStateKeys = {
  building: 'building',
  suggestions: 'suggestions',
}

export const useGetListBuilding = (params?: any) => {
  const cache = useQueryClient()
  return useQuery<IResBuilding, Error>([ServerStateKeys.building, params], () => BuildingApi.getListbuilding(params), {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.suggestions)
    }
  })
}

export const useGetAllBuilding = () => {
  return useQuery<IResBuilding, Error>([ServerStateKeys.building], () => BuildingApi.getListbuilding())
}

export const useGetAllContract = () => {
  return useQuery<IResBuilding, Error>([ServerStateKeys.building], () => BuildingApi.getListContract())
}

export const useImportBuilding = () => {
  const cache = useQueryClient()
  return useMutation(BuildingApi.importCSV, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.building)
    },
  })
}
