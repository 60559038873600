import { Select, Tooltip } from 'antd'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table'
import clsx from 'clsx'
import { Alert, DownloadIcon } from '../../../assets/icons'
import { DatePickerCommon, IconWrap, ModalCommon } from '../../../components'
import { useDownloadDetailMeter, useGetHistoryMeter } from '../../../hooks/query/meter'
import { IMeter, IMeterDetail } from '../../../models'
import { meterUi } from '../../../until/text/ui'
import { useState } from 'react'
import styles from './index.module.scss'
import type { DatePickerProps } from 'antd'
import moment from 'moment'
import { ModalShowImg } from '../ShowImgModal'
import { PaginationCommon } from '../../../components/Pagination'
interface IProps {
  title: string
  detailModal?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  meter?: IMeter
}

export const ModalMeterDetail = ({ title, detailModal, visible, onOk, onCancel, meter }: IProps) => {
  const [showModalImg, setShowModalImg] = useState(false)
  const [currentMeter, setCurrentMeter] = useState<IMeterDetail>()
  const [params, setParams] = useState<any>({
    year: null,
    month: null,
    page_size: 10,
    page: 1,
  })
  const { data: listHistory, isLoading } = useGetHistoryMeter({
    id: meter?.id,
    year: params?.year,
    month: params?.month,
    page_size: params?.page_size,
    page: params?.page,
  })

  const fetchDataMeterDetail = useDownloadDetailMeter({
    id: meter?.id,
    year: params?.year,
    month: params?.month,
  })

  const handleNameCsv = (management_association_no: string, room_no: string, year?: number, month?: number) => {
    if (year && !month) return `${management_association_no}_${room_no}_${params?.year}.csv`
    if (year && month)
      return `${meter?.management_association_no}_${meter?.room_no}_${meter?.subject_code}_${params?.year}-${params?.month}.csv`
    return `${meter?.management_association_no}_${meter?.room_no}_${meter?.subject_code}.csv`
  }

  const handleDownload = async () => {
    const { data: dataMeterDetail } = await fetchDataMeterDetail.refetch()
    const a = document.createElement('a')
    const csvFile = new Blob(['\uFEFF' + dataMeterDetail], { type: 'text/csv; charset=utf-8' })
    a.href = URL.createObjectURL(csvFile)
    a.download = handleNameCsv(
      meter?.management_association_no || '',
      meter?.room_no || '',
      params?.year,
      params?.month,
    )
    a.click()
  }

  const columns: ColumnsType<IMeterDetail> = [
    {
      title: `${meterUi.year}`,
      dataIndex: 'year',
      key: 'year',
      width: 80,
      render: (text) => <span style={{ color: '#000' }}>{text ?? ''}</span>,
    },
    {
      title: `${meterUi.month}`,
      dataIndex: 'month',
      key: 'month',
      width: 50,
      render: (text) => <span style={{ color: '#000' }}>{text ?? ''}</span>,
    },
    {
      title: `${meterUi.day}`,
      dataIndex: 'day',
      key: 'day',
      width: 50,
      render: (text) => <span style={{ color: '#000' }}>{text ?? ''}</span>,
    },
    {
      title: `${meterUi.staff}`,
      dataIndex: 'user',
      key: 'user',
      width: 100,
      render: (text) => (
        <div className={styles.text__mail__container}>
          <Tooltip title={text} placement="bottomLeft">
            <span>{text}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: `${meterUi.prevDateReset}`,
      dataIndex: 'reset_date',
      key: 'reset_date',
      width: 130,
    },
    {
      title: `${meterUi.prevTotalUse}`,
      dataIndex: 'reset_value',
      key: 'reset_value',
      width: 160,
    },
    {
      title: `${meterUi.prevValue}`,
      dataIndex: 'prev_value',
      key: 'prev_value',
      width: 120,
    },
    {
      title: `${meterUi.currentValue}`,
      dataIndex: 'value',
      key: 'value',
      width: 110,
    },
    {
      title: `${meterUi.total}`,
      dataIndex: 'total_use',
      key: 'total_use',
      width: 120,
      render: (_, record) => {
        return (
          <div className={styles.detail__filter__alert}>
            <div className={styles.detail__filter__alert__number}>{record.total_use}</div>
            {record.abnormal_rate && (
              <div className={styles.detail__filter__alert__icon}>
                <div>
                  <Alert />
                </div>
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: `${meterUi.image}`,
      dataIndex: 'image_url',
      key: 'image_url',
      width: 90,
      render: (_, record) => (
        <a
          onClick={() => {
            setShowModalImg(true)
            setCurrentMeter(record)
          }}
          style={{ color: '#1890FF' }}
        >
          画像表示
        </a>
      ),
    },
    {
      title: `${meterUi.history}`,
      dataIndex: 'history',
      key: 'history',
      width: 60,
      render: (_, record) => {
        return (
          <span className={clsx([
            {[styles.textDeleted]: record?.deleted_flag},
            {[styles.textModify]: record?.edit_flag && !record?.deleted_flag},
          ])}>
            {record?.deleted_flag ? meterUi.deleted : record?.edit_flag ? meterUi.modify : ''}
          </span>
        )
      },
    },
    {
      title: (
        <p className={clsx(styles.download__meter)} onClick={handleDownload}>
          <IconWrap icon={<DownloadIcon />} />
        </p>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 50,
    },
  ]

  const handleChangeMonth = (value: number) => {
    setParams({ ...params, page: 1, month: value })
  }

  const handleChangeYear: DatePickerProps['onChange'] = (date, dateString) => {
    if (!date) {
      setParams({ ...params, page: 1, month: null, year: null })
      return
    }
    setParams({ ...params, page: 1, year: Number(dateString) })
  }

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  return (
    <ModalCommon
      title={title}
      detailModal={detailModal}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="1190px"
      destroyOnClose={true}
    >
      <div className={clsx(styles.detail__container)}>
        <div className={clsx(styles.detail__filter)}>
          <div className={clsx(styles.detail__filter__month)}>
            <DatePickerCommon
              onChange={handleChangeYear}
              picker="year"
              placeholder={meterUi.year}
              style={{ width: '100px' }}
              value={params.year ? moment(params.year, 'YYYY') : null}
            />
            <Select
              onChange={handleChangeMonth}
              placeholder={meterUi.month}
              className={clsx(styles.month__select)}
              style={{ width: 85 }}
              allowClear
              disabled={!params.year}
              value={params.month}
            >
              {monthArr.map((item: number) => (
                <Select.Option key={item} value={item}>
                  {`${item} ${meterUi.month}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <Table
          rowKey="id"
          loading={isLoading}
          columns={listHistory?.data && listHistory.data.length > 0 ? columns : columns.slice(0, -1)}
          dataSource={listHistory?.data || []}
          pagination={false}
          scroll={{ x: 1080, y: 400 }}
        />
        {listHistory && listHistory?.pagination.total > 10 ? (
          <div className={styles.pagination}>
            <PaginationCommon
              defaultCurrent={1}
              total={listHistory.pagination.total}
              pageSize={listHistory.pagination.per_page}
              current={listHistory.pagination.current_page}
              onChangePagination={onChangePagination}
              pageSizeOptions={['6', '12', '30', '60']}
              showSizeChanger
            />
          </div>
        ) : null}
      </div>
      <div>
        <ModalShowImg
          visible={showModalImg}
          title={meterUi.titleImage}
          onCancel={() => setShowModalImg(false)}
          img={currentMeter?.image}
        />
      </div>
    </ModalCommon>
  )
}
