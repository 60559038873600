export const meterMessage = {
  upLoadSuccess: 'ファイルをアップロードしました 。',
  upLoadFail: 'CSVファイルのインポートに失敗しました。',
  invalidCsv: 'ファイルの形式が正しくありません。',
  invalidCsv2: '※登録可能なファイル形式はCSVのみです。',
  csv5M: 'ファイルの最大サイズは、5MBです。',
  meterTypeSelect: '種類は必須です。',
  subjecCodeRequired: '科目コードは必須です。',
  refetchSuccess: '再発行完了しました。',
  confirmRefetch: 'QRコードを再発行してもよろしいですか？',
  isRequired: 'は必須です。',
  updateSuccess: '更新に成功しました。',
}
