import clsx from 'clsx'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import styles from './index.module.scss'
import headerImg from '../../assets/images/buildingHeader.png'
import { Upload, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useCallback, useState } from 'react'
import { CardBuilding } from './CardBuilding'
import { buildingUi } from '../../until/text/ui'
import { SearchIcon } from '../../assets/icons'
import { DataEmpty } from '../../components/DataEmpty'
import { IBuilding } from '../../models'
import { useGetListBuilding, useImportBuilding } from '../../hooks/query/building'
import debounce from 'lodash.debounce'
import { Loading } from '../../components/Loading'
import { RcFile } from 'antd/es/upload'
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface'
import { buildingMessage } from '../../until/text/messages'
import { PaginationCommon } from '../../components/Pagination'
import { openNotificationWithIcon } from '../../hooks'
import { SIZE_5MB } from '../../until/constant'

export const Building = () => {
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [loadingImg, setLoadingImg] = useState(true)
  const [params, setParams] = useState({
    page_size: 6,
    page: 1,
    text_search: '',
  })
  const { data, isLoading, isFetching } = useGetListBuilding(params)
  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }
  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, text_search: nextValue, page: 1 })), 500),
    [],
  )

  const mutateImportCsv = useImportBuilding()
  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    accept: 'text/csv',
    headers: {
      authorization: 'authorization-text',
    },

    beforeUpload(file: RcFile) {
      const isCSV = file.type === 'text/csv'
      const isLt5M = file.size < SIZE_5MB
      if (!isCSV) {
        openNotificationWithIcon('error', '', `${buildingMessage.invalidCsv}\n${buildingMessage.invalidCsv2}`)
        return isCSV
      }
      if (!isLt5M) {
        openNotificationWithIcon('error', '', buildingMessage.csv5M)
        return isLt5M
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotificationWithIcon('success', '', `${info.file.name} ${buildingMessage.upLoadSuccess}`)
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', '', `${info.file.name} ${buildingMessage.upLoadFail}`)
      }
    },
    customRequest: async (options: RcCustomRequestOptions) => {
      const { file } = options
      const fmData = new FormData()
      fmData.append('file', file)
      try {
        setIsLoadingUpload(true)
        const data = await mutateImportCsv.mutateAsync(fmData)
        openNotificationWithIcon('success', '', data.data)
      } catch (err: any) {
        openNotificationWithIcon('error', '', err.message)
      }
      setIsLoadingUpload(false)
    },
  }

  const handleOnLoadImg = () => {
    setLoadingImg(false)
  }

  return (
    <div className={styles.building}>
      <PageHeader title={buildingUi.buildingList} backgroundImage={headerImg} height={'108px'}>
        <div className={styles.building__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={buildingUi.search}
            className={styles.building__header__search__input}
            onChange={onHandleSearch}
          />
          <Upload {...props}>
            <ButtonCommon icon={<UploadOutlined />} className={styles.building__header__button} type="primary">
              {buildingUi.importCSV}
            </ButtonCommon>
          </Upload>
        </div>
      </PageHeader>
      <Loading isLoading={isLoading || isLoadingUpload || isFetching} />
      {isLoading && loadingImg ? null : (
        <div className={clsx(styles.building__content, 'main-content')}>
          {data?.data && data.data.length > 0 ? (
            <>
              <div className={styles.building__wrap__item}>
                {data.data.map((item: IBuilding, index: number) => {
                  return <CardBuilding building={item} key={index} />
                })}
              </div>
              {data.pagination.total > 6 ? (
                <PaginationCommon
                  defaultCurrent={1}
                  total={data.pagination.total}
                  pageSize={data.pagination.per_page}
                  current={data.pagination.current_page}
                  onChangePagination={onChangePagination}
                  pageSizeOptions={['6', '12', '30', '60']}
                  showSizeChanger
                />
              ) : null}
            </>
          ) : (
            <DataEmpty detail={buildingUi.noResultsMessage} onLoadImg={handleOnLoadImg}>
              <Upload {...props}>
                <ButtonCommon icon={<UploadOutlined />} type="primary">
                  {buildingUi.importCSV}
                </ButtonCommon>
              </Upload>
            </DataEmpty>
          )}
        </div>
      )}
    </div>
  )
}
