import { Col, Row } from 'antd'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { IInvoice } from '../../../models'
import { dataFormat, unit, convertNumberToMoneyFormat, invoiceUi } from '../../../until'
import styles from './index.module.scss'

interface IProp {
  invoice: IInvoice
}

const InfoTextFormat = ({ info }: any) => {
  const component = {
    [unit.price]: (
      <div>
        <Row wrap={false} align='middle' justify='space-between'>
          <Col flex={1} className={styles.text}>{convertNumberToMoneyFormat(info.text) ?? info.default}</Col>
          <Col className={styles.unit}>{unit.price}</Col>
        </Row>
      </div>
    ),
    [unit.kw]: (
      <div>
        <Row wrap={false} align='middle' justify='space-between'>
          <Col flex={1} className={styles.text}>{info.text || '0'}</Col>
          <Col className={styles.unit}>{info.unit}</Col>
        </Row>
      </div>
    ),
    [unit.m3]: (
      <div>
        <Row wrap={false} align='middle' justify='space-between'>
          <Col flex={1} className={styles.text}>{info.text || '0'}</Col>
          <Col className={styles.unit}>m<sup style={{ fontSize: '8px' }}>3</sup></Col>
        </Row>
      </div>
    )
  }

  return component[info.unit]
}

const InvoidDetail = ({ invoice }: IProp) => {
  const convertFormatBill: { [key: string]: any } = useMemo(() => {
    return ({
      customer: {
        customerCode: `${invoice.management_association_no || '_'}-${invoice.building_code || '_'}-${invoice.room_no || '_'}`,
        meterCode: invoice.water_caliber ? `${invoiceUi.meterCode} ${invoice.water_caliber} ${invoiceUi.mm}` : '',
        customer: (
          <span>
            {invoice.building_name} <br />
            {invoice.room_no} <br />
            {invoice.customer_name} {invoiceUi.look}
          </span>
        )
      },
      using: {
        usingTitle: '',
        usageTime: invoice.year_month_prev_scan && invoice.year_month_scan ?
          `${moment(invoice.year_month_prev_scan).format(dataFormat.yyyyMM)}～${moment(invoice.year_month_scan).format(dataFormat.yyyyMM)}` : '',
        dateReading: invoice.date ? moment(invoice.date).format(dataFormat.yyyyMMdd) : '',
        employeeName: invoice.worker || '',
        currentValue: invoice.value || '',
        lastValue: invoice.prev_value || '',
        OldValueInMaterReplaceTime: <InfoTextFormat info={{
          text: invoice.old_total_use,
          unit: Number(invoice.meter_type) == 2 ? unit.kw : unit.m3
        }} />,
        amountUsage: <InfoTextFormat info={{
          text: invoice.total_use,
          unit: Number(invoice.meter_type) == 2 ? unit.kw : unit.m3
        }} />,
        lastAmountUsage: <InfoTextFormat info={{
          text: invoice.prev_total_use,
          unit: Number(invoice.meter_type) == 2 ? unit.kw : unit.m3
        }} />,
        supplyCost: <InfoTextFormat info={{
          text: invoice.supply_cost,
          unit: unit.price,
          default: 0,
        }} />,
        lossCost: <InfoTextFormat info={{
          text: invoice.loss_cost,
          unit: unit.price,
          default: 0,
        }} />,
        priceUsing: <InfoTextFormat info={{
          text: invoice.cost_of_use,
          unit: unit.price,
          default: 0,
        }} />,
        estBillingAmount: <InfoTextFormat info={{
          text: invoice.cost,
          unit: unit.price,
          default: 0,
        }} />,
      },
      plan: {
        planTransferDate: invoice.scheduled_transfer_date ? moment(invoice.scheduled_transfer_date).format(dataFormat.yyyyMMdd) : '',
        scheduledMeterReadingDate: invoice.scheduled_meter_reading_date ? moment(invoice.scheduled_meter_reading_date).format(dataFormat.yyyyMMdd) : ''
      },
      infoBank: {
        billingTitle: '',
        usagePeriod: invoice?.previous_date_meter_reading_ft || '',
        transferDate: invoice.date_of_last_appointment ?
          moment(invoice.date_of_last_appointment).format(dataFormat.yyyyMMdd) : '',
        usageAmount: <InfoTextFormat info={{
          text: invoice.previous_usage,
          unit: Number(invoice.meter_type) == 2 ? unit.kw : unit.m3
        }} />,
        acountDefinition: invoice.account_holder?.toString()?.trimStart() || '***',
        bankName: invoice.bank?.toString()?.trimStart() || '***',
        storeCode: invoice.store_code?.toString()?.trimStart() || '***',
        bankCode: invoice.account_bank_code?.toString()?.trimStart() || '***',
        lastSupplyCost:
          <InfoTextFormat info={{
            text: invoice.previous_water_charge,
            unit: unit.price,
            default: '',
          }} />,
        lastLossCost:
          <InfoTextFormat info={{
            text: invoice.previous_sewerage_charge,
            unit: unit.price,
            default: '',
          }} />,
        lastPriceUsing: <InfoTextFormat info={{
          text: invoice.previous_cost_of_use,
          unit: unit.price,
          default: '',
        }} />,
        lastEstBillingAmount: <InfoTextFormat info={{
          text: invoice.transfer_cost,
          unit: unit.price,
          default: '',
        }} />,
      },
    })
  }, [])

  const Info = useCallback(({ info }: any) => {
    return (
      <>
        {
          Object.keys(info).map((inforKey: string) => {
            const waterOnlyInfoKeys = ['supplyCost', 'lossCost', 'lastLossCost', 'lastSupplyCost', 'meterCode']
            if (Number(invoice.meter_type) != 1 && waterOnlyInfoKeys.includes(inforKey)) return null

            if (!!inforKey && inforKey.includes('Title')) {
              return (
                <div key={inforKey} className={styles.invoice__deatail__block__title}>
                  {invoiceUi[inforKey]}
                </div>
              )
            }

            return (
              <Row align='top' className={styles.invoice__deatail__block__row} key={inforKey}>
                <Col className={styles.invoice__deatail__block__row__title}>
                  {invoiceUi[inforKey]}:
                </Col>
                <Col className={styles.invoice__deatail__block__row__content}>
                  {info[inforKey] || ''}
                </Col>
              </Row>
            )
          })
        }
      </>
    )
  }, [])

  return (
    <div style={{ width: '100%' }} className={styles.invoice__deatail}>
      <div>
        {Object.keys(convertFormatBill).map((item: any, idx: number) => {
          return (
            <div key={idx} className={styles.invoice__deatail__block}>
              <Info info={convertFormatBill[item]} />
            </div>
          )
        })}
        <div className={styles.invoice__deatail__block} key={'note'}>
          <div className={styles.invoice__deatail__block__row__note} style={{ wordBreak: 'keep-all' }}>
            {invoiceUi.billDetailNote}
          </div>
          <div className={styles.invoice__deatail__block__row}>
            {invoiceUi.collectionServiceContractor}
          </div>
          <Row align='top' className={styles.invoice__deatail__block__row} key={'managementCompanyBranchName'}>
            <Col className={styles.invoice__deatail__block__row__title}>
              {invoiceUi.managementCompanyBranchName}:
            </Col>
            <Col className={styles.invoice__deatail__block__row__content}>
              {invoice.company_name}
            </Col>
          </Row>
          <Row align='top' className={styles.invoice__deatail__block__row} key={'contact'}>
            <Col className={styles.invoice__deatail__block__row__title}>
              {invoiceUi.contact}:
            </Col>
            <Col className={styles.invoice__deatail__block__row__content}>
              {invoice.phone_number}
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.invoice__deatail__note}>
        {invoiceUi.detailBillNoteText}
      </div>
    </div>
  )
}

export default InvoidDetail
