export const profileMessage = {
  updateSuccess: '更新に成功しました。',
  placeholderInput: '入力してください。',
  requireCurrentPwd: '現在のパスワードは必須です。',
  newPwd: ' 新しいパスワードは必須です。',
  confirmPassword: ' 新しいパスワード・確認は必須です。',
  ruleNewPassword:
    '新しいパスワードの形式が正しくありません。\n 8文字以上で、英字・数字・記号※を各１文字以上含んでください。\n ※記号 ( @!#"$%&-[;:],./<>?_+*}`(){=~|¥ )',
  confirmPwdNotMatch: '新しいパスワードと新しいパスワード・確認が一致しません',
  mathCurrentPass: '現在のパスワードと新しいパスワードが同じです。',
}
