import { buildingUi } from '../../../until/text/ui'
import styles from './index.module.scss'
import buildingImg from '../../../assets/images/buildingDefault.png'
import { IBuilding } from '../../../models'

interface ICardBuildingProps {
  building: IBuilding
}

export const CardBuilding = ({ building }: ICardBuildingProps) => {
  return (
    <div className={styles.item}>
      <img className={styles.item__img} src={buildingImg} alt="" />
      <div className={styles.item__wrap}>
        <div className={styles.item__left}>
          <div className={styles.item__title}>
            <span className={styles.item__title__text}>{building.name}</span>
            {building.name_katakana && <span className={styles.item__sub__title}>{`(${building.name_katakana})`}</span>}
          </div>
          <hr className={styles.hr} />
          <div className={styles.wrap__iteminfo}>
            <div className={styles.item__info}>
              <span>{buildingUi.building_code}: </span>
              <span>{building.management_association_no}</span>
            </div>
            <div className={styles.item__info}>
              <span>{buildingUi.managerment_association_name}: </span>
              {building.management_association_name && <span>{building.management_association_name}</span>}
            </div>
            <div className={styles.item__info}>
              <span>{buildingUi.postal_code}: </span>
              {building.postal_code && <span>{building.postal_code}</span>}
            </div>
            <div className={styles.item__info}>
              <span>{buildingUi.address_1}: </span>
              {building.address_1 && <span>{building.address_1}</span>}
            </div>
            <div className={styles.item__info}>
              <span>{buildingUi.address_2}: </span>
              {building.address_2 && <span>{building.address_2}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
