export const Other = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9668 0.866701C11.9777 0.866702 11.9888 0.866703 12 0.866703C12.0113 0.866703 12.0223 0.866702 12.0333 0.866701C12.311 0.866675 12.4902 0.866658 12.6469 0.884309C13.945 1.03057 14.9695 2.05507 15.1158 3.3532C15.1334 3.50986 15.1334 3.68903 15.1334 3.9668C15.1334 3.97772 15.1334 3.9888 15.1334 4.00004C15.1334 4.01127 15.1334 4.02235 15.1334 4.03327C15.1334 4.31105 15.1334 4.49021 15.1158 4.64687C14.9695 5.94501 13.945 6.9695 12.6469 7.11576C12.4902 7.13342 12.311 7.1334 12.0333 7.13337C12.0223 7.13337 12.0113 7.13337 12 7.13337C11.9888 7.13337 11.9777 7.13337 11.9668 7.13337C11.689 7.1334 11.5099 7.13342 11.3532 7.11576C10.0551 6.9695 9.03057 5.94501 8.88431 4.64687C8.86666 4.49021 8.86667 4.31104 8.8667 4.03326C8.8667 4.02234 8.8667 4.01127 8.8667 4.00004C8.8667 3.98881 8.8667 3.97774 8.8667 3.96682C8.86667 3.68904 8.86666 3.50987 8.88431 3.3532C9.03057 2.05507 10.0551 1.03057 11.3532 0.884309C11.5099 0.866658 11.689 0.866675 11.9668 0.866701ZM12 1.80004C11.6778 1.80004 11.5553 1.80078 11.4577 1.81177C10.5923 1.90928 9.90928 2.59228 9.81177 3.4577C9.80078 3.55531 9.80004 3.67776 9.80004 4.00004C9.80004 4.32232 9.80078 4.44476 9.81177 4.54237C9.90928 5.40779 10.5923 6.09079 11.4577 6.1883C11.5553 6.1993 11.6778 6.20004 12 6.20004C12.3223 6.20004 12.4448 6.1993 12.5424 6.1883C13.4078 6.09079 14.0908 5.40779 14.1883 4.54237C14.1993 4.44476 14.2 4.32232 14.2 4.00004C14.2 3.67776 14.1993 3.55531 14.1883 3.4577C14.0908 2.59228 13.4078 1.90928 12.5424 1.81177C12.4448 1.80078 12.3223 1.80004 12 1.80004Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9668 8.20008C11.9777 8.20008 11.9888 8.20008 12 8.20008C12.0113 8.20008 12.0223 8.20008 12.0333 8.20008C12.311 8.20005 12.4902 8.20003 12.6469 8.21768C13.945 8.36395 14.9695 9.38844 15.1158 10.6866C15.1334 10.8432 15.1334 11.0224 15.1334 11.3002C15.1334 11.3111 15.1334 11.3222 15.1334 11.3334C15.1334 11.3446 15.1334 11.3557 15.1334 11.3666C15.1334 11.6444 15.1334 11.8236 15.1158 11.9802C14.9695 13.2784 13.945 14.3029 12.6469 14.4491C12.4902 14.4668 12.311 14.4668 12.0333 14.4667C12.0223 14.4667 12.0113 14.4667 12 14.4667C11.9888 14.4667 11.9777 14.4667 11.9668 14.4667C11.689 14.4668 11.5099 14.4668 11.3532 14.4491C10.0551 14.3029 9.03057 13.2784 8.88431 11.9802C8.86666 11.8236 8.86667 11.6444 8.8667 11.3666C8.8667 11.3557 8.8667 11.3446 8.8667 11.3334C8.8667 11.3222 8.8667 11.3111 8.8667 11.3002C8.86667 11.0224 8.86666 10.8432 8.88431 10.6866C9.03057 9.38844 10.0551 8.36395 11.3532 8.21768C11.5099 8.20003 11.689 8.20005 11.9668 8.20008ZM12 9.13341C11.6778 9.13341 11.5553 9.13415 11.4577 9.14515C10.5923 9.24266 9.90928 9.92565 9.81177 10.7911C9.80078 10.8887 9.80004 11.0111 9.80004 11.3334C9.80004 11.6557 9.80078 11.7781 9.81177 11.8757C9.90928 12.7412 10.5923 13.4242 11.4577 13.5217C11.5553 13.5327 11.6778 13.5334 12 13.5334C12.3223 13.5334 12.4448 13.5327 12.5424 13.5217C13.4078 13.4242 14.0908 12.7412 14.1883 11.8757C14.1993 11.7781 14.2 11.6557 14.2 11.3334C14.2 11.0111 14.1993 10.8887 14.1883 10.7911C14.0908 9.92565 13.4078 9.24266 12.5424 9.14515C12.4448 9.13415 12.3223 9.13341 12 9.13341Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.96682 0.866701C3.97774 0.866702 3.98881 0.866703 4.00004 0.866703C4.01127 0.866703 4.02234 0.866702 4.03326 0.866701C4.31104 0.866675 4.49021 0.866658 4.64687 0.884309C5.94501 1.03057 6.9695 2.05507 7.11576 3.3532C7.13342 3.50986 7.1334 3.68903 7.13337 3.9668C7.13337 3.97772 7.13337 3.9888 7.13337 4.00004C7.13337 4.01127 7.13337 4.02235 7.13337 4.03327C7.1334 4.31105 7.13342 4.49021 7.11576 4.64687C6.9695 5.94501 5.94501 6.9695 4.64687 7.11576C4.49021 7.13342 4.31105 7.1334 4.03327 7.13337C4.02235 7.13337 4.01127 7.13337 4.00004 7.13337C3.9888 7.13337 3.97772 7.13337 3.9668 7.13337C3.68903 7.1334 3.50986 7.13342 3.3532 7.11576C2.05507 6.9695 1.03057 5.94501 0.884309 4.64687C0.866658 4.49021 0.866675 4.31104 0.866701 4.03326C0.866702 4.02234 0.866703 4.01127 0.866703 4.00004C0.866703 3.98881 0.866702 3.97774 0.866701 3.96682C0.866675 3.68904 0.866658 3.50987 0.884309 3.3532C1.03057 2.05507 2.05507 1.03057 3.3532 0.884309C3.50987 0.866658 3.68903 0.866675 3.96682 0.866701ZM4.00004 1.80004C3.67776 1.80004 3.55531 1.80078 3.4577 1.81177C2.59228 1.90928 1.90928 2.59228 1.81177 3.4577C1.80078 3.55531 1.80004 3.67776 1.80004 4.00004C1.80004 4.32232 1.80078 4.44476 1.81177 4.54237C1.90928 5.40779 2.59228 6.09079 3.4577 6.1883C3.55531 6.1993 3.67776 6.20004 4.00004 6.20004C4.32232 6.20004 4.44476 6.1993 4.54237 6.1883C5.40779 6.09079 6.09079 5.40779 6.1883 4.54237C6.1993 4.44476 6.20004 4.32232 6.20004 4.00004C6.20004 3.67776 6.1993 3.55531 6.1883 3.4577C6.09079 2.59228 5.40779 1.90928 4.54237 1.81177C4.44476 1.80078 4.32232 1.80004 4.00004 1.80004Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.96682 8.20008C3.97774 8.20008 3.98881 8.20008 4.00004 8.20008C4.01127 8.20008 4.02234 8.20008 4.03326 8.20008C4.31104 8.20005 4.49021 8.20003 4.64687 8.21768C5.94501 8.36395 6.9695 9.38844 7.11576 10.6866C7.13342 10.8432 7.1334 11.0224 7.13337 11.3002C7.13337 11.3111 7.13337 11.3222 7.13337 11.3334C7.13337 11.3446 7.13337 11.3557 7.13337 11.3666C7.1334 11.6444 7.13342 11.8236 7.11576 11.9802C6.9695 13.2784 5.94501 14.3029 4.64687 14.4491C4.49021 14.4668 4.31105 14.4668 4.03327 14.4667C4.02235 14.4667 4.01127 14.4667 4.00004 14.4667C3.9888 14.4667 3.97772 14.4667 3.9668 14.4667C3.68903 14.4668 3.50986 14.4668 3.3532 14.4491C2.05507 14.3029 1.03057 13.2784 0.884309 11.9802C0.866658 11.8236 0.866675 11.6444 0.866701 11.3666C0.866702 11.3557 0.866703 11.3446 0.866703 11.3334C0.866703 11.3222 0.866702 11.3111 0.866701 11.3002C0.866675 11.0224 0.866658 10.8432 0.884309 10.6866C1.03057 9.38844 2.05507 8.36395 3.3532 8.21768C3.50987 8.20003 3.68903 8.20005 3.96682 8.20008ZM4.00004 9.13341C3.67776 9.13341 3.55531 9.13415 3.4577 9.14515C2.59228 9.24266 1.90928 9.92565 1.81177 10.7911C1.80078 10.8887 1.80004 11.0111 1.80004 11.3334C1.80004 11.6557 1.80078 11.7781 1.81177 11.8757C1.90928 12.7412 2.59228 13.4242 3.4577 13.5217C3.55531 13.5327 3.67776 13.5334 4.00004 13.5334C4.32232 13.5334 4.44476 13.5327 4.54237 13.5217C5.40779 13.4242 6.09079 12.7412 6.1883 11.8757C6.1993 11.7781 6.20004 11.6557 6.20004 11.3334C6.20004 11.0111 6.1993 10.8887 6.1883 10.7911C6.09079 9.92565 5.40779 9.24266 4.54237 9.14515C4.44476 9.13415 4.32232 9.13341 4.00004 9.13341Z" fill="white"/>
    </svg>
  )
}
