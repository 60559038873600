export const contractorsUi: {[key: string]: string} = {
  subject_code: '科目コード',
  management_association_no: '管理組合No',
  building_no: '棟No',
  building_placeholder: '建物',
  building_name: '建物名',
  room_no: '部屋No',
  customer_name: '顧客名',
  water_caliber: '水道口径',
  tariff_code: ' 料金表コード',
  daily_division: '日割区分',
  scheduled_transfer_date: '振替予定日',
  scheduled_meter_reading_date: '検針予定日',
  previous_usage: '前回使用量',
  previous_date_meter_reading: '前回検針基準年月',
  date_of_last_appointment: '前回口振日',
  account_holder: '口座名義',
  bank: '金融機関',
  store_code: '店舗コード',
  account_bank_code: '口座番号',
  previous_water_charge: '前回水道料金',
  previous_sewerage_charge: '前回下水道料金',
  transfer_cost: '振替金額',
  company_name: '会社名',
  phone_number: '電話番号',
  qrCode: 'QRコード',
  printQRCode: 'QRコード印刷',
  showHistory: ' 検針履歴',
  list: 'メーター一覧',
  search: '検索',
  importCSV: 'CSV取り込み',
  printQR: 'QRコード印刷',
  createQR: 'QRコード発行',
  viewQR: 'QRコード',
  download: 'ダウンロード',
  checkAll: '全て選択',
  noResultsMessage: '以下のボタンから契約者を追加してください。',
  detail: 'Detail',
  viewDetail: '詳細表示',
  worker: '作業者',
  type: '区分',
  previous_date_meter_reading_ft: '料金口座振替済み通知書のご使用期間',
  previous_scan_date: '前回検針日',
  first_move_in_flg: '初回入居FLG',
  flg_first: '初回入居',
  flg_no_first: '初回入居以外',
}
