import { IFormula } from '../../models'
import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Price {
  importCSV = async (data: any) => {
    const url = `/${EndPoint.price}/imports`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  getListPrices = async (params: any) => {
    const url = `${EndPoint.price}/list`
    const res = await axiosClient.get(url, { params })

    return res.data?.data || EXAMPLE_RESPONSE
  }

  createPriceManager = async (data: any) => {
    const url = `/${EndPoint.price}`
    const res = await axiosClient.post(url, data.formData)
    return res.data
  }

  getListFormulaPrice = async (params: {
    page: number
    page_size: number
    search: string
    meter_type: number | null
  }) => {
    const url = `${EndPoint.formula}`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }

  getDetailFormulaPrice = async (params: { id: string }) => {
    const url = `${EndPoint.formula}/${params.id}`
    const res = await axiosClient.get(url)
    return res.data?.data
  }

  createFormulaPrice = async (data: IFormula) => {
    const url = `/${EndPoint.formula}`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  updateFormulaPrice = async (data: IFormula, id: string) => {
    const url = `/${EndPoint.formula}/${id}`
    const res = await axiosClient.put(url, data)
    return res.data
  }

  getListTariffCode = async (params: { type: number }) => {
    const url = `${EndPoint.contractor}/${EndPoint.tariff_code_list}`
    const res = await axiosClient.get(url, { params })
    return res.data?.data
  }
  checkTariffCode = async (params: { tariff_code: string; meter_type: number }) => {
    const url = `/${EndPoint.formula}/tariff-code`
    const res = await axiosClient.get(url, { params })
    return res.data?.data
  }
}

export const PriceManagerApi = new Price()
