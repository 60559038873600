interface IProps {
  fill?: string
}

export const OperationIcon = ({fill='white'}: IProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 1.33331L14 4.66665V13.9953C14 14.366 13.7033 14.6666 13.338 14.6666H2.662C2.29667 14.6666 2 14.3633 2 14.0053V1.99465C2 1.62931 2.298 1.33331 2.666 1.33331H10.6667ZM10 2.66665H3.33333V13.3333H12.6667V5.33331H10V2.66665ZM8.66667 5.99998V8.66665H10.6667V9.99998H7.33333V5.99998H8.66667Z"
        fill={fill}
      />
    </svg>
  )
}
