export const Notification = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
      <g filter="url(#filter0_d_442_4962)">
        <path
          d="M20 15H22V17H2V15H4V8C4 3.58172 7.58172 0 12 0C16.4183 0 20 3.58172 20 8V15ZM18 15V8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8V15H18ZM9 19H15V21H9V19Z"
          fill="#E6F7FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_442_4962"
          x="-2"
          y="0"
          width="28"
          height="29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_442_4962" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_442_4962" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
