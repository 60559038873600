import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Traffic {
  getAllTraffic = async (params?: any) => {
    const url = `/${EndPoint.traffic}/list`
    const res = await axiosClient.get(url, { params })
    return res.data.data
  }
  downloadTraffic = async (params?: any) => {
    const url = `/${EndPoint.traffic}/export-traffic`
    const res = await axiosClient.get(url, { params })
    return res.data
  }
}

export const TrafficApi = new Traffic()
