export const priceManagerUI = {
  water: '水道',
  electric: '電気',
  gas: 'ガス',
  priceManager: '料金表',
  search: '検索',
  addPriceRule: '新規作成',
  volume: '水量（m³)',
  volumePlaceholder: '入力してください。',
  cleanWater: '上水金額',
  sewageWater: '下水金額',
  cost: '金額',
  edit: '編集',
  delete: '削除',
  tariffCode: ' 料金表コード',
  daily_division: '日割区分',
  nomal: '通常',
  monthProrated: '1ヵ月日割',
  month2Prorated: '2ヵ月日割',
  type: '区分',
  noResultsMessage: '以下のボタンから料金表を追加してください。',
  noResultsMessageFormula: '以下のボタンから請求金額算出を追加してください。',
  titleDefault: ' 新規作成',
  kinds: '種類',
  amountUsage: '使用量',
  waterCost: '金額（上水金額 + 下水金額)',
  waterCostPlaceholder: '上水金額と下水金額を入力してください。',
  cancel: 'キャンセル',
  save: '確定',
  update: '更新',
  add: '追加',
  confirm: '確認',
  textConfirm: '通常検針を1ヶ月日割と2ヶ月日割にコピーします。よろしいですか？',
  listPriceCodePlaceholder: '料金表コードを選択してください。',
  listPriceName: '料金表名',
  listPriceNamePlaceholder: '料金表名を入力してください。',
  listPriceRate: '消費税率 (%)',
  listPriceRatePlaceholder: '消費税率を入力してください。',
  listPriceFractionSetting: '端数設定',
  listPriceCopy: '通常検針コピー',
  addPriceFormula: '※この行に値を入れると新規行が追加できます',
  charactersPrice: '※演算記号について',
  charactersPrice1: '@：使用量が代入されます。',
  charactersPrice2: '¥J：上水基本料が代入されます。',
  charactersPrice3: '加算：+   減算：-    乗算：*    除算：/',
  charactersPrice4: '¥S：消費税率が代入されます。',
  charactersPrice5: '¥G：下水基本料が代入されます。',
  charactersPrice6: '¥D：使用日数が代入されます。',
  charactersPrice7: '¥：基本料金が代入されます。',
  normalMeter: '通常検針',
  usageRange: '使用量範囲指定',
  usageRangeError: '範囲指定が正しくありません。',
  usageFromToError: '使用量の範囲指定が他の行と重複しています。',
  useCalculationFormula: '計算式を利用する',
  usePriceList: '料金表を使用する',
  calculationBilling: '請求金額算出',
  calculationFormula: '計算式',
  calculationFormulaSewage: '計算式（上水）',
  calculationFormulaSupply: '計算式（下水）',
  roundDown: '切り捨て',
  roundTo5: '四捨五入',
  roundUp: '切り上げ',
  roundTo6: '五捨六入',
  under1: '1円未満',
  under10: '10円未満',
  under100: '100円未満',
  under1000: '1000円未満',
}
