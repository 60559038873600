import QRCode from 'qrcode.react'
import { QR_CODE } from '../../until/constant'
import { useEffect } from 'react'

interface IQenerateUrlQrProps {
  id: string
  handleResetId: () => void
  // eslint-disable-next-line no-unused-vars
  uploadQrCode: (file: any) => void
}

export const GenerateUrlQr = ({ id, handleResetId, uploadQrCode }: IQenerateUrlQrProps) => {
  useEffect(() => {
    if (id) {
      const canvas = document.getElementById(`canvas${id}`) as HTMLCanvasElement
      canvas?.toBlob((blob: any) => {
        const file = new File([blob], `qrcode-staff${id}.png`, { type: 'image/png' })
        uploadQrCode(file)
      }, 'image/png')
      handleResetId()
    }
  }, [id])

  return <QRCode level={QR_CODE.LEVEL} id={`canvas${id}`} value={id} renderAs="canvas" style={{ display: 'none' }} />
}
