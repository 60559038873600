import trafficHeader from '../../assets/images/trafficHeader.png'
import styles from './index.module.scss'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { DownloadIcon, SearchIcon } from '../../assets/icons'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import { Checkbox, DatePicker, DatePickerProps, Space, Table } from 'antd'
import { CommonUi, trafficUi } from '../../until/text/ui'
import { PaginationCommon } from '../../components/Pagination'
import { DataEmpty } from '../../components/DataEmpty'
import { useDownloadTraffic, useGetAllTraffic } from '../../hooks/query/traffic'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { Loading } from '../../components/Loading'

export const Traffic = () => {
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
    text_search: '',
    month: Number(moment(new Date()).format('M')),
    year: Number(moment(new Date()).format('YYYY')),
    is_empty: 0,
  })
  const { data: listTraffic, isLoading: isLoadingListTraffic } = useGetAllTraffic(params)
  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, text_search: nextValue, page: 1 })), 500),
    [],
  )
  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }
  const onChangePagination = (page: number, page_size: number) => {
    setParams({ ...params, page, page_size })
  }

  const handleChangeDate: DatePickerProps['onChange'] = (date) => {
    if (!date) {
      setParams({ ...params, month: Number(moment(new Date()).format('M')), year: Number(moment(new Date()).format('YYYY')) })
      return
    }
    const splitDate = moment(date).format('YYYY/M').split('/')
    setParams({ ...params, month: splitDate[1], year: splitDate[0] })
  }
  const fetchDataTraffic = useDownloadTraffic(params)
  const handleDownload = async () => {
    const { data: dataMeasurement } = await fetchDataTraffic.refetch()
    const a = document.createElement('a')
    const csvFile = new Blob(['\uFEFF' + dataMeasurement], { type: 'text/csv; charset=utf-8' })
    a.href = URL.createObjectURL(csvFile)
    a.download = `${(moment(new Date()).format('YYYY-MM-DD'))}_通信量計測_${params.month}月分.csv`
    a.click()
    a.remove()
  }
  const columns = [
    {
      title: trafficUi.title,
      dataIndex: 'username',
      width: '50%',
      render: (text: any) => (
        <div className={styles.traffic__text}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: trafficUi.date,
      width: '20%',
      render: (record: any) => (
        <span>{`${record.start_date}～${record.end_date}`}</span>
      )
    },
    {
      title: trafficUi.traffic,
      dataIndex: 'total_data',
      width: '30%',
    },
  ]

  return (
    <div className={styles.traffic}>
      <PageHeader title={trafficUi.header} backgroundImage={trafficHeader} height={'108px'}>
        <div className={styles.traffic__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={CommonUi.placeholderInput}
            className={styles.traffic__header__search__input}
            onChange={onHandleSearch}
          />
          <ButtonCommon
            disabled={listTraffic && listTraffic.data.length === 0 ? true : false}
            onClick={handleDownload}
            style={{ width: 190 }}
            icon={<IconWrap icon={<DownloadIcon />} size={20} />}
            className={styles.traffic__header__btn}
            type="primary"
          >
            {trafficUi.CSV_download}
          </ButtonCommon>
        </div>
      </PageHeader>
      <div className={styles.traffic__filter}>
        <div className={clsx(styles.group__filter, 'select__group')}>
          <DatePicker
            allowClear={false}
            defaultValue={moment(new Date())}
            format={'YYYY/MM'}
            onChange={handleChangeDate}
            picker="month"
            style={{ width: '100%', maxWidth: '196px', background: '#fff' }}
          />
          <Space size={24} wrap={true}>
            <Checkbox
              onChange={(value) =>
                value.target.checked ? setParams({ ...params, page: 1, is_empty: 1 }) : setParams({ ...params, page: 1, is_empty: 0 })
              }
            >
              {trafficUi.users_with_0_usage}
            </Checkbox>
          </Space>
        </div>
      </div>
      {isLoadingListTraffic ? null : (
        <>
          {listTraffic?.data && listTraffic?.data?.length > 0 ? (
            <div className={styles.traffic__table}>
              <Table
                rowKey="user_id"
                columns={columns}
                dataSource={listTraffic?.data}
                pagination={false}
                scroll={{ x: 1280 }}
              />
              {listTraffic?.pagination && listTraffic?.pagination?.total > 10 && (
                <div className={styles.traffic__table__pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={listTraffic?.pagination.total}
                    current={listTraffic?.pagination.current_page}
                    pageSize={listTraffic?.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              )}
            </div>
          ) : (
            <DataEmpty>
              <></>
            </DataEmpty>
          )}
        </>
      )}
      <Loading isLoading={isLoadingListTraffic} />
    </div>
  )
}
