import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { PrintIcon } from '../../../assets/icons'
import { ButtonCommon, IconWrap, ModalCommon } from '../../../components'
import { IInvoice } from '../../../models'
import { invoiceUi } from '../../../until/text/ui'
import InvoidDetail from '../InvoidDetail'
import styles from './index.module.scss'

interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  id?: string
  isLoading?: boolean
  invoice: IInvoice
  invoiceId: string
}

export const ManagerModal = ({
  title,
  visible,
  invoice,
  invoiceId,
  onOk,
  onCancel,
}: IProps) => {
  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page { size: A4 Portrait }
      @media print {
        body { 
          -webkit-print-color-adjust: exact; 
        }
      }
    `
  })

  return (
    <>
      <ModalCommon
        destroyOnClose={true}
        title={invoice ? invoiceUi.invoiceDetails : title}
        detailModal={`#${invoiceId}`}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width="850px"
      >
        <div >
          <div id="canvas" className={styles.modal__body}>
            <div className={styles.invoice__deatail} ref={componentRef}>
              <InvoidDetail invoice={invoice}  />
            </div>
          </div>
          <div className={styles.modal__footer}>
            <ButtonCommon
              className={styles.printqrcode__btn}
              icon={<IconWrap icon={<PrintIcon />} size={18} />}
              type="primary"
              onClick={handlePrint}
            >
              {invoiceUi.printInvoice}
            </ButtonCommon>
          </div>
        </div>
      </ModalCommon>
    </>
  )
}
