import clsx from 'clsx'
import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './index.module.scss'
import { requestForToken } from '../../firebase'
const AuthPage = () => {
  requestForToken()
  return (
    <div className={clsx(styles.wrapper__auth)}>
      <div className={clsx(styles.auth__page)}>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthPage
