import { DangerIcon } from '../../../assets/icons'
import { ButtonCommon, ModalCommon } from '../../../components'
import { meterMessage, meterUi, staffUi } from '../../../until'
import styles from './index.module.scss'

interface IProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
  loading?: boolean
}

export const ConfirmRefetchQRCodeModal = ({ visible, onCancel, onOk, loading }: IProps) => {
  return (
    <ModalCommon
      title={meterUi.refetchQRCode}
      visible={visible}
      onCancel={onCancel}
      width="852px"
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.form__content}>
          <DangerIcon />
          <p>{meterMessage.confirmRefetch}</p>
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {staffUi.cancel}
          </ButtonCommon>
          <ButtonCommon type="primary" onClick={onOk} loading={loading}>
            {meterUi.refetch}
          </ButtonCommon>
        </div>
      </div>
    </ModalCommon>
  )
}
