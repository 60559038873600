import { formulaItem } from '../../models'
import { checkValidateRangeProp } from '../../pages/PriceManager/CreateFormula'
import { regex } from '../regex'
import { settingMessage } from '../text/messages'
import { priceManagerUI } from '../text/ui'
import _ from 'lodash'

export const convertNumberToMoneyFormat = (value: number | string) => {
  if (!value && value !== 0) return value

  return value.toString().replace(regex.formatMoney, ',')
}

export const checkValidateRange = (value: checkValidateRangeProp[]) => {
  const errorItems = []
  const initValueWithIndex = value?.map((el: checkValidateRangeProp, index: number) => {
    return {
      ...el,
      quantity_level_from:
        el?.quantity_level_from || el?.quantity_level_from === 0 ? Number(el.quantity_level_from) : null,
      quantity_level_to: el?.quantity_level_to || el?.quantity_level_to === 0 ? Number(el.quantity_level_to) : null,
      idIndex: index,
    }
  })
  const sorterOfNullValues = (a: { quantity_level_from: number | null }, b: { quantity_level_from: number | null }) => {
    return assignValueOfNullAtEnd(a.quantity_level_from) - assignValueOfNullAtEnd(b.quantity_level_from)
  }
  const assignValueOfNullAtEnd = (val: number | null) => {
    if (val === null) {
      return Infinity
    } else {
      return val
    }
  }
  const finalSorter = (arr: any) => arr?.sort(sorterOfNullValues)
  const valueSorted = finalSorter(initValueWithIndex)
  for (let i = 0; i < valueSorted?.length; i++) {
    if (
      (!valueSorted[i]?.quantity_level_from && !(valueSorted[i]?.quantity_level_from === 0)) ||
      (!valueSorted[i]?.quantity_level_to && !(valueSorted[i]?.quantity_level_to === 0))
    ) {
      errorItems?.push({ ...valueSorted[i], error: `${priceManagerUI.usageRange}${settingMessage.isRequired}` })
    } else {
      if (valueSorted[i].quantity_level_from > valueSorted[i].quantity_level_to) {
        errorItems?.push({ ...valueSorted[i], error: priceManagerUI.usageRangeError })
      } else {
        if (
          (valueSorted[i + 1]?.quantity_level_from || valueSorted[i + 1]?.quantity_level_from === 0) &&
          valueSorted[i + 1]?.quantity_level_to
        ) {
          if (valueSorted[i + 1]?.quantity_level_from <= valueSorted[i].quantity_level_to) {
            errorItems?.push({ ...valueSorted[i + 1], error: priceManagerUI.usageFromToError })
          }
        }
      }
    }
  }
  return errorItems
}

export const checkValidateFormula = (data: formulaItem[], type: number) => {
  if (type === 1) {
    return _.findIndex(data, (item: formulaItem) => {
      const supplyValid = item?.formula_water_supply && validateMath(item?.formula_water_supply, regex.regexMathWater, regex.replaceMathWater)
      const sewageValid = item?.formula_water_sewage && validateMath(item?.formula_water_sewage, regex.regexMathWater, regex.replaceMathWater)
      if (supplyValid || sewageValid) {
        if (item?.formula_water_supply && item?.formula_water_sewage) {
          return supplyValid && sewageValid ? false : true
        } else return false
      } else {
        return true
      }
    }) >= 0
  } else {
    return _.findIndex(data, (item: formulaItem) => {
      const basicValid = item?.formula_basic && validateMath(item?.formula_basic, regex.regexMathGasElc, regex.replaceMathGasElc)
      return basicValid ? false : true
    }) >= 0
  }
}

export const validateMath = (math: string, regex: RegExp, replaceType: RegExp) => {
  if (regex.test(math)) {
    try {
      return isFinite(eval(math.replace(/\b0+(\d)/g, '$1').replaceAll(replaceType, () => Math.random().toString()))) ? true : false
    } catch {
      return false
    }
  } else {
    return false
  }
}
