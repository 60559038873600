import { Form, FormInstance, Input, Table } from 'antd'
import { priceManagerUI, settingMessage, checkValidateRange, priceMessage } from '../../../until'
import styles from '../CreateFormula/index.module.scss'
import clsx from 'clsx'
import { CloseIcon, PlusIcon } from '../../../assets/icons'
import { ButtonCommon, IconWrap } from '../../../components'
import { InputNumberCommon } from '../../../components/Input/Number'
import { tabMainProps } from '../CreateFormula'
import { max } from 'lodash'
import { formulaItem } from '../../../models'
interface IProps {
  statusKey: string
  type: number
  form: FormInstance
  tabMainValid: tabMainProps
}

const maxLengthRecordFormula = process.env.REACT_APP_MAX_RECORD_FORMULA || 500

export const FormListTable = ({ statusKey, type, form, tabMainValid }: IProps) => {
  const columnsTypeWater = ({ remove }: any, fields: any) => {
    return [
      {
        title: 'No',
        width: '10%',
        render: (r: any, item: any) => {
          return (
            <div id={`formula_items${statusKey}_${item.name}`}>
              <Form.Item
                {...fields[item.name]}
                name={[item.name, 'formula_no']}
              >
                <Input bordered={false} readOnly={true} />
              </Form.Item>
            </div>
          )
        },
      },
      {
        title: priceManagerUI.usageRange,
        width: '30%',
        render: (_: any, item: any) => {
          return (
            <div id={`formula_items${statusKey}_${item.name}`}>
              <Form.Item
                {...fields[item.name]}
                key={fields.key}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  () => ({
                    validator() {
                      const check = form.getFieldsValue()[`formula_items${statusKey}`]
                      const valueValid = checkValidateRange(check)
                      const error = valueValid.filter((el: any) => el.idIndex === item.name)
                      if (error.length) {
                        return Promise.reject(error[0]?.error)
                      } else {
                        return Promise.resolve()
                      }
                    },
                  }),
                ]}
              >
                <Input.Group compact className={clsx(styles.createFormula__scope)}>
                  <Form.Item
                    {...fields[item.name]}
                    key={fields.key}
                    name={[item.name, 'quantity_level_from']}
                    validateTrigger="onSubmit"
                    noStyle
                  >
                    <InputNumberCommon
                      min={0}
                      max={999999.99}
                      maxLength={9}
                      precision={2}
                      step={0.01}
                      className={styles['form__item--half']}
                      placeholder={priceManagerUI.volumePlaceholder}
                    />
                  </Form.Item>
                  <span className={clsx(styles.createFormula__scope__space)}>~</span>
                  <Form.Item
                    {...fields[item.name]}
                    name={[item.name, 'quantity_level_to']}
                    validateTrigger="onSubmit"
                    noStyle
                  >
                    <InputNumberCommon
                      min={0}
                      max={999999.99}
                      maxLength={9}
                      precision={2}
                      step={0.01}
                      className={styles['form__item--half']}
                      placeholder={priceManagerUI.volumePlaceholder}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </div>
          )
        },
      },
      {
        title: priceManagerUI.calculationFormulaSewage,
        width: '28%',
        render: (_: any, item: any) => {
          return (
            <div>
              <Form.Item
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value &&
                        !form.getFieldsValue()[`formula_items${statusKey}`]?.[item.name]?.formula_water_sewage
                      ) {
                        return Promise.reject(priceMessage.errorWaterAndSupplyEmpty)
                      } else return Promise.resolve()
                    },
                  }),
                ]}
                {...fields[item.name]}
                key={fields.key}
                name={[item.name, 'formula_water_supply']}
                validateTrigger="onSubmit"
              >
                <Input maxLength={1000} placeholder={priceManagerUI.volumePlaceholder} />
              </Form.Item>
            </div>
          )
        },
      },
      {
        title: priceManagerUI.calculationFormulaSupply,
        width: '28%',
        render: (_: any, item: any) => {
          return (
            <div>
              <Form.Item
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value &&
                        !form.getFieldsValue()[`formula_items${statusKey}`]?.[item.name]?.formula_water_supply
                      ) {
                        return Promise.reject()
                      } else return Promise.resolve()
                    },
                  }),
                ]}
                {...fields[item.name]}
                key={fields.key}
                name={[item.name, 'formula_water_sewage']}
                validateTrigger="onSubmit"
              >
                <Input maxLength={1000} placeholder={priceManagerUI.volumePlaceholder} />
              </Form.Item>
            </div>
          )
        },
      },
      {
        width: '4%',
        render: (_: any, item: any) => {
          return (
            <>
              <div className={clsx(styles.createFormula__groupBtn)}>
                <div
                  onClick={() => {
                    remove(item.name)
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            </>
          )
        },
      },
    ]
  }
  const columnsTypeGasEle = ({ remove }: any, fields: any) => {
    return [
      {
        title: 'No',
        width: '10%',
        render: (r: any, item: any) => {
          return (
            <div id={`formula_items${statusKey}_${item.name}`}>
              <Form.Item
                {...fields[item.name]}
                name={[item.name, 'formula_no']}
              >
                <Input bordered={false} readOnly={true} />
              </Form.Item>
            </div>
          )
        },
      },
      {
        title: priceManagerUI.usageRange,
        width: '30%',
        render: (_: any, item: any) => {
          return (
            <div id={`formula_items${statusKey}_${item.name}`}>
              <Form.Item
                {...fields[item.name]}
                key={fields.key}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  () => ({
                    validator() {
                      const check = form.getFieldsValue()[`formula_items${statusKey}`]
                      const valueValid = checkValidateRange(check)
                      const error = valueValid.filter((el: any) => el.idIndex === item.name)
                      if (error.length) {
                        return Promise.reject(error[0]?.error)
                      } else {
                        return Promise.resolve()
                      }
                    },
                  }),
                ]}
              >
                <Input.Group compact className={clsx(styles.createFormula__scope)}>
                  <Form.Item
                    {...fields[item.name]}
                    key={fields.key}
                    name={[item.name, 'quantity_level_from']}
                    validateTrigger="onSubmit"
                    noStyle
                  >
                    <InputNumberCommon
                      min={0}
                      max={999999.99}
                      maxLength={9}
                      precision={2}
                      step={0.01}
                      className={styles['form__item--half']}
                      placeholder={priceManagerUI.volumePlaceholder}
                    />
                  </Form.Item>
                  <span className={clsx(styles.createFormula__scope__space)}>~</span>
                  <Form.Item
                    {...fields[item.name]}
                    name={[item.name, 'quantity_level_to']}
                    validateTrigger="onSubmit"
                    noStyle
                  >
                    <InputNumberCommon
                      min={0}
                      max={999999.99}
                      maxLength={9}
                      precision={2}
                      step={0.01}
                      className={styles['form__item--half']}
                      placeholder={priceManagerUI.volumePlaceholder}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </div>
          )
        },
      },
      {
        title: priceManagerUI.calculationFormula,
        width: '55%',
        render: (_: any, item: any) => {
          return (
            <div>
              <Form.Item
                {...fields[item.name]}
                key={fields.key}
                name={[item.name, 'formula_basic']}
                validateTrigger="onSubmit"
                rules={[
                  {
                    required: true,
                    message: `${priceManagerUI.calculationFormula}${settingMessage.isRequired}`,
                  },
                ]}
              >
                <Input maxLength={1000} placeholder={priceManagerUI.volumePlaceholder} />
              </Form.Item>
            </div>
          )
        },
      },
      {
        width: '5%',
        render: (_: any, item: any) => {
          return (
            <>
              <div className={clsx(styles.createFormula__groupBtn)}>
                <div
                  onClick={() => {
                    remove(item.name)
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            </>
          )
        },
      },
    ]
  }
  return (
    <Form.List name={`formula_items${statusKey}`}>
      {(fields, { add, remove }) => {
        return (
          <>
            <Table
              dataSource={fields}
              columns={type === 1 ? columnsTypeWater({ remove }, fields) : columnsTypeGasEle({ remove }, fields)}
              pagination={false}
            />
            <div className={styles.createFormula__errorTabMain}>
              {tabMainValid.defaultItems && `${priceManagerUI.normalMeter}${settingMessage.isRequired}`}
            </div>
            <div className={styles.createFormula__addColumn}>
              <ButtonCommon
                disabled={fields.length >= Number(maxLengthRecordFormula)}
                icon={<IconWrap icon={<PlusIcon />} />}
                onClick={() => {
                  const maxIndex = max(form.getFieldValue(`formula_items${statusKey}`)?.map((el: formulaItem) => el.formula_no)) || 0
                  add({ formula_no: Number(maxIndex) + 1 })
                }}
                type="ghost"
              >
                {priceManagerUI.add}
              </ButtonCommon>
            </div>
          </>
        )
      }}
    </Form.List>
  )
}
