import { useQuery } from 'react-query'
import { ISetting } from '../../../models'
import { SettingApi } from '../../../apis'

export const PriceServerStateKeys = {
  setting: 'setting',
}

export const useGetSetting = () =>
  useQuery<ISetting, Error>([PriceServerStateKeys.setting], () => SettingApi.getSetting())
