import { Modal } from 'antd'
import clsx from 'clsx'
import styles from './index.module.scss'
interface IProps {
  title: string
  visible: boolean
  onOk?: () => void
  onCancel?: () => void
  children: React.ReactNode
  width?: string
  destroyOnClose?: boolean
  detailModal?: string
  className?: string
  style?: object
}

export const ModalCommon = ({
  title,
  detailModal,
  visible,
  onOk,
  onCancel,
  children,
  width,
  destroyOnClose,
  className,
  style,
}: IProps) => {
  return (
    <Modal
      title={
        <>
          <span
            style={{
              marginRight: title === '' ? '0px' : '24px',
            }}
          >
            {title}
          </span>
          <span style={{ color: '#135200' }}>{detailModal}</span>
        </>
      }
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      closable={true}
      maskClosable={false}
      footer={null}
      className={clsx(styles.modal, className)}
      width={width}
      destroyOnClose={destroyOnClose}
      style={style}
      centered={true}
    >
      {children}
    </Modal>
  )
}
