import { useQuery } from 'react-query'
import { ExtraordinaryApi } from '../../../apis/extraordinary'
import { IResExtraordinary, IResMeasurementExtraordinary } from '../../../models'

export const ServerStateKeysExtraordinary = {
  Extraordinary: 'abnormal',
  ExtraordinaryList: 'abnormal-list',
  MeterTypes: 'meter-types',
}

export const useGetAllMeasurementExtraordinary = (params?: any) =>
  useQuery<IResMeasurementExtraordinary, Error>([ServerStateKeysExtraordinary.ExtraordinaryList, params], () =>
    ExtraordinaryApi.getAllMeasurementExtraordinary(params),
  )
export const useGetAllExtraordinary = (params?: any) =>
  useQuery<IResExtraordinary, Error>([ServerStateKeysExtraordinary.Extraordinary, params], () =>
    ExtraordinaryApi.getAllExtraordinary(params),
  )
export const useDownloadMeasurementExtraordinary = () => {
  return useQuery<any, Error>(
    [ServerStateKeysExtraordinary.Extraordinary],
    () => ExtraordinaryApi.downloadMeasurementExtraordinary(),
    {
      enabled: false,
    },
  )
}
