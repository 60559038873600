import React, { useCallback } from 'react'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../../components'
import meterType from '../../../assets/images/meterType.png'
import { PlusIcon, SearchIcon, TypeIcon } from '../../../assets/icons'
import { managerUI, meterUi } from '../../../until/text/ui'
import styles from './index.module.scss'
import clsx from 'clsx'
import { useState } from 'react'
import { Select, Table } from 'antd'
import { PaginationCommon } from '../../../components/Pagination'
import { DataEmpty } from '../../../components/DataEmpty'
import { MeterTypeModal } from '../MeterTypeModal'
import { useGetMeterType } from '../../../hooks/query/meter'
import { Loading } from '../../../components/Loading'
import debounce from 'lodash.debounce'
import { ColumnsType } from 'antd/es/table'
import { IMeterType } from '../../../models'

export const MeterTypeManagement = () => {
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
    search: '',
    filter_by_type: ''
  })
  const [selected, setSelected] = useState<any>(null)
  const typeMeter: { name: string; value: number }[] = [
    { name: meterUi.water, value: 1 },
    { name: meterUi.electric, value: 2 },
    { name: meterUi.gas, value: 3 },
  ]
  const { data, isLoading, isFetching } = useGetMeterType(params)
  const columns: ColumnsType<IMeterType> = [
    {
      title: `${managerUI.IDcontract}`,
      dataIndex: 'contract_code',
      key: 'contract_code',
      width: '30%',
    },
    {
      title: `${meterUi.type}`,
      dataIndex: 'type',
      key: 'type',
      width: '30%',
      render: ((type: any) => typeMeter.find((el) => el.value == type)?.name)
    },
    {
      title: `${meterUi.subjectCode}`,
      dataIndex: 'subject_code',
      key: 'subject_code',
      width: '30%',
      render: ((subject: string) => {
        return <div style={{maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{subject}</div>
      })
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <ButtonCommon
          onClick={() => {
            setSelected(record)
            setVisible(true)
          }}
          type='link'
          style={{ width: '40px', color: '#1890FF' }}
        >
          {managerUI.edit}
        </ButtonCommon>
      )
    }
  ]

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, search: nextValue, page: 1 })), 500),
    [],
  )

  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const handleChangeSelectType = (value: number) => {
    setParams({ ...params, page: 1, filter_by_type: value })
  }

  return (
    <div className={clsx(styles.meterType)}>
      <PageHeader title={meterUi.meterTypeTitle} backgroundImage={meterType} height='108px'>
        <div className={styles.meterType__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            onChange={onHandleSearch}
            placeholder={meterUi.search}
            className={styles.meterType__header__search__input}
          />
          <ButtonCommon
            icon={<IconWrap icon={<PlusIcon />} size={14} />}
            className={styles.addMeterType__btn}
            type="primary"
            onClick={() => setVisible(true)}
          >
            {meterUi.addMeterType}
          </ButtonCommon>
        </div>
      </PageHeader>
      <Loading isLoading={isLoading || isFetching} />
      <div className={clsx(styles.page__content__filter)}>
        <div
          className={clsx(
            styles.group__filter,
            {
              [styles.hidden]: data?.data.length === 0 || isLoading || isFetching,
            },
            'select__group',
          )}
        >
          <Select
            style={{ width: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter, styles.stafficon)} />
                {meterUi.type}
              </>
            }
            onChange={handleChangeSelectType}
            value={params.type}
          >
            {typeMeter.map((item: { name: string; value: number }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      {!isLoading && (
        <div className={clsx(styles.page__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={data.data}
                pagination={false}
                scroll={{ x: 700 }}
              />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty detail={meterUi.noMeterTypeResultsMessage} className={styles.empty__results}>
              <ButtonCommon
                className={styles.btn__add}
                type="primary"
                icon={<IconWrap icon={<PlusIcon />} color="#fff" />}
                onClick={() => setVisible(true)}
              >
                {meterUi.addMeterType}
              </ButtonCommon>
            </DataEmpty>
          )}
        </div>
      )}
      {visible && (
        <MeterTypeModal
          visible={visible}
          onCancel={() => {
            setVisible(false)
            setSelected(null)
          }}
          onOk={() => {
            setVisible(false)
            setSelected(null)
          }}
          meterType={selected}
        />
      )}
    </div>
  )
}