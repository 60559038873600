export const ContractIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5181_38755)">
        <path
          d="M14 4.50465L12.6667 5.83798V2.66665H6.66668V5.99998H3.33335V13.3333H12.6667V11.4953L14 10.162V14.0053C13.9998 14.1808 13.93 14.349 13.8059 14.473C13.6818 14.597 13.5135 14.6666 13.338 14.6666H2.66202C2.57447 14.666 2.4879 14.6482 2.40725 14.6141C2.3266 14.58 2.25345 14.5304 2.19197 14.4681C2.1305 14.4057 2.08191 14.3319 2.04897 14.2508C2.01604 14.1697 1.9994 14.0829 2.00002 13.9953V5.33331L6.00202 1.33331H13.332C13.7 1.33331 14 1.63665 14 1.99465V4.50465ZM14.5187 5.87131L15.4613 6.81465L10.276 12L9.33202 11.9986L9.33335 11.0573L14.5187 5.87198V5.87131Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5181_38755">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
