import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MeterApi } from '../../../apis'
import { IResBuildingMeter, IResMeterDetail, IResMeterType } from '../../../models'

export const ServerStateKeys = {
  building_meter: 'buiding_meter',
  history_meter: 'history_meter',
  meter_type: 'meter_type',
  meter: 'meter',
  meter_print: 'meter_print'
}

export const useGetBuildingMeter = (params?: any) => {
  return useQuery<IResBuildingMeter, Error>([ServerStateKeys.building_meter, params], () =>
    MeterApi.getListBuildingMeter(params),
  )
}

export const useImportMeter = () => {
  const cache = useQueryClient()
  return useMutation(MeterApi.importCSV, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.building_meter)
    },
  })
}

export const useUploadQrCode = () => {
  return useMutation(MeterApi.uploadQrCode)
}

export const useGetHistoryMeter = (params?: any) => {
  return useQuery<IResMeterDetail, Error>(
    [ServerStateKeys.history_meter, params],
    () => MeterApi.getHistoryMeter(params),
    {
      enabled: !!params.id,
    },
  )
}

export const useDownloadDetailMeter = (params?: any) => {
  return useQuery<any, Error>([ServerStateKeys.building_meter, params], () => MeterApi.downloadHistoryMeter(params), {
    enabled: false,
  })
}

export const useGetImageMeter = (url: string) => {
  return useQuery<any, Error>([ServerStateKeys.building_meter, url], () => MeterApi.getImageMeter(url), {
    enabled: !!url,
  })
}

export const usePostMeterType = () => {
  const cache = useQueryClient()
  return useMutation(MeterApi.postMeterType, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.meter_type)
    },
  })
}

export const useGetMeterType = (params?: any) => {
  return useQuery<IResMeterType, Error>([ServerStateKeys.meter_type, params], () =>
    MeterApi.getMeterType(params),
  )
}

export const usePutMeterType = () => {
  const cache = useQueryClient()
  return useMutation(MeterApi.putMeterType, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.meter_type)
    },
  })
}

export const usePutMeterLatest = () => {
  const cache = useQueryClient()
  return useMutation(MeterApi.putMeterLatest, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.meter)
    },
  })
}

export const usePrintMeter = () => {
  const cache = useQueryClient()
  return useMutation(MeterApi.printQRCode, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.meter_print)
    },
  })
}
