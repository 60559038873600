import { ISettingParam } from '../../models/setting'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Setting {
  getSetting = async () => {
    const url = `${EndPoint.setting}`
    const res = await axiosClient.get(url)

    return res.data?.data
  }

  updateSetting = async (data: ISettingParam) => {
    const url = `/${EndPoint.setting}`
    const res = await axiosClient.post(url, data)
    return res.data
  }
}

export const SettingApi = new Setting()
