interface IProps {
  fill?: string
}

export const EditIcon = ({fill = '#434343'}: IProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.9 3.858L14.142 8.101L4.242 18H0V13.757L9.9 3.857V3.858ZM11.314 2.444L13.435 0.322C13.6225 0.134528 13.8768 0.029213 14.142 0.029213C14.4072 0.029213 14.6615 0.134528 14.849 0.322L17.678 3.151C17.8655 3.33853 17.9708 3.59284 17.9708 3.858C17.9708 4.12316 17.8655 4.37747 17.678 4.565L15.556 6.686L11.314 2.444Z"
        fill={fill}
      />
    </svg>
  )
}
