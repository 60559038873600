import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

interface ISortProps {
  title: string
  value?: 'asc' | 'desc' | null
  onSort: () => void
}

export const SortTitle = ({ title, onSort, value }: ISortProps) => {
  return (
    <div onClick={onSort} className={styles.sort}>
      <div className={styles.sort__title}>{title}</div>
      <div className={styles.sort__icons}>
        <CaretUpOutlined style={{ fontSize: 11, margin: '-1px', color: value === 'asc' ? '#348B7B' : '#bfbfbf' }} />
        <CaretDownOutlined style={{ fontSize: 11, margin: '-1px', color: value === 'desc' ? '#348B7B' : '#bfbfbf' }} />
      </div>
    </div>
  )
}
