export const invoiceUi: { [key: string]: string } = {
  invoiceManager: '請求書',
  type: '種類',
  date: '日付',
  selectAll: '全て選択',
  printInvoice: '印刷',
  invoiceNo: '請求書番号',
  building: '建物',
  buildingNo: '棟No',
  startTime: '開始日',
  endTime: '終了日',
  quantity: '利用量',
  invoiceDetail: '請求書',
  water: 'water',
  electric: 'electric',
  gas: 'gas',
  dateTime: 'yyyy/mm/dd',
  rank: 'Rank',
  endNumber: '今回検針日',
  cost: '金額',
  time: 'Time',
  client: 'Client',
  roomNo: '部屋No',
  invoiceDetails: '請求書',
  price: '単価',
  customerName: 'お客様番号',
  buildingName: 'マンション名',
  buildingNameKatakana: '建物名カナ',
  buildingNostalCode: '郵便番号',
  buildingAddress1: '住所1',
  buildingAddress2: '住所2',
  employee: '作業者',
  previousGuidelineValue: '前回指針値',
  currentGuidelineValue: '今回指針値',
  gasFee: 'ガス料金',
  taxGasCharge: '内ガス料金分消費税',
  basicCharge: '基本料金',
  total: '金額',
  billDetailNote:
    `上記のとおり貴口座より振り替えました。
  金融機関名等を確認なされたい方は、
  下記までご連絡をお願いいたします。`,
  collectionServiceContractor: '料金等徴収業務受託者',
  managementCompanyBranchName: '管理会社　支店名',
  contact: 'お問い合わせ先',
  detailBillNoteText: '　本通知書により集金員が収納することはございません。',

  customerCode: 'お客様番号',
  meterCode: '口径',
  customer: 'お客様名',

  usingTitle: '今回使用量',
  usageTime: 'ご使用期間',
  dateReading: '検針日',
  employeeName: '検針員',
  currentValue: '今回メーター検針',
  lastValue: '前回メーター検針',
  OldValueInMaterReplaceTime: 'メーター取替時の旧メーター量',
  amountUsage: '今回使用量',
  lastAmountUsage: '前回使用量',
  supplyCost: '上水金額',
  lossCost: '下水金額',
  priceUsing: '料金',
  estBillingAmount: '請求予定額  (消費税含む)',
  includeTax: '消費税含む',
  planTransferDate: '振替予定日',
  scheduledMeterReadingDate: '検針予定日',

  billingTitle: '料金口座振替済通知書',
  usagePeriod: 'ご使用期間',
  transferDate: '振替日',
  usageAmount: '使用量',
  acountDefinition: '口座名義',
  bankName: '金融機関名',
  storeCode: '店舗コード',
  bankCode: '口座番号',
  searchType: '区分',
  searchTypePlaceholder: 'すべて',
  lastSupplyCost: '前回水道料金',
  lastLossCost: '前回下水道料金',
  lastPriceUsing: '料金',
  lastEstBillingAmount: '請求予定額  (消費税含む)',
  mm: 'ミリ',
  look: '様',
  previousScanDate: '前回検針日',
  history: '履歴',
  deleted: '削除',
  modify: '修正'
}
