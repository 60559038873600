import { useMutation, useQuery, useQueryClient } from 'react-query'
import { StaffApi } from '../../../apis'
import { IResStaff, IStaff } from '../../../models'

export const ServerStateKeys = {
  Staff: 'staff/getAll',
  contracts: 'staff/contracts',
  Suggestions: 'suggestions',
  useGetStaffSuggest: 'StaffSuggest',
  staff_print: 'staff/print-qr-code'
}

export const useGetStaff = (params?: any) =>
  useQuery<IResStaff, Error>([ServerStateKeys.Staff, params], () => StaffApi.getAllStaff(params))

export const useGetStaffSuggest = (params?: any) =>
  useQuery<IStaff[], Error>([ServerStateKeys.useGetStaffSuggest, params], () => StaffApi.getSuggestStaff(params))

export const useGetAllContracts = () => useQuery(ServerStateKeys.contracts, () => StaffApi.getAllContract())

export const useCreateStaff = () => {
  const cache = useQueryClient()
  return useMutation(StaffApi.createStaff, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.Staff)
    },
  })
}

export const useDeleteStaff = () => {
  const cache = useQueryClient()
  return useMutation(StaffApi.deleteStaff, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.Staff)
    },
  })
}

export const useUpdateStaff = () => {
  const cache = useQueryClient()
  return useMutation(StaffApi.updateStaff, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.Staff)
    },
  })
}

export const useGetSuggestionsBuilding = () => {
  return useQuery(ServerStateKeys.Suggestions, StaffApi.getSuggestionsBuilding)
}

export const useGetSuggestionsBuildingAll = () => {
  return useQuery(ServerStateKeys.Suggestions, StaffApi.getSuggestionsBuildingAll)
}

export const useForgotPassword = () => {
  return useMutation(StaffApi.forgotPassword)
}

export const usePrintStaff = () => {
  const cache = useQueryClient()
  return useMutation(StaffApi.printQRCode, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.staff_print)
    },
  })
}
