export const profileUi = {
  inputFullName: '名前を入力してください。',
  inputUserName: 'ユーザー名は必須です。',
  profile: 'プロフィール',
  name: '名前',
  nameKatakana: '名前カナ',
  username: 'ユーザー名',
  password: 'パスワード',
  email: 'メールアドレス',
  phoneNumber: '電話番号',
  address_1: '住所1',
  address_2: '住所2',
  logout: 'ログアウト',
  changePassword: 'パスワード変更',
  currentPassword: '現在のパスワード',
  newPassword: '新しいパスワード',
  confirmPassword: '新しいパスワード・確認',
  cancel: 'キャンセル',
  savePassword: 'パスワード変更',
  edit: '編集',
  save: '更新',
  postal_code: '郵便番号',
  placeholderPostalCode: '郵便番号を入力してください。',
  placeholderAddress2: '住所を入力してください。',
  placeholderAddress1: '住所を入力してください。',
  placeholderPhoneNumber: '電話番号を入力してください。',
  placeholderEmail: 'メールアドレスを入力してください。',
  placeholderUsername: 'ユーザー名を入力してください。',
  placeholderKatakana: '名前カナを入力してください。',
  placeholderFullName: '名前を入力してください。',
  invalidPostalCode: '郵便番号の形式が正しくありません。',
}
