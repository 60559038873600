import React from 'react'

export const UserType = () => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6402 9.36803C10.3876 8.7696 10.021 8.22603 9.56078 7.76758C9.10198 7.30782 8.55849 6.94125 7.96033 6.68812C7.95497 6.68544 7.94962 6.6841 7.94426 6.68142C8.77863 6.07874 9.32104 5.09705 9.32104 3.98946C9.32104 2.15464 7.83444 0.66803 5.99962 0.66803C4.16479 0.66803 2.67819 2.15464 2.67819 3.98946C2.67819 5.09705 3.2206 6.07874 4.05497 6.68276C4.04961 6.68544 4.04426 6.68678 4.0389 6.68946C3.4389 6.94258 2.90051 7.30553 2.43845 7.76892C1.97869 8.22772 1.61212 8.77121 1.35899 9.36937C1.11031 9.95498 0.976198 10.5828 0.963901 11.2189C0.963543 11.2332 0.96605 11.2474 0.971275 11.2607C0.976499 11.2741 0.984334 11.2862 0.994319 11.2964C1.0043 11.3067 1.01624 11.3148 1.02941 11.3203C1.04259 11.3259 1.05675 11.3287 1.07104 11.3287H1.87462C1.93354 11.3287 1.98042 11.2819 1.98176 11.2243C2.00854 10.1904 2.42372 9.22205 3.15765 8.48812C3.91703 7.72875 4.92551 7.31089 5.99962 7.31089C7.07372 7.31089 8.0822 7.72875 8.84158 8.48812C9.57551 9.22205 9.99069 10.1904 10.0175 11.2243C10.0188 11.2832 10.0657 11.3287 10.1246 11.3287H10.9282C10.9425 11.3287 10.9566 11.3259 10.9698 11.3203C10.983 11.3148 10.9949 11.3067 11.0049 11.2964C11.0149 11.2862 11.0227 11.2741 11.028 11.2607C11.0332 11.2474 11.0357 11.2332 11.0353 11.2189C11.0219 10.5787 10.8893 9.95598 10.6402 9.36803ZM5.99962 6.29303C5.38488 6.29303 4.80631 6.0533 4.37104 5.61803C3.93578 5.18276 3.69604 4.60419 3.69604 3.98946C3.69604 3.37473 3.93578 2.79615 4.37104 2.36089C4.80631 1.92562 5.38488 1.68589 5.99962 1.68589C6.61435 1.68589 7.19292 1.92562 7.62819 2.36089C8.06345 2.79615 8.30319 3.37473 8.30319 3.98946C8.30319 4.60419 8.06345 5.18276 7.62819 5.61803C7.19292 6.0533 6.61435 6.29303 5.99962 6.29303Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  )
}
