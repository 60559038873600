import { Form } from 'antd'
import { ButtonCommon, InputCommon, RequireTag } from '../../../../components'
import { InputNumberCommon } from '../../../../components/Input/Number'
import { regex } from '../../../../until'
import { MAX_11_DEGIT_VALUE, MAX_5_DEGIT_FLOAT_VALUE } from '../../../../until/constant'
import { priceMessage } from '../../../../until/text/messages'
import { managerUI } from '../../../../until/text/ui'
import { priceManagerUI } from '../../../../until/text/ui'
import styles from './index.module.scss'
import { openNotificationWithIcon, useCreatePriceManager } from '../../../../hooks'

interface IProps {
  onCancel: () => void
  id?: string
  isLoading?: boolean
}

const FormLabel = {
  quantityLevel: 'quantity_level',
  cost: 'cost',
  tariffCode: 'tariff_code'
}

export const GasForm = ({
  onCancel,
  isLoading,
}: IProps) => {
  const mutateAddPriceManager = useCreatePriceManager()
  const [form] = Form.useForm()
  const handleOnFinish = async (value: any) => {
    try {
      const formData = new FormData()

      for (const key in value) {
        formData.append(key, value[key])
      }

      await mutateAddPriceManager.mutateAsync({ formData: formData })
      openNotificationWithIcon('success', '', `${priceMessage.createSuccess}`)

      onCancel()
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        form.setFields(arrError)
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
    }
  }

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleOnFinish}>
        <div className={styles.form__container}>
          <div className={styles.form__content}>
            <Form.Item
              label={
                <>
                  {priceManagerUI.tariffCode}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputTariffCode,
                },
              ]}
              name={FormLabel.tariffCode}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputCommon
                maxLength={45}
                onBlur={() => {
                  form.setFieldValue(FormLabel.tariffCode, form.getFieldValue(FormLabel.tariffCode))
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.amountUsage}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputQuantityLevel,
                },
                {
                  pattern: regex.validateNumber,
                  message: <span style={{ whiteSpace: 'break-spaces' }}>{priceMessage.inputinputQuantityLevelValid}</span>,
                },
              ]}
              name={FormLabel.quantityLevel}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                max={MAX_5_DEGIT_FLOAT_VALUE}
                maxLength={8}
                precision={2}
                step={0.1}
                onBlur={() => {
                  form.setFieldValue(FormLabel.quantityLevel, form.getFieldValue(FormLabel.quantityLevel))
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  {priceManagerUI.cost}
                  <RequireTag />
                </>
              }
              rules={[
                {
                  required: true,
                  message: priceMessage.inputCost,
                },
              ]}
              name={FormLabel.cost}
              className={styles['form__item--full']}
              validateTrigger="onSubmit"
              initialValue={''}
            >
              <InputNumberCommon
                max={MAX_11_DEGIT_VALUE}
                maxLength={11}
                onBlur={() => {
                  form.setFieldValue(FormLabel.cost, form.getFieldValue(FormLabel.cost))
                }}
                placeholder={priceManagerUI.volumePlaceholder}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.form__footer}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {managerUI.cancel}
          </ButtonCommon>
          <ButtonCommon
            type="primary"
            htmlType="submit"
            loading={isLoading || mutateAddPriceManager.isLoading}
            style={{ letterSpacing: '-2px' }}
          >
            {managerUI.save}
          </ButtonCommon>
        </div>
      </Form>
    </>
  )
}
