import React, { useCallback, useRef, useState } from 'react'
import staffHeaderImg from '../../assets/images/staffHeader.png'
import { ButtonCommon, IconWrap, InputCommon, PageHeader } from '../../components'
import { PlusIcon, PrintIcon, SearchIcon, UserType } from '../../assets/icons'
import styles from './index.module.scss'
import { CardStaff } from './CardStaff'
import { StaffModal } from './Modal'
import { Checkbox, Col, Row, Select } from 'antd'
import { staffUi } from '../../until/text/ui'
import { GenerateUrlQr, openNotificationWithIcon, useCreateStaff, useGetStaff, usePrintStaff } from '../../hooks/'
import { IStaff } from '../../models'
import { Loading } from '../../components/Loading'
import { useDeleteStaff } from '../../hooks'
import { DataEmpty } from '../../components/DataEmpty'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import debounce from 'lodash.debounce'
import clsx from 'clsx'
import QRCode from 'qrcode.react'
import { QR_CODE } from '../../until/constant'
import { useReactToPrint } from 'react-to-print'
import { PaginationCommon } from '../../components/Pagination'
import { staffMessage } from '../../until/text/messages'

export const Staff = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    page_size: 12,
    search: '',
    filter_by_user_type: '0',
  })

  const [id, setId] = useState('')
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const { data, isLoading, isFetching } = useGetStaff(params)
  const [showListQrcode, setShowListQrcode] = useState(false)
  const [listErr, setListErr] = useState<any>()

  const mutateAddStaff = useCreateStaff()
  const mutateDeleteStaff = useDeleteStaff()
  const { mutateAsync: mutatePrintAsync, isLoading: isPrintLoading } = usePrintStaff()
  const dataSubmit = useRef<any>(null)
  const componentRef = useRef(null)
  const handleUploadQrcode = async () => {
    try {
      const formData = new FormData()
      //append qr code
      if (dataSubmit.current.building_ids) {
        for (let i = 0; i < dataSubmit.current.building_ids.length; i++) {
          formData.append('building_ids[]', dataSubmit.current.building_ids[i])
        }
      }
      delete dataSubmit.current.building_ids
      for (const key in dataSubmit.current) {
        formData.append(key, dataSubmit.current[key])
      }
      await mutateAddStaff.mutateAsync(formData)
      setVisible(false)
      setLoading(false)
      setCheckAll(false)
      setIndeterminate(false)
      setCheckedList([])
      openNotificationWithIcon('success', '', staffMessage.createSuccess)
      setListErr([])
    } catch (error: any) {
      if (error.errors) {
        const entries = Object.entries(error.errors)
        const arrError = entries.map((item: any) => ({ name: item[0], errors: item[1] }))
        setListErr(arrError)
      } else if (error?.message === 'ユーザー名は既に使用されています。') {
        setListErr([{ name: 'username', errors: [error?.message] }])
      } else {
        openNotificationWithIcon('error', '', error?.message)
      }
      setLoading(false)
    }
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, search: nextValue, page: 1 })), 500),
    [],
  )

  const onCheckAllReset = () => {
    if (!checkAll && !checkedList?.length) return
    setCheckedList([])
    setIndeterminate(false)
    setCheckAll(false)
  }

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onCheckAllReset()
    debouncedSearch(e.target.value)
  }

  const handleOnFinish = async (value: any) => {
    setLoading(true)
    setId(value.email)
    dataSubmit.current = value
  }

  const handleDeleteStaff = async (id?: number) => {
    try {
      if (id) {
        await mutateDeleteStaff.mutateAsync(id)
        openNotificationWithIcon('success', '', staffMessage.deleteSuccess)
        onCheckAllReset()
      }
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  const onChangePagination = (page: number, page_size: number) => {
    onCheckAllReset()
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length > 0) {
      setIndeterminate(true)
    }
    if (checkedValues.length === data?.data.filter((el) => el.user_type.toString() !== '2').length) {
      setIndeterminate(false)
      setCheckAll(true)
    }
    if (checkedValues.length === 0) {
      setIndeterminate(false)
      setCheckAll(false)
    }
    setCheckedList(checkedValues)
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page { size: A4 Portrait }
      @media print {
        body { 
          -webkit-print-color-adjust: exact; 
        }
      }
    `
  })

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const listStaff = data?.data
      .filter((item: IStaff) => item.user_type.toString() !== '2')
      .map((item: IStaff) => item.qr_code)
    setCheckedList(e.target.checked ? (listStaff as string[]) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const handleConvertQrCode = async () => {
    await setShowListQrcode(true)
    await mutatePrintAsync()
    handlePrint()
  }

  const handleFindEmailByQrcode = (qr_code: string) => {
    const staff = data?.data.find((item: IStaff) => item.qr_code === qr_code)
    return staff?.email
  }

  const filterRole: any = [
    {
      role: '0',
      name: `${staffUi.all}`,
    },
    {
      role: '2',
      name: `${staffUi.administrator}`,
    },
    {
      role: '3',
      name: `${staffUi.worker}`,
    },
  ]
  const filterUserType = (value: any) => {
    setParams({ ...params, filter_by_user_type: value, page: 1 })
    onCheckAllReset()
  }
  const userType = localStorage.getItem('user_type')
  
  return (
    <div className={styles.page__container}>
      <PageHeader height="108px" title={staffUi.staffList} backgroundImage={staffHeaderImg}>
        <div className={styles.control__wrap}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            className={styles.search}
            placeholder={staffUi.search}
            onChange={onHandleSearch}
          />
          <ButtonCommon
            icon={<IconWrap icon={<PlusIcon />} size={14} />}
            className={styles.addstaff__btn}
            type="primary"
            onClick={() => setVisible(true)}
          >
            {staffUi.addStaff}
          </ButtonCommon>
        </div>
      </PageHeader>
      <Loading isLoading={isLoading || isFetching} />

      <div className={clsx(styles.page__content__filter)}>
        {userType === '1' ? (
          <div className={clsx(styles.page__content__filter__userType)}>
            <span style={{ fontWeight: 400, fontSize: '14px' }}>{staffUi.userType}</span>
            <Select
              autoFocus={false}
              maxTagCount="responsive"
              allowClear
              optionLabelProp="label"
              style={{ minWidth: 156 }}
              placeholder={
                <>
                  <IconWrap color="#8C8C8C" icon={<UserType />} className={clsx(styles.icon__filter)} />
                  {staffUi.userType}
                </>
              }
              onChange={filterUserType}
            >
              {filterRole.map((item: any) => (
                <Select.Option
                  key={item.role}
                  value={item.role}
                  label={
                    <React.Fragment>
                      <IconWrap color="#8C8C8C" icon={<UserType />} className={clsx(styles.icon__filter)} />
                      {`${item.name}`}
                    </React.Fragment>
                  }
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : null}

        <div className={clsx(styles.page__content__filter__printQR)}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={data?.data.length === 0 || params.filter_by_user_type === '2'}
          >
            {staffUi.checkAll}
          </Checkbox>
          <ButtonCommon
            disabled={checkedList.length === 0}
            className={styles.printqrcode__btn}
            icon={<IconWrap icon={<PrintIcon />} size={18} />}
            type="ghost"
            onClick={handleConvertQrCode}
            loading={isPrintLoading}
          >
            {staffUi.printQRCode}
          </ButtonCommon>
        </div>
      </div>
      {!isLoading && (
        <div className={styles.cardstaff__wrap}>
          {Array.isArray(data?.data) && data?.data && data?.data.length > 0 ? (
            <Checkbox.Group style={{ width: '100%' }} onChange={onChangeCheckBox} value={checkedList}>
              <div className={styles.cardstaff__container}>
                <Row justify="start" wrap gutter={[24, 24]}>
                  {data?.data?.map((item: IStaff) => (
                    <Col key={item.id} lg={6} md={8} sm={12} xs={24}>
                      <CardStaff
                        handleResetCheck={onCheckAllReset}
                        isChecked={checkedList.includes(item.qr_code)}
                        checkBox={item.user_type.toString() === '2' ? <></> : <Checkbox value={item.qr_code} />}
                        key={item.id}
                        data={item}
                        handleDeleteStaff={handleDeleteStaff}
                        isLoadingDelete={mutateDeleteStaff.isLoading}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Checkbox.Group>
          ) : (
            <DataEmpty detail={staffUi.noResultsMessage}>
              <ButtonCommon
                className={styles.btn__add}
                type="primary"
                icon={<IconWrap icon={<PlusIcon />} color="#fff" />}
                onClick={() => setVisible(true)}
              >
                {staffUi.addStaff}
              </ButtonCommon>
            </DataEmpty>
          )}
          {data?.total && data?.total > 12 ? (
            <div className={styles.pagination}>
              <PaginationCommon
                current={data?.current_page}
                total={data?.total}
                pageSize={data?.per_page}
                onChangePagination={onChangePagination}
                showSizeChanger
                defaultCurrent={1}
                pageSizeOptions={['12', '24', '60', '120']}
              />
            </div>
          ) : null}
        </div>
      )}
      <GenerateUrlQr id={id} handleResetId={() => setId('')} uploadQrCode={handleUploadQrcode} />
      {showListQrcode && (
        <div className={styles.list__qrcode} ref={componentRef}>
          {checkedList &&
            checkedList.map((item: any) => (
              <div key={item}>
                <QRCode level={QR_CODE.LEVEL} size={QR_CODE.SIZE} id={`canvas${item}`} value={item} renderAs="canvas" />
                <p>{handleFindEmailByQrcode(item)}</p>
              </div>
            ))}
        </div>
      )}
      {visible && (
        <StaffModal
          // resetCheck={handleResetCheck}
          listErr={listErr}
          handleOnFinish={(value: string) => handleOnFinish(value)}
          title={staffUi.addStaff}
          visible={visible}
          onOk={() => {
            setVisible(false)
            setListErr([])
          }}
          onCancel={() => {
            setVisible(false)
            setListErr([])
          }}
          isLoading={loading}
        />
      )}
    </div>
  )
}
