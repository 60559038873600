import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class History {
  getHistory = async (params: any) => {
    const url = `/${EndPoint.history}/list`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }
}

export const HistoryApi = new History()