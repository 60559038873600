import clsx from 'clsx'
import styles from './index.module.scss'
import { useState } from 'react'
import { Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import Navigation from './Navigation'
import User from './UserInfo'
import { useNavigate } from 'react-router-dom'
import { CommonUi } from '../../until/text/ui'

const HeaderLayout = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }
  const role = localStorage.getItem('role') || ''
  return (
    <>
      <nav className={clsx(styles.header)}>
        <div className={clsx(styles.header__logo)} onClick={() => (role === '9' ? navigate('/admin') : navigate('/'))}>
          {CommonUi.logo}
        </div>
        <div className={clsx([styles.header__menu, 'menu--selected'])} style={role === '9' ? {width: '320px'} : {}}>
          <Navigation />
        </div>
        <User />

        <Button className={styles['header__mobi-btn']} onClick={showDrawer}>
          <MenuOutlined />
        </Button>
        <Drawer className={styles.drawer} placement="right" closable={false} onClose={onClose} visible={visible}>
          <div className={styles['header__menu-bar']}>
            <Navigation onClickMenu={onClose} mode="vertical" />
          </div>
        </Drawer>
      </nav>
    </>
  )
}

export default HeaderLayout
