export const SummaryIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.00001H2.66667V13H0V7.00001ZM9.33333 4.33334H12V13H9.33333V4.33334ZM4.66667 0.333344H7.33333V13H4.66667V0.333344Z"
        fill="#E6F7FF"
      />
    </svg>
  )
}
