import { useMutation, useQuery, useQueryClient } from 'react-query'
import { UserApi } from '../../../apis'
import { IUserInfor } from '../../../models/user'
import { ServerStateKeysSummary } from '../summary'
import { ServerStateKeys as ManagerStateKeys } from '../manager'
export const ServerStateKeys = {
  profile: 'profile',
}

export const useLogin = () => {
  const cache = useQueryClient()
  return useMutation(UserApi.login, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.profile)
      cache.invalidateQueries(ServerStateKeysSummary.Summary)
    },
  })
}

export const useLogout = () => {
  const cache = useQueryClient()
  return useMutation(UserApi.logout, {
    onSuccess: () => {
      cache.clear()
    },
  })
}

export const useGetProfile = () => {
  return useQuery<IUserInfor, Error>(ServerStateKeys.profile, UserApi.getProfile)
}

export const usePostProfile = () => {
  const cache = useQueryClient()
  return useMutation(UserApi.updateProfile, {
    onSuccess: () => {
      cache.invalidateQueries(ServerStateKeys.profile)
    },
  })
}

export const useGenToken = () => {
  const cache = useQueryClient()
  return useMutation(UserApi.genToken, {
    onSuccess: () => {
      cache.invalidateQueries(ManagerStateKeys.Staff)
    },
  })
}
