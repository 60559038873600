import styles from './index.module.scss'
import { useState, useEffect } from 'react'
import { ButtonCommon, ConfirmDelete, IconWrap } from '../../../components'
import { Table } from 'antd'
import { openNotificationWithIcon } from '../../../hooks'
import { extraordinaryUi, terminalUi } from '../../../until/text/ui'
import { PaginationCommon } from '../../../components/Pagination'
import { ExtraordinaryManagerModal } from '../Modal'
import { useGetAllExtraordinary } from '../../../hooks/query/extraordinary'
import { meterTypeKey, terminalMessage } from '../../../until'
import { ExtraordinaryApi } from '../../../apis/extraordinary'
import { useMutation } from 'react-query'
import { DataEmpty } from '../../../components/DataEmpty'
import { PlusIcon } from '../../../assets/icons'

export const Manager = ({
  setLoadingApi,
  setLoadingApiRefresh,
  dataFilter,
  setDataFilter,
  visibleAdd,
  setVisibleAdd,
  dataDetail,
  setDataDetail,
  listBuilding,
}: any) => {
  const [visibleDelete, setVisibleDelete] = useState<any>({ visibleDelete: false, data: null })
  const [loading, setLoading] = useState(false)
  const {
    data: listExtraordinary,
    isLoading: isLoadingListExtraordinary,
    isFetching,
    refetch,
  } = useGetAllExtraordinary(dataFilter)
  const { mutate: mutateDelete, isLoading: isLoadingDeleteExtraordinary } = useMutation(
    (id: number) => ExtraordinaryApi.deleteExtraordinary(id),
    {
      onSuccess: () => {
        refetch()
        setVisibleDelete({ visibleDelete: false, data: null })
        openNotificationWithIcon('success', terminalMessage.deleteSuccess)
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', error?.message)
      },
    },
  )
  const onChangePagination = (page: number, page_size: number) => {
    if (page_size !== dataFilter.page_size) {
      return setDataFilter({ ...dataFilter, page: 1, page_size })
    }
    setDataFilter({ ...dataFilter, page, page_size })
  }
  useEffect(() => {
    setLoadingApi(isLoadingListExtraordinary)
    setLoadingApiRefresh(isFetching)
  }, [listExtraordinary, isFetching])
  const columns = [
    {
      title: extraordinaryUi.association,
      dataIndex: 'management_association_no',
      width: '20%',
    },
    {
      title: extraordinaryUi.building,
      dataIndex: 'building_code',
      width: '30%',
    },
    {
      title: extraordinaryUi.type,
      dataIndex: 'meter_type',
      width: '15%',
      render: (type: any) => meterTypeKey[type],
    },
    {
      title: extraordinaryUi.abnormal,
      dataIndex: 'value',
      width: '15%',
      render: (type: any) => parseInt(type) + '%',
    },
    {
      title: '',
      width: '10%',
      render: (record: any) => (
        <>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div
              onClick={() => {
                setDataDetail(record)
                setVisibleAdd(true)
              }}
              style={{ cursor: 'pointer', color: '#1890FF' }}
            >
              {terminalUi.buttonEdit}
            </div>
            <div
              style={{ cursor: 'pointer', color: '#1890FF' }}
              onClick={() => {
                setVisibleDelete({ visibleDelete: true, data: record })
              }}
            >
              {terminalUi.buttonDelete}
            </div>
          </div>
        </>
      ),
    },
  ]

  return (
    <>
      {isLoadingListExtraordinary ? null : (
        <div className={styles.extraordinary}>
          {listExtraordinary?.data && listExtraordinary?.data.length > 0 ? (
            <div className={styles.extraordinary__table}>
              <Table rowKey="id" columns={columns} dataSource={listExtraordinary?.data} pagination={false} />
              {listExtraordinary && listExtraordinary?.pagination?.total > 10 && (
                <div className={styles.extraordinary__table__pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={listExtraordinary.pagination.total}
                    current={listExtraordinary.pagination.current_page}
                    pageSize={listExtraordinary.pagination.per_page}
                    showSizeChanger
                    onChangePagination={onChangePagination}
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              )}
            </div>
          ) : (
            <DataEmpty detail={extraordinaryUi.noResultsMessage}>
              <ButtonCommon
                className={styles.btn__add}
                type="primary"
                icon={<IconWrap icon={<PlusIcon />} size={14} />}
                onClick={() => {
                  setVisibleAdd(true)
                }}
              >
                {extraordinaryUi.buttonCreate}
              </ButtonCommon>
            </DataEmpty>
          )}
        </div>
      )}
      {visibleAdd && (
        <ExtraordinaryManagerModal
          listBuilding={listBuilding}
          dataDetail={dataDetail}
          refetch={refetch}
          title={dataDetail ? extraordinaryUi.titleModalEdit : extraordinaryUi.titleModalCreate}
          visible={visibleAdd}
          setLoading={setLoading}
          loading={loading}
          onOk={() => {
            setVisibleAdd(true)
          }}
          onCancel={() => {
            setVisibleAdd(false)
          }}
        />
      )}
      {visibleDelete && (
        <ConfirmDelete
          title={extraordinaryUi.buttonDelete}
          onOk={() => setVisibleDelete({ visibleDelete: false, data: null })}
          onCancel={() => setVisibleDelete({ visibleDelete: false, data: null })}
          onConfirmDelete={() => mutateDelete(Number(visibleDelete?.data?.id))}
          isLoading={isLoadingDeleteExtraordinary}
          visible={visibleDelete.visibleDelete}
          text={extraordinaryUi.textDelete}
          width={'50%'}
        />
      )}
    </>
  )
}
