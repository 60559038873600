export const EndPoint = {
  staff: 'staff',
  building: 'building',
  meter: 'meter',
  operation_log: 'operation-log',
  summary: 'summary',
  meterType: 'meter-type',
  history: 'sync/history',
  price: 'price',
  invoice: 'invoice',
  contractor: 'contractor',
  terminal: 'device',
  notification: 'notification',
  extraordinary: 'abnormal',
  traffic: 'traffic',
  setting: 'setting',
  formula: 'formula',
  tariff_code_list: 'tariff-code-list',
}
