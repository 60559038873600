import { Col, Form, Row, Select } from 'antd'
import styles from './index.module.scss'
import { ButtonCommon, PageHeader, RequireTag } from '../../components'
import { Loading } from '../../components/Loading'
import { useEffect, useState } from 'react'
import { priceManagerUI, settingMessage, settingUi } from '../../until'
import headerImg from '../../assets/images/settingHeader.png'
import clsx from 'clsx'
import { InputNumberCommon } from '../../components/Input/Number'
import './style.scss'
import { useGetSetting } from '../../hooks/query/setting'
import { ISettingParam } from '../../models'
import { useMutation } from 'react-query'
import { SettingApi } from '../../apis'
import { openNotificationWithIcon } from '../../hooks'

const countdownDefault = process.env.REACT_APP_SETTING_COUNTDOWN || 5

export const Setting = () => {
  const [form] = Form.useForm()
  const [isView, setIsView] = useState(true)
  const [isScan, setIsScan] = useState(true)
  const [useFormula, setUseFormula] = useState(false)

  const { data, isLoading, refetch } = useGetSetting()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        countdown_seconds: data?.countdown_seconds,
      })
      setIsScan(data?.scannable || false)
      setUseFormula(data?.use_formula || false)
    }
  }, [data])

  const { mutate: mutateSetting } = useMutation((body: ISettingParam) => SettingApi.updateSetting(body), {
    onSuccess: () => {
      refetch()
      setIsView(!isView)
      openNotificationWithIcon('success', settingMessage.updateSuccess)
    },
    onError: (error: any) => {
      openNotificationWithIcon('error', error?.message)
    },
  })

  const onFinish = (values: ISettingParam) => {
    const body = {
      scannable: isScan,
      countdown_seconds: values.countdown_seconds,
      use_formula: useFormula,
    }
    mutateSetting(body)
  }

  const onChangeScanSetting = (value: number) => {
    setIsScan(value === 1)
  }

  const onChangeFormula = (value: number) => {
    setUseFormula(value === 1)
  }

  const onCancel = () => {
    form.setFields([{ name: 'countdown_seconds', errors: [], value: data?.countdown_seconds || countdownDefault }])
    setIsScan(data ? data?.scannable : true)
    setUseFormula(data ? data?.use_formula : false)
    setIsView(!isView)
  }

  return (
    <div className={styles.settingForm}>
      <PageHeader title={settingUi.settingTitle} backgroundImage={headerImg} height={'108px'} />
      <Loading isLoading={isLoading} />
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" className={styles.form}>
        <div className={styles['settingForm__control--container']}>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <>
                    {settingUi.canSettingScan}
                    {!isView ? <RequireTag /> : null}
                  </>
                }
                className={clsx([
                  styles.settingForm__item,
                  { [styles.settingForm__itemDisable]: isView },
                  'settingForm__item',
                ])}
                rules={[
                  {
                    required: true,
                    message: settingUi.canSettingScan + settingMessage.isRequired,
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Select
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  disabled={isView}
                  onChange={onChangeScanSetting}
                  defaultValue={1}
                  value={isScan ? 1 : 0}
                >
                  <Select.Option key="yes" value={1}>
                    {settingUi.yes}
                  </Select.Option>
                  <Select.Option key="no" value={0}>
                    {settingUi.no}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <>
                    {settingUi.settingCountdown}
                    {!isView && <RequireTag />}
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: settingUi.settingCountdown + settingMessage.isRequired,
                  },
                ]}
                name="countdown_seconds"
                className={clsx([
                  styles.settingForm__item,
                  { [styles.settingForm__itemDisable]: isView },
                  'settingForm__item',
                ])}
                validateTrigger="onSubmit"
                initialValue={countdownDefault}
              >
                <InputNumberCommon
                  max={10}
                  min={1}
                  maxLength={2}
                  disabled={isView}
                  onBlur={() => {
                    form.setFieldValue('countdown_seconds', form.getFieldValue('countdown_seconds'))
                  }}
                  placeholder={settingMessage.pleaseEnter}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item
                label={
                  <>
                    {priceManagerUI.calculationBilling}
                    {!isView ? <RequireTag /> : null}
                  </>
                }
                className={clsx([
                  styles.settingForm__item,
                  { [styles.settingForm__itemDisable]: isView },
                  'settingForm__item',
                ])}
                rules={[
                  {
                    required: true,
                    message: settingUi.canSettingScan + settingMessage.isRequired,
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Select
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  disabled={isView}
                  onChange={onChangeFormula}
                  defaultValue={0}
                  value={useFormula ? 1 : 0}
                >
                  <Select.Option key={1} value={1}>
                    {priceManagerUI.useCalculationFormula}
                  </Select.Option>
                  <Select.Option key={0} value={0}>
                    {priceManagerUI.usePriceList}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        {isView ? (
          <div className={styles.settingForm__btn__wrap}>
            <ButtonCommon className={styles['settingForm__btn--full']} type="ghost" onClick={() => setIsView(!isView)}>
              {settingUi.edit}
            </ButtonCommon>
          </div>
        ) : (
          <div className={styles.settingForm__btn__wrap}>
            <ButtonCommon type="ghost" onClick={onCancel}>
              {settingUi.cancel}
            </ButtonCommon>
            <ButtonCommon style={{ letterSpacing: '-2px' }} type="primary" htmlType="submit">
              {settingUi.save}
            </ButtonCommon>
          </div>
        )}
      </Form>
    </div>
  )
}
