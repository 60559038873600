import { IOpreationLogParams } from '../../models'
import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class OperationLog {
  getListOperationLog = async (params?: IOpreationLogParams) => {
    const url = `${EndPoint.operation_log}`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }
}

export const OperationLogApi = new OperationLog()
