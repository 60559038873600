export const UploadIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 17.5H18V19.5H0V17.5ZM10 4.328V15.5H8V4.328L1.929 10.4L0.515 8.986L9 0.5L17.485 8.985L16.071 10.399L10 4.33V4.328Z"
        fill="white"
      />
    </svg>
  )
}
