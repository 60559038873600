import { Form } from 'antd'
import { ButtonCommon, InputCommon, ModalCommon, RequireTag, SelectCommon } from '../../../components'
import styles from './index.module.scss'
import { managerMessage, meterMessage, profileMessage, staffMessage } from '../../../until/text/messages'
import { managerUI, meterUi, profileUi } from '../../../until/text/ui'
import { openNotificationWithIcon, useGetAllContracts } from '../../../hooks'
import { usePostMeterType, usePutMeterType } from '../../../hooks/query/meter'
import { IMeterType } from '../../../models'
interface IProps {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  meterType?: IMeterType
}

export const MeterTypeModal = ({ visible, onOk, onCancel, meterType }: IProps) => {
  const [form] = Form.useForm()
  const { mutateAsync, isLoading } = usePostMeterType()
  const { mutateAsync: mutatePutAsync, isLoading: isLoadingPut} = usePutMeterType()
  const { data } = useGetAllContracts()
  const handleFinish = async (values: any) => {
    try {
      meterType ? await mutatePutAsync({...meterType, subject_code: values.subject_code}) : await mutateAsync(values)
      openNotificationWithIcon('success', '', meterType ? staffMessage.updateSuccess : staffMessage.createSuccess)
      onOk()
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  const typeMeter: { label: string; value: number }[] = [
    { label: meterUi.water, value: 1 },
    { label: meterUi.electric, value: 2 },
    { label: meterUi.gas, value: 3 },
  ]

  return (
    <ModalCommon
      destroyOnClose={true}
      title={meterType ? meterUi.editMeterType : meterUi.addMeterType}
      visible={visible}
      width="29%"
      className={styles.modal}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} onFinish={handleFinish} className={styles.form} layout="vertical">
        <div className={styles.form__wrap}>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {managerUI.IDcontract}
                {!meterType && <RequireTag />}
              </>
            }
            initialValue={meterType?.contract_code || null}
            name="contract_code"
            className={styles.form__item}
            rules={[
              {
                required: true,
                message: managerMessage.selectContract,
              },
            ]}
          >
            <SelectCommon 
              disabled={!!meterType}
              placeholder={managerUI.placeholderContract} 
              showSearch={true}
              optionFilterProp='label'
              options={data && data.length > 0 && data.map((el: any) => {return {label: el.contract_code, value: el.contract_code}}) || []}
            />
          </Form.Item>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {meterUi.type}
                {!meterType && <RequireTag />}
              </>
            }
            name="type"
            initialValue={Number(meterType?.type) || null}
            className={styles.form__item}
            rules={[
              {
                required: true,
                message: `${meterMessage.meterTypeSelect}`,
              },
            ]}
          >
            <SelectCommon 
              disabled={!!meterType}
              placeholder={meterUi.meterTypePlaceholder}
              options={typeMeter}
            />
          </Form.Item>
          <Form.Item
            validateTrigger="onSubmit"
            label={
              <>
                {meterUi.subjectCode}
                <RequireTag />
              </>
            }
            name="subject_code"
            initialValue={meterType?.subject_code || null}
            className={styles.form__item}
            rules={[
              {
                required: true,
                message: `${meterMessage.subjecCodeRequired}`,
              }
            ]}
          >
            <InputCommon 
              maxLength={10} 
              placeholder={profileMessage.placeholderInput} 
              onBlur={() => {
                form.setFieldValue('subject_code', form.getFieldValue('subject_code')?.trim())
              }}
            />
          </Form.Item>
        </div>
        <div className={styles.form__btn__wrap}>
          <ButtonCommon type="ghost" onClick={onCancel}>
            {profileUi.cancel}
          </ButtonCommon>
          <ButtonCommon style={{letterSpacing: '-2px'}} loading={isLoading || isLoadingPut} type="primary" htmlType="submit">
            {managerUI.save}
          </ButtonCommon>
        </div>
      </Form>
    </ModalCommon>
  )
}
