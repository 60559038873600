export const staffUi = {
  staffList: 'ユーザー一覧',
  search: '検索',
  addStaff: 'ユーザー追加',
  building: '建物',
  status: '状態',
  administrator: '管理者',
  worker: '作業者',
  all: 'すべて',
  placeholderStatus: '状態を選択してください。',
  onGoing: '有効',
  printQRCode: 'QRコード印刷',
  qrCode: 'QRコード',
  delete: '削除',
  updateInformation: '編集',
  profileImage: 'プロフィール画像',
  upload: 'アップロード',
  fullName: '名前',
  placeholderName: '名前を入力してください。',
  fullNameKatakana: '名前カナ',
  placeholderNameKatakana: '名前カナを入力してください。',
  userName: 'ユーザー名',
  placeholderUserName: 'ユーザー名を入力してください。',
  phone: '電話番号',
  placeholderPhone: '電話番号を入力してください。',
  email: 'メールアドレス',
  placeholderEmail: 'メールアドレスを入力してください。',
  address1: '住所 1',
  address2: '住所 2',
  placeholderAddress: '住所を入力してください。',
  postalCode: '郵便番号',
  placeholderPostalCode: '郵便番号を入力してください。',
  assignBuilding: '担当する建物設定',
  placeholderAssignBuilding: '建物を選択してください。',
  cancel: 'キャンセル',
  save: '確定',
  download: 'ダウンロード',
  print: '印刷',
  active: '有効',
  inactive: '無効',
  noResultsMessage: '以下のボタンから作業者を追加してください。',
  contract: '契約コード',
  manager: '管理者',
  noBuilding: 'なし',
  checkAll: '全て選択',
  printQrCode: 'QRコード印刷',
  userType: 'ユーザー種別',
  placeholderUserType: 'ユーザー種別を選択してください。',
  staff: '作業者',
  titleDefault: 'ユーザー編集',
  placeHolderBillingDate: '通信量計測の締日を選択してください。',
  billingDate: '通信量計測の締日',
  twoFactorAuthentication: '2段階認証',
  twoFactorAuthenticationPlaceholder: '２段階認証を選択してください。',
}
