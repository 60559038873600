export const settingUi = {
  settingTitle: 'マスタ設定',
  canSettingScan: '検針値取込用QRコードスキャン',
  settingCountdown: '検針値取込開始のカウントダウン（秒）',
  yes: '有り',
  no: '無し',
  cancel: 'キャンセル',
  save: '更新',
  edit: '編集',
}
