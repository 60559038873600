import { useQuery } from 'react-query'
import { NotificationApi } from '../../../apis'

const ServeStateKey = {
  notificationCount: 'notificationCount',
}

export const useGetNotificationCount = () => {
  return useQuery<any, Error>([ServeStateKey.notificationCount], () => NotificationApi.getCount())
}
