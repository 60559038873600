import { EXAMPLE_RESPONSE } from '../../until/constant'
import axiosClient from '../axiosClient'
import { EndPoint } from '../endpoint'

class Contractors {
  importCSV = async (data: any) => {
    const url = `/${EndPoint.contractor}/imports`
    const res = await axiosClient.post(url, data)
    return res.data
  }

  getListContractors = async (params: any) => {
    const url = `${EndPoint.contractor}/list`
    const res = await axiosClient.get(url, { params })
    return res.data?.data || EXAMPLE_RESPONSE
  }
}

export const ContractorsApi = new Contractors()
