export const loginUi = {
  loginTitle: 'ログイン',
  userName: 'ユーザー名',
  password: 'パスワード',
  forgotPassword: 'パスワードをお忘れの場合',
  placeHolderUsername: 'メールアドレスを入力してください。',
  placeHolderPassword: 'パスワードを入力してください。',
  loginBtn: 'サインイン',
  email: 'メールアドレス',
  placeHolderEmail: 'メールアドレスを入力してください。',
}

export const forgotPasswordUi = {
  forgotPasswordTitle: 'パスワードをお忘れの場合',
  email: 'メールアドレス ',
  placeHolder: '例）xxxx＠xxxx.xx.jp',
  resetPassword: '送信',
  backToLogin: '戻る',
}

export const verifyAuthUi = {
  verifyTitle: '2段階認証',
  code: '確認コード',
  description: 'ご登録のメールアドレス宛にメールを送信しました。メールに記載されている確認コードを入力して、送信ボタンを押下してください。（有効時間：１０分間）',
  verify: '送信',
  back: '戻る'
}
