import React, { useRef } from 'react'
import clsx from 'clsx'
import styles from './index.module.scss'
import { ButtonCommon } from '../../../components/Button'
import { Form, FormInstance, Input } from 'antd'
import { CommonUi, verifyAuthUi } from '../../../until/text/ui'
import { authMessage } from '../../../until/text/messages'
import { LockIcon } from '../../../assets/icons'
import { AUTHENTICATION_PASSCODE, regex } from '../../../until'
import _ from 'lodash'
import { ILogin } from '../../../models/user'

interface IProps {
  passcode: (number | null)[]
  // eslint-disable-next-line no-unused-vars
  setPasscode: (value: (number | null)[]) => void
  // eslint-disable-next-line no-unused-vars
  onFinish: (value: ILogin) => void
  loading: boolean
  form: FormInstance
  // eslint-disable-next-line no-unused-vars
  onBackLoginForm: (value: boolean) => void
}

export const VerifyAuthForm: React.FC<IProps> = ({
  passcode,
  setPasscode,
  onFinish,
  loading,
  form,
  onBackLoginForm,
}) => {
  const inputs = useRef<HTMLInputElement[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target
    if (regex.numberCode.test(value) || value === '') {
      const newPasscode: (number | null)[] = [...passcode]
      newPasscode[index] = value === '' ? null : Number(value)
      setPasscode(newPasscode)

      if (value && index < inputs.current.length - 1) {
        inputs.current[index + 1].focus()
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !_.isNumber(passcode[index]) && index > 0) {
      inputs.current[index - 1].focus()
      e.preventDefault()
      e.stopPropagation()
      return
    }
    const newPasscode: (number | null)[] = [...passcode]
    if (/^[0-9]$/i.test(e.key)) {
      newPasscode[index] = Number(e.key)
      setPasscode(newPasscode)

      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus()
      }
      e.preventDefault()
      e.stopPropagation()
      return
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const paste = e.clipboardData.getData('text')
    if (!_.isNaN(Number(paste))) {
      const newPasscode = paste.split('').map(Number)
      let newValueIndex = 0
      const passCodeTemp = passcode.map((el, idx) => {
        if (idx >= index) {
          const temp = newPasscode[newValueIndex] ?? el
          newValueIndex = newValueIndex + 1
          return temp
        }
        return el
      })
      setPasscode(passCodeTemp)
      const lastIndex = index + newPasscode.length
      const lastInputFocusIndex =
        lastIndex > AUTHENTICATION_PASSCODE - 1 ?
          AUTHENTICATION_PASSCODE - 1 :
          lastIndex
      inputs.current[lastInputFocusIndex].focus()
    }
    e.preventDefault()
  }

  return (
    <div className={styles.verify}>
      <div className={clsx(styles.verify__title)}>
        <h1 style={{ textAlign: 'center', color: 'white' }}>{CommonUi.logo}</h1>
      </div>
      <div className={clsx(styles.verify__page)}>
        <div className={clsx(styles.verify__header)}>
          <Form form={form} className={clsx(styles.verify__header__form)} layout="vertical" onFinish={onFinish}>
            <div className={styles.verify__header__form__info}>
              <LockIcon />
              <h1 className={clsx(styles.verify__header__text)}>{verifyAuthUi.verifyTitle}</h1>
            </div>
            <p className={styles.verify__header__form__description}>{verifyAuthUi.description}</p>
            <Form.Item
              label={verifyAuthUi.code}
              name="code"
              className={clsx(styles.form__item)}
              rules={[
                {
                  required: !passcode.join(''),
                  message: authMessage.verifyCodeRequired,
                },
                {
                  validator() {
                    if (passcode.join('') && passcode.filter((el) => el !== null)?.length < AUTHENTICATION_PASSCODE) {
                      return Promise.reject(`${authMessage.verifyCodeInvalid}`)
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              validateTrigger="onSubmit"
            >
              {passcode.map((digit, index) => (
                <Input
                  key={index}
                  type="text"
                  value={digit === null ? '' : digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={(e) => handlePaste(e, index)}
                  maxLength={1}
                  ref={(el) => {
                    if (el && el.input) {
                      inputs.current[index] = el.input
                    }
                  }}
                  style={{ width: '2em', marginRight: '0.5em', textAlign: 'center' }}
                />
              ))}
            </Form.Item>
            <div className={clsx(styles.form__btnGroup)}>
              <ButtonCommon
                className={clsx(styles.btn__verify)}
                loading={loading}
                style={{ letterSpacing: '-2px' }}
                htmlType="submit">
                {verifyAuthUi.verify}
              </ButtonCommon>
              <ButtonCommon onClick={() => onBackLoginForm(false)} type="ghost" className={clsx(styles.btn__back__login)} style={{ letterSpacing: '-2px' }}>
                {verifyAuthUi.back}
              </ButtonCommon>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
