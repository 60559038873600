export const LockIcon = () => {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={44} height={44} rx={22} fill="#FFE7BA" />
      <path
        d="M29 20H30C30.2652 20 30.5196 20.1054 30.7071 20.2929C30.8946 20.4804 31 20.7348 31 21V31C31 31.2652 30.8946 31.5196 30.7071 31.7071C30.5196 31.8946 30.2652 32 30 32H14C13.7348 32 13.4804 31.8946 13.2929 31.7071C13.1054 31.5196 13 31.2652 13 31V21C13 20.7348 13.1054 20.4804 13.2929 20.2929C13.4804 20.1054 13.7348 20 14 20H15V19C15 18.0807 15.1811 17.1705 15.5328 16.3212C15.8846 15.4719 16.4002 14.7003 17.0503 14.0503C17.7003 13.4002 18.4719 12.8846 19.3212 12.5328C20.1705 12.1811 21.0807 12 22 12C22.9193 12 23.8295 12.1811 24.6788 12.5328C25.5281 12.8846 26.2997 13.4002 26.9497 14.0503C27.5998 14.7003 28.1154 15.4719 28.4672 16.3212C28.8189 17.1705 29 18.0807 29 19V20ZM15 22V30H29V22H15ZM21 24H23V28H21V24ZM27 20V19C27 17.6739 26.4732 16.4021 25.5355 15.4645C24.5979 14.5268 23.3261 14 22 14C20.6739 14 19.4021 14.5268 18.4645 15.4645C17.5268 16.4021 17 17.6739 17 19V20H27Z"
        fill="#FA8C16"
      />
    </svg>
  )
}
