export const staffMessage = {
  inputFullName: '名前は必須です。',
  inputFullNameKatakana: '名前カナを入力してください。',
  inputUserName: 'ユーザー名は必須です。',
  selectRole: 'ユーザ種別は必須です。',
  inputPhone: '電話番号を入力してください。',
  invalidPhone: '電話番号の形式が正しくありません。',
  inputEmail: 'メールアドレスは必須です。',
  invalidEmail: 'メールアドレスの形式が正しくありません。',
  inputAddress: '住所を入力してください。',
  inputPostalCode: '郵便番号の形式が正しくありません。',
  selectStatus: '状態は必須です。',
  inputStatus: '状態は必須です。',
  inputBuilding: '担当する建物設定は必須です。',
  placeholderBuilding: '入力してください。',
  updateSuccess: '更新に成功しました。',
  whiteSpace: 'ユーザー名の形式が正しくありません。',
  createSuccess: '作成に成功しました。',
  inputUserType: ' ユーザ種別は必須です。',
  deleteSuccess: '削除に成功しました。',
  inputDateClose: '通信量計測の締日は必須です。',
}
