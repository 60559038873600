import clsx from 'clsx'
import { ButtonCommon, ModalCommon, RequireTag } from '../../../components'
import { IMeter } from '../../../models'
import styles from './index.module.scss'
import { meterMessage, meterUi } from '../../../until'
import { Form } from 'antd'
import { useEffect, useMemo } from 'react'
import { usePutMeterLatest } from '../../../hooks/query/meter'
import { openNotificationWithIcon } from '../../../hooks'
import { InputNumberCommon } from '../../../components/Input/Number'
interface IProps {
  title: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
  meter?: IMeter
}

export const CorrectionMeterModal = ({ meter, title, visible, onOk, onCancel }: IProps) => {
  const [form] = Form.useForm()
  const { mutateAsync: mutatePutAsync, isLoading: isLoadingPut } = usePutMeterLatest()

  useEffect(() => {
    if (meter) {
      form.setFieldsValue({
        value: meter?.latest_meter
      })
    }
  }, [meter])

  const handleFinish = async (values: any) => {
    try {
      await mutatePutAsync({
        id: Number(meter?.id),
        value: values.value
      })
      openNotificationWithIcon('success', '', meterMessage.updateSuccess)
      onOk()
    } catch (error: any) {
      openNotificationWithIcon('error', '', error?.message)
    }
  }

  const meterMaxValue = useMemo(() => {
    return {
      max: Number(Array(meter?.meter_digit).fill(9).join('')) + 0.99,
      maxLength: !meter?.meter_digit ? 9 : Number(meter.meter_digit + 3)
    }
  }, [meter])

  return (
    <ModalCommon
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="768px"
      destroyOnClose={true}
    >
      <div className={styles.editForm}>
        <Form form={form} layout="vertical" onFinish={handleFinish} autoComplete="off" className={styles.form}>
          <Form.Item
            label={
              <>
                {meterUi.latestMeter}
                <RequireTag />
              </>
            }
            rules={[
              {
                required: true,
                message: meterUi.latestMeter + meterMessage.isRequired,
              },
            ]}
            name="value"
            className={clsx([styles.editForm__item, 'editForm__item'])}
            validateTrigger="onSubmit"
          >
            <InputNumberCommon
              precision={2}
              step={0.1}
              max={meterMaxValue.max}
              min={0}
              maxLength={meterMaxValue.maxLength}
            />
          </Form.Item>
          <div className={styles.editForm__btn__wrap}>
            <ButtonCommon type="ghost" onClick={onCancel}>
              {meterUi.cancel}
            </ButtonCommon>
            <ButtonCommon style={{ letterSpacing: '-2px' }} type="primary" htmlType="submit" loading={isLoadingPut}>
              {meterUi.save}
            </ButtonCommon>
          </div>
        </Form>
      </div>
    </ModalCommon>
  )
}
