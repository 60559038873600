import { useQuery } from 'react-query'
import { SummaryApi } from '../../../apis/summary'
import { IResSummary } from '../../../models'

export const ServerStateKeysSummary = {
  Summary: 'summary',
  SummaryCsv: 'summaryCsv',
  SummaryImg: 'summaryImg',
  SummarySuggestions: 'summarySuggestions',
}

export const useGetAllSummary = () =>
  useQuery<IResSummary, Error>([ServerStateKeysSummary.Summary], () => SummaryApi.getAllSummary())

export const useDownloadCsvSummary = (params: any) =>
  useQuery<any, Error>([ServerStateKeysSummary.SummaryCsv, params], () => SummaryApi.downloadCsvSummary(params), {
    // The query will not execute until the userId exists
    enabled: false,
  })

export const useDownloadImgSummary = (params: any) =>
  useQuery<any, Error>([ServerStateKeysSummary.SummaryImg, params], () => SummaryApi.downloadImg(params), {
    // The query will not execute until the userId exists
    enabled: false,
  })

export const useSuggestionsSummary = () =>
  useQuery<any, Error>([ServerStateKeysSummary.SummarySuggestions], () => SummaryApi.getSuggestionsSummary())
