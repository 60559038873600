import deviceHeader from '../../assets/images/deviceHeader.png'
import styles from './index.module.scss'
import { PlusIcon, SearchIcon } from '../../assets/icons'
import { IconWrap, InputCommon, PageHeader, ButtonCommon, ConfirmDelete } from '../../components'
import { Table } from 'antd'
import { useCallback, useState } from 'react'
import { CommonUi, terminalUi } from '../../until/text/ui'
import { PaginationCommon } from '../../components/Pagination'
import { DataEmpty } from '../../components/DataEmpty'
import { TerminalModal } from './Modal'
import { Loading } from '../../components/Loading'
import { useGetAllMAC } from '../../hooks/query/terminal'
import { TerminalApi } from '../../apis'
import { openNotificationWithIcon } from '../../hooks'
import { useMutation } from 'react-query'
import { terminalMessage } from '../../until'
import debounce from 'lodash.debounce'

export const Terminal = () => {
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [detailMAC, setDetailMAC] = useState<any>(null)
  const [visibleDelete, setVisibleDelete] = useState<any>({ visibleDelete: false, data: null })
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    search: '',
  })
  const { data: listMAC, isLoading: isLoadingListMAC, refetch, isFetching: isFetchingListMAC } = useGetAllMAC(params)
  const { mutate: mutateDelete, isLoading: isLoadingDeleteMac } = useMutation(
    (id: number) => TerminalApi.deleteMAC(id),
    {
      onSuccess: () => {
        refetch()
        if (listMAC?.data?.length === 1) {
          setParams({ ...params, page: params.page > 1 ? params.page - 1 : 1 })
        }
        setVisibleDelete({ visibleDelete: false, data: null })
        openNotificationWithIcon('success', terminalMessage.deleteSuccess)
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', error?.message)
      },
    },
  )
  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, search: nextValue, page: 1 })), 500),
    [],
  )

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value)
  }
  const onChangePagination = (page: number, page_size: number) => {
    setParams({ ...params, page, page_size })
  }

  const columns = [
    {
      title: terminalUi.title,
      dataIndex: 'username',
      width: '30%',
      render: (text: any) => (
        <div className={styles.terminal__text}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: terminalUi.device,
      dataIndex: 'device_name',
      width: '30%',
      render: (text: any) => (
        <div className={styles.terminal__text}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: terminalUi.MAC,
      dataIndex: 'device_id',
      width: '30%',
      render: (text: any) => (
        <div className={styles.terminal__text}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: '',
      width: '10%',
      render: (record: any) => (
        <>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div
              onClick={() => {
                setDetailMAC(record)
                setVisibleAdd(true)
              }}
              style={{ cursor: 'pointer', color: '#1890FF', whiteSpace: 'nowrap' }}
            >
              {terminalUi.buttonEdit}
            </div>
            <div
              style={{ cursor: 'pointer', color: '#1890FF', whiteSpace: 'nowrap' }}
              onClick={() => setVisibleDelete({ visibleDelete: true, data: record })}
            >
              {terminalUi.buttonDelete}
            </div>
          </div>
        </>
      ),
    },
  ]

  return (
    <div className={styles.terminal}>
      <PageHeader title={terminalUi.header} backgroundImage={deviceHeader} height={'108px'}>
        <div className={styles.terminal__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={CommonUi.placeholderInput}
            className={styles.terminal__header__search__input}
            onChange={onHandleSearch}
          />
          <ButtonCommon
            onClick={() => {
              setVisibleAdd(true)
              setDetailMAC(null)
            }}
            style={{ width: '140px', gap: '8px' }}
            icon={<IconWrap icon={<PlusIcon />} size={14} />}
            className={styles.price__addRule__btn}
            type="primary"
          >
            {terminalUi.buttonAdd}
          </ButtonCommon>
        </div>
      </PageHeader>
      {isLoadingListMAC ? null : (
        <>
          {listMAC?.data && listMAC?.data?.length > 0 ? (
            <div className={styles.terminal__table}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={listMAC?.data}
                pagination={false}
                scroll={{ x: 1280 }}
              />
              {listMAC?.pagination?.total > 10 && (
                <div className={styles.terminal__table__pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={listMAC?.pagination.total}
                    current={listMAC?.pagination.current_page}
                    pageSize={listMAC?.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              )}
            </div>
          ) : (
            <DataEmpty detail={terminalUi.noResultsMessage}>
              <ButtonCommon
                className={styles.btn__add}
                type="primary"
                icon={<IconWrap icon={<PlusIcon />} size={14} />}
                onClick={() => {
                  setVisibleAdd(true)
                  setDetailMAC(null)
                }}
              >
                {terminalUi.buttonAdd}
              </ButtonCommon>
            </DataEmpty>
          )}
        </>
      )}
      {visibleAdd && (
        <TerminalModal
          dataDetail={detailMAC}
          title={detailMAC === null ? terminalUi.modalTitleAdd : terminalUi.modalTitleEdit}
          visible={visibleAdd}
          onOk={() => {
            setVisibleAdd(true)
          }}
          onCancel={() => {
            setVisibleAdd(false)
          }}
          setLoading={setLoading}
          refetch={refetch}
        />
      )}
      {visibleDelete && (
        <ConfirmDelete
          title={terminalUi.buttonDelete}
          onOk={() => setVisibleDelete({ visibleDelete: false, data: null })}
          onCancel={() => setVisibleDelete({ visibleDelete: false, data: null })}
          onConfirmDelete={() => mutateDelete(Number(visibleDelete?.data?.id))}
          visible={visibleDelete.visibleDelete}
          isLoading={isLoadingDeleteMac}
          text={terminalMessage.textDelete}
          width={'50%'}
        />
      )}
      <Loading isLoading={loading || isLoadingListMAC || isFetchingListMAC} />
    </div>
  )
}
