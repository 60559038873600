import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { IconWrap } from '../../../components/IconWrap'
import {
  HomeIcon,
  BuildingIcon,
  MeterIcon,
  SummaryIcon,
  StaffIcon,
  MeterType,
  LogHistory,
  ContractIcon,
  Setting,
  Invoice,
  Traffic,
  Device,
  Extraordinary,
  Other,
  SettingV2Icon,
  OperationIcon,
} from '../../../assets/icons'
import styles from './index.module.scss'

import clsx from 'clsx'
import { homeUi, profileUi } from '../../../until/text/ui'
import { useLogout } from '../../../hooks/query/user'
import { openNotificationWithIcon } from '../../../hooks'
interface HeaderLayoutProps {
  mode?: any
  onClickMenu?: any
}

const Navigation: FC<HeaderLayoutProps> = ({ mode = 'horizontal' }) => {
  const location = useLocation()
  const [current, setCurrent] = useState<string>(location.pathname)
  const [activeSubmenu, setActiveSubmenu] = useState(false)
  const navigate = useNavigate()
  const mutateLogout = useLogout()
  const role = localStorage.getItem('role') || ''
  const userType = localStorage.getItem('user_type') || ''
  const handleOnClickMenu = async (e: any) => {
    if (e.key === '/login') {
      try {
        await mutateLogout.mutateAsync()
        localStorage.clear()
        navigate('/login')
      } catch (error: any) {
        openNotificationWithIcon('error', '', error?.message)
      }
    } else if (role === '9' && e.key === '/profile') {
      navigate('/admin-profile')
    } else {
      navigate(e.key)
    }
  }

  const menuItems: any =
    role !== '9'
      ? [
        {
          key: '/',
          label: <Link to="">{homeUi.home}</Link>,
          icon: <IconWrap icon={<HomeIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/staff',
          label: <Link to="staff">{homeUi.staff}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<StaffIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/building',
          label: <Link to="building">{homeUi.building}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<BuildingIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/meter',
          label: <Link to="meter">{homeUi.meter}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<MeterIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/contractor',
          label: <Link to="summary">{homeUi.contractor}</Link>,
          icon: <IconWrap icon={<ContractIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/price-manager',
          label: <Link to="summary">{homeUi.priceManager}</Link>,
          icon: <IconWrap icon={<Setting />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/invoice-manager',
          label: <Link to="summary">{homeUi.invoiceManager}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<Invoice />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/operation-log',
          label: <Link to="meter">{homeUi.operationLog}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<OperationIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          label: homeUi.other,
          className: clsx(activeSubmenu ? styles.submenu__big : styles.submenu__small),
          icon: <IconWrap icon={<Other />} className={clsx(styles.header__icon)} />,
          role: 'menuitem',
          children: [
            {
              key: '/summary',
              label: <Link to="summary">{homeUi.summary}</Link>,
              icon: <IconWrap color="#E6F7FF" icon={<SummaryIcon />} className={clsx(styles.header__icon)} />,
            },
            userType === '1' && {
              key: '/history',
              label: <Link to="history">{homeUi.history}</Link>,
              icon: <IconWrap icon={<LogHistory />} className={clsx(styles.header__icon)} />,
            },
            {
              key: '/extraordinary-manager',
              label: <Link to="/extraordinary-manager">{homeUi.extraordinaryManager}</Link>,
              icon: <IconWrap color="#fff" icon={<Extraordinary />} className={clsx(styles.header__icon)} />,
            },
            {
              key: '/traffic-manager',
              label: <Link to="/traffic-manager">{homeUi.trafficManager}</Link>,
              icon: <IconWrap color="#fff" icon={<Traffic />} className={clsx(styles.header__icon)} />,
            },
            {
              key: '/terminal-manager',
              label: <Link to="/terminal-manager">{homeUi.terminalManager}</Link>,
              icon: <IconWrap color="#fff" icon={<Device />} className={clsx(styles.header__icon)} />,
            },
            {
              key: '/setting',
              label: <Link to="/setting">{homeUi.setting}</Link>,
              icon: <IconWrap color="#fff" icon={<SettingV2Icon />} className={clsx(styles.header__icon)} />,
            },
          ],
        },
      ]
      : [
        {
          key: '/admin',
          label: <Link to="staff">{homeUi.staff}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<StaffIcon />} className={clsx(styles.header__icon)} />,
        },
        {
          key: '/meter-type',
          label: <Link to="staff">{homeUi.meterType}</Link>,
          icon: <IconWrap color="#E6F7FF" icon={<MeterType />} className={clsx(styles.header__icon)} />,
        },
      ]
  const menuUser: any = [
    {
      type: 'divider',
    },
    {
      label: <a>{profileUi.profile}</a>,
      key: '/profile',
    },
    {
      label: <a>{profileUi.logout}</a>,
      key: '/login',
    },
  ]
  useEffect(() => {
    setCurrent(location.pathname)
    if (
      location.pathname === '/traffic-manager' ||
      location.pathname === '/extraordinary-manager' ||
      location.pathname === '/terminal-manager' ||
      location.pathname === '/history' ||
      location.pathname === '/summary'
    ) {
      setActiveSubmenu(true)
    } else {
      setActiveSubmenu(false)
    }
  }, [location])

  return (
    <div className={clsx([styles.header__menu, 'menu--selected'])}>
      {role ? (
        <Menu
          onClick={handleOnClickMenu}
          mode={mode}
          className={styles.header__menu__antd}
          items={mode === 'horizontal' ? menuItems : menuItems.concat(menuUser)}
          selectedKeys={[current]}
        />
      ) : (
        <Menu
          onClick={handleOnClickMenu}
          mode={mode}
          className={styles.header__menu__antd__admin}
          items={mode !== 'horizontal' ? menuUser : null}
          selectedKeys={[current]}
        />
      )}
    </div>
  )
}

export default Navigation
